import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../css/estilos.css';
import logo from '../img/logo.png';
import {withRouter, NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { setMusicPlaying } from '../../actions/musicActions';
import { setActiveMenu } from '../../actions/menuActions';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import VisitorForm from './VisitorWeb';
import firebase from '../../Firebase';
import {setLanguage} from 'redux-i18n';
import {bindActionCreators} from "redux";
import 'flag-icon-css/css/flag-icon.min.css';

Modal.setAppElement('#root');

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.handleChange = this.handleChange.bind(this);
    this.tableVisitors = this.db.collection('visitantes');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleMusic = () => {
    if(!this.props.isPlaying){   
      this.props.audio.play().then(() => {});
      this.props.audio.loop = true;
      this.props.setMusicPlaying(true);
    }else if(this.props.isPlaying){
      this.props.audio.pause();
      this.props.setMusicPlaying(false);
    }
  };

  handleChange = (event) => {
    let menu = event.target.value;
    this.props.setActiveMenu(menu);
    this.props.history.push(menu);
  };

  handleSubmit = (values) => {
    const { correo, edad, genero, estadoResidencia } = values;
    this.tableVisitors.add({ correo, edad, genero, estadoResidencia }).then( () => {
      sessionStorage.setItem('showModal', 'false');
    }).catch( () => {
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'Hubo un problema enviando tus datos. Intenta de nuevo por favor.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      }).then(() => {});
    });
  };

  closeModal = () => {
    sessionStorage.setItem('showModal', 'false');
  };

  closeSession = () => {
    firebase.auth().signOut().then(() => {});
    localStorage.clear();
    this.props.history.push('/');
    window.location.reload();
  };

  changeLanguage = (lang) => {
    localStorage.setItem("language",lang);
    this.props.actions.setLanguage(lang);
  };

  render() {
    let totalProducts = localStorage.getItem('totalProductos') ? localStorage.getItem('totalProductos') : 0;
    let showModal = sessionStorage.getItem('showModal') === 'true';
    const clientName = localStorage.getItem('clientName') ? localStorage.getItem('clientName') : '';
    const clientEmail = localStorage.getItem('clientEmail') ? localStorage.getItem('clientEmail') : '';
    return (
      <div className="header">
        <Modal isOpen={showModal} >
          <VisitorForm onSubmit={this.handleSubmit} onClose={this.closeModal} />
        </Modal>
        <div className="top_menu">
          <div className="music">
            <span onClick={() => this.toggleMusic()} ><i className={this.props.icono}/> {this.context.t("menuMusica")}</span>
          </div>
          <div className="social">
            {this.context.t("menuSiguenos")}
            <a href="https://www.facebook.com/Cerveza-Sierra-Friamx-108088070663065/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"/></a>
            <a href="https://www.instagram.com/sierrafria.mx/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"/></a>
            <a href="http://m.me/108088070663065" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-messenger"/></a>
          </div>
          {
            clientName !== '' && clientEmail !== '' ?
                <div className="client_area">
                  <NavLink exact to="/mis-pedidos">{this.context.t("menuPedidos")}</NavLink>
                  <span onClick={() => this.closeSession()} >{this.context.t("menuCerrarSesion")}</span>
                </div>
                :
                <div className="client_area">
                  <NavLink exact to="/iniciar-sesion">{this.context.t("menuIniciarSesion")}</NavLink>
                </div>
          }
          <div className="language">
            <span onClick={() => this.changeLanguage('es')} className="flag-icon flag-icon-mx" />
            <span onClick={() => this.changeLanguage('en')} className="flag-icon flag-icon-us" />
          </div>
        </div>
        <div className="main_menu">
          <div className="logo">
            <NavLink exact to="/" activeClassName="active"><img src={logo} alt="Cerveza Sierra Fría" /></NavLink>
            <p>{this.context.t("LogoSubtitulo")}</p>
          </div>
          <div className="frase">
            <p>{this.context.t("HeaderFrase")}</p>
          </div>
          <div className="menu">
            <NavLink exact to="/" activeClassName="active">{this.context.t("menuInicio")}</NavLink>
            <NavLink exact to="/tiendas" activeClassName="active">{this.context.t("menuTiendas")}</NavLink>
            <NavLink exact to="/nuestros-productos" activeClassName="active">{this.context.t("menuProductos")}</NavLink>
            <NavLink exact to="/recetas" activeClassName="active">{this.context.t("menuRecetas")}</NavLink>
            <NavLink exact to="/blog" activeClassName="active">{this.context.t("menuBlog")}</NavLink>
            <NavLink exact to="/contacto" activeClassName="active">{this.context.t("menuContacto")}</NavLink>
            <NavLink exact to="/carrito"><i className="fas fa-shopping-cart"/> {this.context.t("menuCarrito")} ({totalProducts})</NavLink>
          </div>
        </div>
        <div className="menu-mobile">
          <div className="contenedor-menu">
            <div className="logo">
              <NavLink exact to="/" activeClassName="active"><img src={logo} alt="Cerveza Sierra Fría" /></NavLink>
            </div>
            <div className="select">
              <select id="menu-select" onChange={this.handleChange} value={this.props.menu}>
                <option value="">{this.context.t("menuSelect")}</option>
                <option value="/">{this.context.t("menuInicio")}</option>
                <option value="/tiendas">{this.context.t("menuTiendas")}</option>
                <option value="/nuestros-productos">{this.context.t("menuProductos")}</option>
                <option value="/recetas">{this.context.t("menuRecetas")}</option>
                <option value="/blog">{this.context.t("menuBlog")}</option>
                <option value="/contacto">{this.context.t("menuContacto")}</option>
              </select>
            </div>
          </div>
          <div className="footer">
            <div className="social">
              <a href="https://www.facebook.com/Cerveza-Sierra-Friamx-108088070663065/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"/></a>
              <a href="https://www.instagram.com/sierrafria.mx/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"/></a>
              <a href="http://m.me/108088070663065" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-messenger"/></a>
              <span onClick={() => this.toggleMusic()} ><i className={this.props.icono}/> {this.context.t("menuMusica")}</span>
              <span onClick={() => this.changeLanguage('es')} className="flag-icon flag-icon-mx language-mobile" />
              <span onClick={() => this.changeLanguage('en')} className="flag-icon flag-icon-us" />
            </div>
            {
              clientName !== '' && clientEmail !== '' ?
                  <div className="client_area">
                    <NavLink exact to="/mis-pedidos">{this.context.t("menuPedidos")}</NavLink>
                    <span onClick={() => this.closeSession()} >{this.context.t("menuCerrarSesion")}</span>
                    <NavLink exact to="/carrito" className="carrito"><i className="fas fa-shopping-cart"/> {this.context.t("menuCarrito")} ({totalProducts})</NavLink>
                  </div>
                  :
                  <div className="client_area">
                    <NavLink exact to="/iniciar-sesion">{this.context.t("menuIniciarSesion")}</NavLink>
                    <NavLink exact to="/carrito" className="carrito"><i className="fas fa-shopping-cart"/> {this.context.t("menuCarrito")} ({totalProducts})</NavLink>
                  </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state)=>{
  return {
    audio: state.music.audio,
    isPlaying: state.music.isPlaying,
    icono: state.music.musicIcon,
    menu: state.menu.activeMenu,
    lang: state.i18nState.lang
  }
};

const mapDispatchToProps= (dispatch)=>{
  const actions = {
    setLanguage
  };
  return {
    actions: bindActionCreators(actions, dispatch),
    setMusicPlaying: (estado) => {
      dispatch(setMusicPlaying(estado))
    },
    setActiveMenu: (menu) => {
      dispatch(setActiveMenu(menu))
    }
  }
};

AppHeader.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AppHeader));
