import React, {Component} from 'react';
import AppFrame from "../../components/AppFrame";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import TableDesktop from "../../components/TableDesktop";
import {textFilter} from "react-bootstrap-table2-filter";

const columns = [{
    dataField: 'fecha',
    text: 'Fecha',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'nombre',
    text: 'Nombre',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'correo',
    text: 'Correo',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'comentario',
    text: 'Comentario',
    sort: true,
    filter: textFilter()
}];

class CommentsContainer extends Component {
    renderBody(comments) {
        return (
            <div className="view-comments-container">
                <div className="card">
                    <div className="body">
                        { <Table className="table-admin">
                            <Thead>
                                <Tr>
                                    <Th>Fecha</Th>
                                    <Th>Nombre</Th>
                                    <Th>Correo</Th>
                                    <Th>Comentario</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                { comments.map( (comment,key) => {
                                    return(
                                        <Tr key={key}>
                                            <Td>{comment.fecha}</Td>
                                            <Td>{comment.nombre}</Td>
                                            <Td>{comment.correo}</Td>
                                            <Td>{comment.comentario}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table> }
                        <TableDesktop data={comments} columns={columns} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const article = this.props.location.article;
        return (
            <AppFrame title={"Comentarios del Artículo: " + article.titulo} body={this.renderBody(article.comentarios)} />
        );
    }
}

export default CommentsContainer;
