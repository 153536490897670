// noinspection JSNonASCIINames,NonAsciiCharacters
export const translations = {
    'es': {
        'menuInicio': 'Inicio',
        'menuTiendas': '¿Dónde Comprar?',
        'menuProductos': 'Productos',
        'menuRecetas': 'Recetas',
        'menuBlog': 'Blog',
        'menuContacto': 'Contacto',
        'menuCarrito': 'Carrito',
        'menuSelect': 'Menú',
        'menuSiguenos': 'Síguenos',
        'menuMusica': 'Música',
        'menuPedidos': 'Mis Pedidos',
        'menuIniciarSesion': 'Iniciar Sesión',
        'menuCerrarSesion': 'Cerrar Sesión',
        'footerDeveloper': 'Sitio Desarrollado por',
        'visitorMensaje': 'Solicitamos los siguientes datos para conocer el tipo de personas que entran a nuestro sitio web. ¡Gracias por tu ayuda!',
        'visitorCorreo': 'Correo',
        'visitorEdad': 'Edad',
        'visitorGenero': 'Género',
        'visitorGeneroSelect': 'Selecciona una opción...',
        'visitorEstado': 'Estado de Residencia',
        'visitorEstadoSelect': 'Selecciona tu estado...',
        'visitorEnviar': 'Enviar mis datos',
        'visitorCancelar': 'No deseo compartir mis datos',
        'visitorValidateEdad': 'Llena la edad por favor.',
        'visitorValidateEdadValida': 'La edad debe ser un valor mayor a 0 y menor a 100 por favor.',
        'visitorValidateGenero': 'Llena tu género por favor.',
        'visitorValidateEstado': 'Llena tu estado de residencia por favor.',
        'visitorMasculino': 'Masculino',
        'visitorFemenino': 'Femenino',
        'direccionTitulo': '¿A dónde vamos a enviar tus cervezas?',
        'direccionCalle': 'Calle',
        'direccionNumero': 'Número',
        'direccionColonia': 'Colonia',
        'direccionPostal': 'Código Postal',
        'direccionCiudad': 'Ciudad',
        'direccionEstado': 'Estado',
        'direccionValidateCalle': 'Llena la calle por favor.',
        'direccionValidateNumero': 'Llena el número por favor.',
        'direccionValidateColonia': 'Llena la colonia por favor.',
        'direccionValidatePostal': 'Llena el código postal por favor.',
        'direccionValidateCiudad': 'Llena la ciudad por favor.',
        'direccionValidateEstado': 'Llena el estado por favor.',
        'contactoNombre': 'Nombre',
        'contactoCorreo': 'Correo',
        'contactoAsunto': 'Asunto',
        'contactoMensaje': 'Escribe tu mensaje...',
        'contactoEnviar': 'Enviar Mensaje',
        'contactoValidateNombre': 'Llena el nombre por favor.',
        'contactoValidateAsunto': 'Llena el asunto por favor.',
        'promotionCorreo': 'Correo',
        'promotionSuscribirse': 'Suscribirse',
        'ValidateCorreo': 'Llena el correo por favor.',
        'ValidateCorreoValido': 'Escribe un correo válido por favor.',
        'HomeExplora': 'Explora',
        'HomeExploraSubtitulo': 'Una cerveza emocionante',
        'HomeHistoriaTitulo': 'Nuestra Historia',
        'HomeHistoriaP1': 'Cerveza Sierra Fría nace de conjugar el espíritu aventurero, vivir experiencias nuevas, con una bebida única que refresca nuestra jornada. El lugar que nos da origen es el punto medio entre la montaña más alta de México y la playa.',
        'HomeHistoriaP2': 'Lo que nos inspiró para tener una Cerveza única con sabores que nos reflejan la profundidad del bosque desconocido y la frescura y alegría de la costa.',
        'HomeHistoriaP3': 'Teniendo siempre de fondo ese cuerpo serrano que, con sus partes altas nevadas, frías, nos refresca como cada una de nuestras variedades.',
        'HomeHistoriaP4': 'Las mejores acompañantes para disfrutar: reuniones, celebraciones y comidas que maridan con nuestra gran gastronomía mexicana.',
        'HomeFrase': 'Reinventa tu forma de vivir',
        'HomeTestimonio1': 'Muchas Felicidades Cerveza Sierra Fría, por su cerveza tipo Porter, una verdadera delicia en cuerpo y sabor!',
        'HomeTestimonio2': 'Sin ser un experto, me pareció que la cerveza es muy buena, al beberla, puedes notar su composición y notas a cacao. Es una cerveza con cuerpo, a mí me relajo mucho e inmediatamente me situó en una atmósfera cálida con un marcado sentir de apreciación del momento.',
        'HomeTestimonio3': 'Me gusto mucho que fuera una cerveza artesanal desde el sabor, hasta la historia. Volvería a probarla una y otra vez, mi favorita.',
        'HomeTestimonio4': 'La IPA me encantó por su sabor y sobre todo por los aromas herbales, cítricos no lo esperaba, me sorprendió gratamente. Me envolvió en el ambiente de aventura.',
        'HomeTestimonio5': 'Me gusto mucho la cerveza Sierra Fría Porter con carne asada y mis amigos, la IPA con pescado me encanto !!, me llevo a la playa.',
        'Anterior': 'Anterior',
        'Siguiente': 'Siguiente',
        'HomeBeneficiosTitulo': 'Descubre nuevas experiencias con nuestros sabores',
        'HomeBeneficiosNombre1': 'Sabor',
        'HomeBeneficiosSubtitulo1': 'Profundidad de sabores para que puedas disfrutar ese momento con las personas que más quieres.',
        'HomeBeneficiosNombre2': 'Gastronomía',
        'HomeBeneficiosSubtitulo2': 'Descubre un nuevo mundo con los diferentes platillos que puedes disfrutar con nuestras cervezas.',
        'HomeBeneficiosNombre3': 'Aventura',
        'HomeBeneficiosSubtitulo3': 'Nuestras cervezas son una aventura que valen la pena para disfrutar los momentos extraordinarios.',
        'HomeBeneficiosNombre4': 'Emociones',
        'HomeBeneficiosSubtitulo4': 'La cerveza es una bebida emocionante con una gran complejidad al igual que las emociones del día a día.',
        'HomeSocialTitulo': 'Nuestras Redes Sociales',
        'HomePromocionesTitulo': 'Participa en nuestras promociones',
        'HomeActividadesTitulo': '¿Cómo disfrutar nuestra cerveza?',
        'HomeActividades1': 'Deportes',
        'HomeActividades2': 'Viajes',
        'HomeActividades3': 'Amigos',
        'HomeActividades4': 'Gastronomía',
        'ModalBotonContinuar': 'Continuar',
        'ModalLoSentimos': '¡Lo sentimos!',
        'ModalGracias': '¡Gracias!',
        'ModalPromotionExito': 'Ahora recibirás correos con nuestras promociones.',
        'ModalPromotionError': 'Hubo un problema con tu suscripción.',
        'TiendasTitulo': 'Tiendas',
        'TiendasSubtitulo': 'Encuentra el lugar más cercano a ti para poder disfrutar de nuestra cerveza.',
        'ProductosTitulo': 'Nuestras Cervezas',
        'ProductosSubtitulo': 'Hechas para disfrutar con una buena comida junto a los que más quieres.',
        'ProductosContenido': 'Contenido',
        'ProductosAlcohol': 'Volumen de Alcohol',
        'RecetasTitulo': 'Recetas',
        'RecetasSubtitulo': 'Nuestra lista de platillos ideales para maridar con nuestras cervezas.',
        'ContactoTitulo': 'Contáctanos',
        'ContactoSubtitulo': 'Estamos interesados en conocer tu opinión, dudas y sugerencias.',
        'ModalContactoExito': 'Tu mensaje ha sido enviado exitosamente.',
        'ModalContactoError': 'Hubo un problema enviando tu mensaje.',
        'ViewRecipeIngredientes': 'Ingredientes',
        'ViewRecipeInstrucciones': 'Instrucciones',
        'ViewProductModalExito': 'Tu producto ha sido agregado exitosamente.',
        'ViewProductAddCart': 'Añadir al carrito',
        'ViewProductInformacion': 'Información',
        'ViewProductSabor': 'Sabor',
        'ViewProductMaridaje': 'Maridaje',
        'ViewProductAspecto': 'Aspecto',
        'ViewProductAromas': 'Aromas',
        'ViewProductCategoria': 'Categoría',
        'ViewProductContenido': 'Contenido',
        'ViewProductAlcohol': 'Volumen de Alcohol',
        'ViewProductAmargor': 'Amargor IBUs',
        'LoginTitulo': 'Empieza una nueva aventura',
        'LoginSubtitulo': 'Comienza a disfrutar de nuestras cervezas y reinventa tu forma de vida.',
        'LoginGoogle': 'Iniciar Sesión con Google',
        'LoginFacebook': 'Iniciar Sesión con Facebook',
        'PedidoCapturado': 'Capturado',
        'ModalPedidoExito': 'Tu pedido se ha creado exitosamente.',
        'ModalPedidoCorreoError': 'Hubo un problema al enviar el correo sobre tu nuevo pedido.',
        'ModalPedidoPagoError': 'Hubo un problema al crear tu pedido. Revisa tu método de pago.',
        'CarritoTitulo': 'Resumen del pedido',
        'CarritoEliminar': 'Eliminar',
        'CarritoProducto': 'Producto',
        'CarritoPrecio': 'Precio',
        'CarritoCantidad': 'Cantidad',
        'CarritoTotal': 'Total',
        'CarritoActualizar': 'Actualizar carrito',
        'CarritoNoLogged': 'Debes iniciar sesión con tu cuenta de Google o Facebook para realizar tu pedido.',
        'CarritoLogIn': 'Iniciar Sesión',
        'CarritoPagar': 'Pagar',
        'CarritoFinalizar': 'Finalizar la Compra',
        'CarritoVacio': 'Tu carrito está vacío.',
        'PedidosVer': 'Ver',
        'PedidosTitulo': 'Mis Pedidos',
        'PedidosFecha': 'Fecha',
        'PedidosDireccion': 'Dirección',
        'PedidosTotal': 'Total',
        'PedidosEstado': 'Estado',
        'PedidosDetalles': 'Detalles',
        'PedidosVacio': 'Aún no tienes pedidos.',
        'VerPedidoTitulo': 'Detalles del Pedido',
        'VerPedidoVolver': 'Volver',
        'VerPedidoInformacion': 'Información del Pedido',
        'VerPedidoFecha': 'Fecha',
        'VerPedidoDireccion': 'Dirección',
        'VerPedidoTotal': 'Total',
        'VerPedidoEstado': 'Estado',
        'VerPedidoNombre': 'Nombre',
        'VerPedidoCantidad': 'Cantidad',
        'VerPedidoPrecio': 'Precio',
        'VerPedidoVacio': 'Lo sentimos, no hay registros.',
        'PedidoPagado': 'Pagado',
        'PedidoEnviado': 'Enviado',
        'PedidoEntregado': 'Entregado',
        'CarritoEnvio': 'Te enviaremos un correo con las opciones de envío y una vez que recibamos tu selección haremos el envío de tu pedido y el cargo a tu tarjeta.',
        'LogoSubtitulo': 'Cerveza Artesanal',
        'HeaderFrase': '¡Vive la Aventura!',
        'Entrada': 'Entrada',
        'PlatilloFuerte': 'Platillo Fuerte',
        'Postre': 'Postre',
        'A malta obscura, notas a chocolate, café y presencia de tostado al final; con persistencia en boca y cuerpo.': 'A malta obscura, notas a chocolate, café y presencia de tostado al final; con persistencia en boca y cuerpo.',
        'Fresco, ligero, cítricos.': 'Fresco, ligero, cítricos.',
        'Equilibra sabores fuertes como son carne asada, ahumada, condimentada, barbacoa. Quesos ahumados, maduros: parmesano, gruyer, gouda y pan. postres de chocolate.': 'Equilibra sabores fuertes como son carne asada, ahumada, condimentada, barbacoa. Quesos ahumados, maduros: parmesano, gruyer, gouda y pan. postres de chocolate.',
        'Matices profundos ámbar, café obscuro achocolatado. Espuma color marrón claro.': 'Matices profundos ámbar, café obscuro achocolatado. Espuma color marrón claro.',
        'A granos tostados, chocolate, café, caramelo, pan.': 'A granos tostados, chocolate, café, caramelo, pan.',
        'Comida mexicana, guacamole, carnes blancas a la parrilla, ensaladas, botanas, sushi y mariscos.': 'Comida mexicana, guacamole, carnes blancas a la parrilla, ensaladas, botanas, sushi y mariscos.',
        'Ámbar dorado, con buena persistencia en boca, con espuma blanca.': 'Ámbar dorado, con buena persistencia en boca, con espuma blanca.',
        'Notas cítricas y frutales. Con presencia de lúpulo moderado.': 'Notas cítricas y frutales. Con presencia de lúpulo moderado.',
        'Ceviche de Tilapia': 'Ceviche de Tilapia',
        'Dip de queso y cerveza':'Dip de queso y cerveza',
        'El famoso fuerte fromage de Toronado, San Diego':'El famoso fuerte fromage de Toronado, San Diego',
        'Sopa de coliflor, cerveza y gruyere':'Sopa de coliflor, cerveza y gruyere',
        'Ceviche de camarones con cerveza':'Ceviche de camarones con cerveza',
        'Sándwich de pollo vaquero':'Sándwich de pollo vaquero',
        'Pizza de carnitas a la BBQ':'Pizza de carnitas a la BBQ',
        'Pizza de Pan Plano del Cervecero':'Pizza de Pan Plano del Cervecero',
        'Pasta Arrabiata':'Pasta Arrabiata',
        'Asado a la olla con cerveza':'Asado a la olla con cerveza',
        'Pastel de tempeh':'Pastel de tempeh',
        'Hamburguesa Bunz con mermelada de tocino ahumado':'Hamburguesa Bunz con mermelada de tocino ahumado',
        'Pollo a la barbacoa con succotash':'Pollo a la barbacoa con succotash',
        'Muffins de manzana y cerveza':'Muffins de manzana y cerveza',
        'Pastelitos de fondo de roca':'Pastelitos de fondo de roca',
        '500 gramos de tilapia (u otro pescado sostenible)':'500 gramos de tilapia (u otro pescado sostenible)',
        '1 1/4 tazas de jugo de limón fresco': '1 1/4 tazas de jugo de limón fresco',
        '3 tomates grandes, sin corazón, sin semillas y cortados en cubitos': '3 tomates grandes, sin corazón, sin semillas y cortados en cubitos',
        '1/2 pepino grande, sin semillas y cortado en cubitos': '1/2 pepino grande, sin semillas y cortado en cubitos',
        '1/2 chile jalapeño, picado (use guantes al picar el jalapeño y evite tocarse los ojos)': '1/2 chile jalapeño, picado (use guantes al picar el jalapeño y evite tocarse los ojos)',
        '1/4 taza de cilantro, picado, y más para decorar':'1/4 taza de cilantro, picado, y más para decorar',
        '1/4 taza de cebolla roja, cortada en cubitos': '1/4 taza de cebolla roja, cortada en cubitos',
        '1 cucharadita de salsa de tomate totalmente natural (sin jarabe de maíz)': '1 cucharadita de salsa de tomate totalmente natural (sin jarabe de maíz)',
        '1 cucharadita de salsa tabasco o salsa tapatio':'1 cucharadita de salsa tabasco o salsa tapatio',
        '1/2 cucharadita de salsa Worcestershire':'1/2 cucharadita de salsa Worcestershire',
        'Sal kosher y pimienta blanca molida':'Sal kosher y pimienta blanca molida',
        'Chips de tortilla, para servir':'Chips de tortilla, para servir',
        'Cortar la tilapia en cubos de 6 milímetros y ponerla en un tazón grande. Agregue el jugo de limón fresco, mezcle bien y deje reposar, refrigerado, durante 2 horas.':'Cortar la tilapia en cubos de 6 milímetros y ponerla en un tazón grande. Agregue el jugo de limón fresco, mezcle bien y deje reposar, refrigerado, durante 2 horas.',
        'Escurra el exceso de jugo de la tilapia y mézclelo con el tomate, el pepino, el jalapeño, el cilantro, la cebolla roja, el ketchup, el tabasco y el Worcestershire. Sazone con sal y pimienta blanca al gusto. Adorne con cilantro adicional, si lo desea.':'Escurra el exceso de jugo de la tilapia y mézclelo con el tomate, el pepino, el jalapeño, el cilantro, la cebolla roja, el ketchup, el tabasco y el Worcestershire. Sazone con sal y pimienta blanca al gusto. Adorne con cilantro adicional, si lo desea.',
        'Sirva inmediatamente con chips de tortilla, o almacene, cubierto y refrigerado, hasta por 2 días.':'Sirva inmediatamente con chips de tortilla, o almacene, cubierto y refrigerado, hasta por 2 días.',
        '2 dientes de ajo':'2 dientes de ajo',
        '3/4 tazas de Cerveza Sierra Fría Session IPA':'3/4 tazas de Cerveza Sierra Fría Session IPA',
        '3 tazas de queso cheddar rallado':'3 tazas de queso cheddar rallado',
        '1 taza de queso crema, en cubos':'1 taza de queso crema, en cubos',
        '1 cucharadita de mostaza en polvo': '1 cucharadita de mostaza en polvo',
        '1 cucharadita de pimienta negra recién molida':'1 cucharadita de pimienta negra recién molida',
        '1/4 cucharadita de cayena':'1/4 cucharadita de cayena',
        '1/4 cucharadita de rábano picante cremoso':'1/4 cucharadita de rábano picante cremoso',
        'Galletas, para servir':'Galletas, para servir',
        'Rebanadas de baguette tostadas, para servir':'Rebanadas de baguette tostadas, para servir',
        'Con un procesador de alimentos en funcionamiento, deje caer el ajo a través del tubo de alimentación y continúe durante 30 segundos. Detener.':'Con un procesador de alimentos en funcionamiento, deje caer el ajo a través del tubo de alimentación y continúe durante 30 segundos. Detener.',
        'Agrega los ingredientes restantes. Ejecute el procesador de alimentos hasta que todos los ingredientes estén bien mezclados.':'Agrega los ingredientes restantes. Ejecute el procesador de alimentos hasta que todos los ingredientes estén bien mezclados.',
        'Enfríe por al menos 2 horas. Sirva con galletas saladas o rebanadas de baguette.':'Enfríe por al menos 2 horas. Sirva con galletas saladas o rebanadas de baguette.',
        '1/2 taza de queso cheddar ahumado, rallado':'1/2 taza de queso cheddar ahumado, rallado',
        '1/2 taza de queso crema':'1/2 taza de queso crema',
        '1/2 taza de queso de cabra':'1/2 taza de queso de cabra',
        '1 cucharada de mostaza Dijon':'1 cucharada de mostaza Dijon',
        '3 dientes de ajo, machacados':'3 dientes de ajo, machacados',
        '1 manojo de perejil fresco':'1 manojo de perejil fresco',
        '1 chalote grande':'1 chalote grande',
        '140 gr de tocino, opcional':'140 gr de tocino, opcional',
        '180 ml de Cerveza Sierra Fría Session IPA':'180 ml de Cerveza Sierra Fría Session IPA',
        '1 a 3 cucharadas de aceite de oliva':'1 a 3 cucharadas de aceite de oliva',
        'Sal y pimienta al gusto':'Sal y pimienta al gusto',
        'Rebanadas de baguette tostado, para servir':'Rebanadas de baguette tostado, para servir',
        'Buenas rebanadas de salami, para servir':'Buenas rebanadas de salami, para servir',
        'Moras y arándanos frescos, para servir':'Moras y arándanos frescos, para servir',
        'Permita que los quesos alcancen la temperatura ambiente. Coloque los quesos en un procesador de alimentos junto con mostaza, ajo, perejil, chalota y tocino, si los usa. Haga puré la mezcla durante 1 a 2 minutos, hasta que se combine.':'Permita que los quesos alcancen la temperatura ambiente. Coloque los quesos en un procesador de alimentos junto con mostaza, ajo, perejil, chalota y tocino, si los usa. Haga puré la mezcla durante 1 a 2 minutos, hasta que se combine.',
        'Agregue la cerveza y el aceite de oliva y mezcle hasta que la mezcla esté suave. Sazone con sal y pimienta al gusto.':'Agregue la cerveza y el aceite de oliva y mezcle hasta que la mezcla esté suave. Sazone con sal y pimienta al gusto.',
        'Servir inmediatamente con rebanadas de baguette, salami o bayas.':'Servir inmediatamente con rebanadas de baguette, salami o bayas.',
        'Nota: si no sirve de inmediato, envuelva bien y refrigere. Dejar calentar a temperatura ambiente antes de servir.':'Nota: si no sirve de inmediato, envuelva bien y refrigere. Dejar calentar a temperatura ambiente antes de servir.',
        '2 cucharaditas de mantequilla':'2 cucharaditas de mantequilla',
        '1 1/2 tazas de cebolla picada':'1 1/2 tazas de cebolla picada',
        '1 1/2 tazas de apio picado':'1 1/2 tazas de apio picado',
        '1 1/2 cucharadas de ajo picado':'1 1/2 cucharadas de ajo picado',
        '1 a 1.4 kilos de coliflor, cortada en trozos pequeños':'1 a 1.4 kilos de coliflor, cortada en trozos pequeños',
        '650 ml de Cerveza Sierra Fría Session IPA':'650 ml de Cerveza Sierra Fría Session IPA',
        '2 tazas de caldo de pollo o vegetales':'2 tazas de caldo de pollo o vegetales',
        '1 taza de crema batida':'1 taza de crema batida',
        '2 tazas de queso gruyere, rallado (uso dividido)':'2 tazas de queso gruyere, rallado (uso dividido)',
        'Pimienta de cayena, al gusto':'Pimienta de cayena, al gusto',
        'Sal y pimiento, al gusto':'Sal y pimiento, al gusto',
        '1/2 baguette, en rodajas':'1/2 baguette, en rodajas',
        'Mantequilla, para baguettes':'Mantequilla, para baguettes',
        'En una olla grande a fuego medio, derrita la mantequilla. Agregue las cebollas, el apio, el ajo y la coliflor. Saltee hasta que las cebollas estén translúcidas y la coliflor esté tierna.':'En una olla grande a fuego medio, derrita la mantequilla. Agregue las cebollas, el apio, el ajo y la coliflor. Saltee hasta que las cebollas estén translúcidas y la coliflor esté tierna.',
        'Agregue la cerveza y el caldo, hierva y reduzca el fuego. Cubra y cocine a fuego lento durante 15 a 20 minutos.':'Agregue la cerveza y el caldo, hierva y reduzca el fuego. Cubra y cocine a fuego lento durante 15 a 20 minutos.',
        'Agregue la crema y cocine a fuego lento durante 15 minutos. Retirar del fuego y enfriar un poco.':'Agregue la crema y cocine a fuego lento durante 15 minutos. Retirar del fuego y enfriar un poco.',
        'Haga puré la sopa con una licuadora de inmersión o una licuadora de sobremesa suave. Lleva la sopa a fuego lento y bate 1 1/2 tazas de queso. Agregue la pimienta de cayena, sal y pimienta y, si lo desea, más ajo, al gusto.':'Haga puré la sopa con una licuadora de inmersión o una licuadora de sobremesa suave. Lleva la sopa a fuego lento y bate 1 1/2 tazas de queso. Agregue la pimienta de cayena, sal y pimienta y, si lo desea, más ajo, al gusto.',
        'Precaliente el horno para asar: coloque las rebanadas de baguette en una bandeja para hornear, mantequilla ligeramente, espolvoree con el queso restante y ase hasta que estén doradas y burbujeantes. Sirva la sopa en tazones y haga flotar la tostada con queso encima.':'Precaliente el horno para asar: coloque las rebanadas de baguette en una bandeja para hornear, mantequilla ligeramente, espolvoree con el queso restante y ase hasta que estén doradas y burbujeantes. Sirva la sopa en tazones y haga flotar la tostada con queso encima.',
        '2 chiles habaneros grandes, cortados en cubitos (use guantes al cortarlos en cubitos y evite tocarse los ojos)':'2 chiles habaneros grandes, cortados en cubitos (use guantes al cortarlos en cubitos y evite tocarse los ojos)',
        '355 ml de Cerveza Sierra Fría Session IPA':'355 ml de Cerveza Sierra Fría Session IPA',
        '1 cebolla morada mediana, cortada en cubitos':'1 cebolla morada mediana, cortada en cubitos',
        '2 cebollas amarillas medianas, cortadas en cubitos':'2 cebollas amarillas medianas, cortadas en cubitos',
        '3 aguacates, cortados en cubitos (no demasiado maduros)':'3 aguacates, cortados en cubitos (no demasiado maduros)',
        '1 mango grande, cortado en cubitos':'1 mango grande, cortado en cubitos',
        '6 tomates cortados en cubitos':'6 tomates cortados en cubitos',
        '6 cebolletas pequeñas, finamente cortadas en cubitos':'6 cebolletas pequeñas, finamente cortadas en cubitos',
        '5 dientes de ajo finamente picados':'5 dientes de ajo finamente picados',
        '1 manojo de cilantro, finamente picado':'1 manojo de cilantro, finamente picado',
        '1 kilogramo de camarones de 30 a 50 unidades, cocidos, pelados, desvenados y picados':'1 kilogramo de camarones de 30 a 50 unidades, cocidos, pelados, desvenados y picados',
        '6 limas, en jugo':'6 limas, en jugo',
        '1 cucharada de salsa de chile tailandés Sriracha':'1 cucharada de salsa de chile tailandés Sriracha',
        '2 chiles jalapeños grandes, sin semillas y finamente picados (use guantes al picarlos y evite tocarse los ojos)':'2 chiles jalapeños grandes, sin semillas y finamente picados (use guantes al picarlos y evite tocarse los ojos)',
        '1 1/2 cucharadas de sal':'1 1/2 cucharadas de sal',
        '2 cucharadas de pimienta negra recién molida':'2 cucharadas de pimienta negra recién molida',
        'En un tazón pequeño, combine los habaneros y la cerveza. Déjalo reposar durante 1 hora':'En un tazón pequeño, combine los habaneros y la cerveza. Déjalo reposar durante 1 hora',
        'En un tazón grande, combine todos los demás ingredientes y cubra con una envoltura de plástico hasta que la cerveza con infusión de habanero esté lista.':'En un tazón grande, combine todos los demás ingredientes y cubra con una envoltura de plástico hasta que la cerveza con infusión de habanero esté lista.',
        'Dependiendo de la cantidad de picante que desee, puede colar las semillas de habanero de la cerveza antes de verterlas en la mezcla o mantenerlas todas adentro.':'Dependiendo de la cantidad de picante que desee, puede colar las semillas de habanero de la cerveza antes de verterlas en la mezcla o mantenerlas todas adentro.',
        'Agregue la cerveza a los otros ingredientes, mezcle suavemente para evitar que los ingredientes se vuelvan blandos. Cubra con una envoltura de plástico y deje reposar por un mínimo de 1 hora. Para mejores resultados, refrigere durante la noche.':'Agregue la cerveza a los otros ingredientes, mezcle suavemente para evitar que los ingredientes se vuelvan blandos. Cubra con una envoltura de plástico y deje reposar por un mínimo de 1 hora. Para mejores resultados, refrigere durante la noche.',
        'Para la salsa BBQ: 1 cucharada de aceite de canola':'Para la salsa BBQ: 1 cucharada de aceite de canola',
        'Para la salsa BBQ: 2/3 taza de cebolla amarilla picada':'Para la salsa BBQ: 2/3 taza de cebolla amarilla picada',
        'Para la salsa BBQ: 1/3 taza de ajo, picado':'Para la salsa BBQ: 1/3 taza de ajo, picado',
        'Para la salsa BBQ: 2 cucharaditas de semillas de cilantro, tostadas y molidas':'Para la salsa BBQ: 2 cucharaditas de semillas de cilantro, tostadas y molidas',
        'Para la salsa BBQ: 1 cucharadita de pimentón ahumado':'Para la salsa BBQ: 1 cucharadita de pimentón ahumado',
        'Para la salsa BBQ: 1 cucharadita de pimienta de cayena':'Para la salsa BBQ: 1 cucharadita de pimienta de cayena',
        'Para la salsa BBQ: 2 3/4 tazas de Cerveza Sierra Fría Porter':'Para la salsa BBQ: 2 3/4 tazas de Cerveza Sierra Fría Porter',
        'Para la salsa BBQ: 1 chile ancho, rehidratado con 1 taza de agua caliente':'Para la salsa BBQ: 1 chile ancho, rehidratado con 1 taza de agua caliente',
        'Para la salsa BBQ: 1 lima, rallada':'Para la salsa BBQ: 1 lima, rallada',
        'Para la salsa BBQ: 4 1/3 tazas de salsa de tomate':'Para la salsa BBQ: 4 1/3 tazas de salsa de tomate',
        'Para la salsa BBQ: 1/2 manojo de cilantro':'Para la salsa BBQ: 1/2 manojo de cilantro',
        'Para la salsa BBQ: 2/3 taza de melaza':'Para la salsa BBQ: 2/3 taza de melaza',
        'Para la salsa BBQ: 1/2 taza de vinagre de vino de arroz':'Para la salsa BBQ: 1/2 taza de vinagre de vino de arroz',
        'Para la salsa BBQ: 1/4 taza de azúcar morena':'Para la salsa BBQ: 1/4 taza de azúcar morena',
        'Para la salsa BBQ: 1 cucharadita de sal kosher':'Para la salsa BBQ: 1 cucharadita de sal kosher',
        'Para los aros de cebolla: 2 1/2 tazas de harina para todo uso, uso dividido':'Para los aros de cebolla: 2 1/2 tazas de harina para todo uso, uso dividido',
        'Para los aros de cebolla: 2 cucharaditas de perejil seco':'Para los aros de cebolla: 2 cucharaditas de perejil seco',
        'Para los aros de cebolla: 2 cucharaditas de ajo en polvo':'Para los aros de cebolla: 2 cucharaditas de ajo en polvo',
        'Para los aros de cebolla: 1 cucharadita de comino':'Para los aros de cebolla: 1 cucharadita de comino',
        'Para los aros de cebolla: 1 cucharadita de chile en polvo':'Para los aros de cebolla: 1 cucharadita de chile en polvo',
        'Para los aros de cebolla: 1 cucharadita de chile chipotle seco, molido':'Para los aros de cebolla: 1 cucharadita de chile chipotle seco, molido',
        'Para los aros de cebolla: 2 cucharaditas de pimentón ahumado':'Para los aros de cebolla: 2 cucharaditas de pimentón ahumado',
        'Para los aros de cebolla: 1 cucharada de sal kosher':'Para los aros de cebolla: 1 cucharada de sal kosher',
        'Para los aros de cebolla: 1 huevo batido':'Para los aros de cebolla: 1 huevo batido',
        'Para los aros de cebolla: 355 ml de Cerveza Sierra Fría Porter':'Para los aros de cebolla: 355 ml de Cerveza Sierra Fría Porter',
        'Para los aros de cebolla: 2 a 3 cebollas amarillas grandes, cortadas en aros gruesos':'Para los aros de cebolla: 2 a 3 cebollas amarillas grandes, cortadas en aros gruesos',
        'Para los aros de cebolla: 2 tazas de aceite, para freír':'Para los aros de cebolla: 2 tazas de aceite, para freír',
        'Para el pollo: 4 grandes muslos de pollo deshuesados':'Para el pollo: 4 grandes muslos de pollo deshuesados',
        'Para el pollo: Sal y pimienta negra recién molida':'Para el pollo: Sal y pimienta negra recién molida',
        'Para el pollo: 4 onzas de rebanadas afiladas de queso cheddar':'Para el pollo: 4 onzas de rebanadas afiladas de queso cheddar',
        'Para el pollo: 2 chiles poblanos, asados y sin semillas':'Para el pollo: 2 chiles poblanos, asados y sin semillas',
        'Para el pollo: 8 rebanadas de tocino cocido':'Para el pollo: 8 rebanadas de tocino cocido',
        'Para el pollo: 4 hojas de lechuga romana':'Para el pollo: 4 hojas de lechuga romana',
        'Para el pollo: 8 rodajas de tomate':'Para el pollo: 8 rodajas de tomate',
        'Para el pollo: 4 bollos de hamburguesa de trigo integral':'Para el pollo: 4 bollos de hamburguesa de trigo integral',
        'Para el pollo: 4 brochetas grandes':'Para el pollo: 4 brochetas grandes',
        'Haga la salsa BBQ: En una cacerola mediana a fuego alto, caliente el aceite y saltee la cebolla durante unos 3 minutos. Baje el fuego y agregue el ajo. Cocine la cebolla y el ajo por otros 3 minutos y luego agregue las especias secas. Agregue los ingredientes restantes, hierva, baje el fuego y cocine a fuego lento durante aproximadamente 2 horas. Retirar del fuego y dejar de lado.':'Haga la salsa BBQ: En una cacerola mediana a fuego alto, caliente el aceite y saltee la cebolla durante unos 3 minutos. Baje el fuego y agregue el ajo. Cocine la cebolla y el ajo por otros 3 minutos y luego agregue las especias secas. Agregue los ingredientes restantes, hierva, baje el fuego y cocine a fuego lento durante aproximadamente 2 horas. Retirar del fuego y dejar de lado.',
        'Prepare los aros de cebolla: en un tazón poco profundo combine 2 tazas de harina con perejil seco, ajo en polvo, comino, chile en polvo, chipotle, pimentón y sal. En un recipiente aparte, mezcle el huevo batido y la cerveza. Poco a poco revuelva la mezcla de cerveza en la mezcla de harina, revolviendo hasta que se forme una masa espesa. (Agregue más cerveza si la masa es demasiado espesa).':'Prepare los aros de cebolla: en un tazón poco profundo combine 2 tazas de harina con perejil seco, ajo en polvo, comino, chile en polvo, chipotle, pimentón y sal. En un recipiente aparte, mezcle el huevo batido y la cerveza. Poco a poco revuelva la mezcla de cerveza en la mezcla de harina, revolviendo hasta que se forme una masa espesa. (Agregue más cerveza si la masa es demasiado espesa).',
        'Asa el pollo: agrega sal y pimienta de manera uniforme en cada muslo y cocina a la parrilla. Derrita 28 gramos de queso en cada muslo antes de sacarlo de la parrilla.':'Asa el pollo: agrega sal y pimienta de manera uniforme en cada muslo y cocina a la parrilla. Derrita 28 gramos de queso en cada muslo antes de sacarlo de la parrilla.',
        'En una sartén pesada o sartén de hierro fundido, caliente el aceite a fuego medio-alto. (Ajuste la cantidad, dependiendo del tamaño de su sartén, de modo que tenga 5 centímetros de aceite). Coloque las cebollas en un recipiente con agua, luego métalas en un recipiente con la 1/2 taza de harina. Cuando el aceite esté caliente (176 a 190 grados), sumerja las cebollas en la masa, sacuda el exceso y fríe, volteando una vez para que se dore uniformemente en ambos lados. Escurrir sobre toallas de papel.':'En una sartén pesada o sartén de hierro fundido, caliente el aceite a fuego medio-alto. (Ajuste la cantidad, dependiendo del tamaño de su sartén, de modo que tenga 5 centímetros de aceite). Coloque las cebollas en un recipiente con agua, luego métalas en un recipiente con la 1/2 taza de harina. Cuando el aceite esté caliente (176 a 190 grados), sumerja las cebollas en la masa, sacuda el exceso y fríe, volteando una vez para que se dore uniformemente en ambos lados. Escurrir sobre toallas de papel.',
        'Ensamble los sándwiches: tueste los bollos ligeramente y esparza un poco de salsa BBQ en la parte inferior y superior de cada bollo. Coloque un muslo con queso en el fondo de cada bollo. Coloque unas rodajas de chile pimiento y 2 rodajas de tocino en cada snadwich, y cubra con un trozo de lechuga y 2 rodajas de tomate. Coloque un anillo de cebolla bien dorado en la parte superior y rocíe con un poco más de salsa BBQ. Cubra cada sándwich con pan y use una brocheta para mantener juntos a estos gigantes.':'Ensamble los sándwiches: tueste los bollos ligeramente y esparza un poco de salsa BBQ en la parte inferior y superior de cada bollo. Coloque un muslo con queso en el fondo de cada bollo. Coloque unas rodajas de chile pimiento y 2 rodajas de tocino en cada snadwich, y cubra con un trozo de lechuga y 2 rodajas de tomate. Coloque un anillo de cebolla bien dorado en la parte superior y rocíe con un poco más de salsa BBQ. Cubra cada sándwich con pan y use una brocheta para mantener juntos a estos gigantes.',
        'Para el cerdo: 1 kilogramo de paleta de cerdo deshuesada':'Para el cerdo: 1 kilogramo de paleta de cerdo deshuesada',
        'Para el cerdo: 1 cucharada de orégano seco':'Para el cerdo: 1 cucharada de orégano seco',
        'Para el cerdo: 1/2 cucharada de comino molido':'Para el cerdo: 1/2 cucharada de comino molido',
        'Para el cerdo: 8 cucharadas de aceite vegetal, uso dividido':'Para el cerdo: 8 cucharadas de aceite vegetal, uso dividido',
        'Para el cerdo: 2 pimientos chipotles enlatados en adobo, picados en trozos grandes':'Para el cerdo: 2 pimientos chipotles enlatados en adobo, picados en trozos grandes',
        'Para el cerdo: 6 dientes de ajo picados':'Para el cerdo: 6 dientes de ajo picados',
        'Para el cerdo: 1 1/2 cucharadas de sal':'Para el cerdo: 1 1/2 cucharadas de sal',
        'Para el cerdo: 1 1/2 cucharadas de pimienta negra recién molida':'Para el cerdo: 1 1/2 cucharadas de pimienta negra recién molida',
        'Para el cerdo: 1 cebolla picada gruesa':'Para el cerdo: 1 cebolla picada gruesa',
        'Para el cerdo: 1 jalapeño mediano, picado en trozos gruesos (use guantes cuando corte pimientos y evite tocarse los ojos)':'Para el cerdo: 1 jalapeño mediano, picado en trozos gruesos (use guantes cuando corte pimientos y evite tocarse los ojos)',
        'Para el cerdo: 1 naranja, en jugo':'Para el cerdo: 1 naranja, en jugo',
        'Para el cerdo: 1 1/2 tazas de Cerveza Sierra Fría Porter':'Para el cerdo: 1 1/2 tazas de Cerveza Sierra Fría Porter',
        'Para la masa: 1/2 sobre de levadura seca':'Para la masa: 1/2 sobre de levadura seca',
        'Para la masa: 3/4 tazas de Cerveza Sierra Fría Porter (1/4 taza de temperatura ambiente, 1/2 taza enfriada)':'Para la masa: 3/4 tazas de Cerveza Sierra Fría Porter (1/4 taza de temperatura ambiente, 1/2 taza enfriada)',
        'Para la masa: 1 taza de harina de pan, más harina adicional para estirar la masa':'Para la masa: 1 taza de harina de pan, más harina adicional para estirar la masa',
        'Para la masa: 1 taza de harina para todo uso':'Para la masa: 1 taza de harina para todo uso',
        'Para la masa: 1 cucharada de azúcar granulada':'Para la masa: 1 cucharada de azúcar granulada',
        'Para la masa: 1 1/2 cucharaditas de sal kosher':'Para la masa: 1 1/2 cucharaditas de sal kosher',
        'Para la masa: 2 cucharadas de aceite de oliva, más extra para engrasar el tazón':'Para la masa: 2 cucharadas de aceite de oliva, más extra para engrasar el tazón',
        'Para la salsa BBQ: 3 cucharadas de aceite vegetal':'Para la salsa BBQ: 3 cucharadas de aceite vegetal',
        'Para la salsa BBQ: 1/2 taza de cebolla, cortada en cubos pequeños':'Para la salsa BBQ: 1/2 taza de cebolla, cortada en cubos pequeños',
        'Para la salsa BBQ: 1/4 taza de pimientos verdes, cortados en cubos pequeños':'Para la salsa BBQ: 1/4 taza de pimientos verdes, cortados en cubos pequeños',
        'Para la salsa BBQ: 1 cucharada de ajo picado':'Para la salsa BBQ: 1 cucharada de ajo picado',
        'Para la salsa BBQ: 3 tazas de salsa de tomate (preferiblemente Heinz)':'Para la salsa BBQ: 3 tazas de salsa de tomate (preferiblemente Heinz)',
        'Para la salsa BBQ: 1/2 taza de mostaza amarilla':'Para la salsa BBQ: 1/2 taza de mostaza amarilla',
        'Para la salsa BBQ: 3/4 taza de azúcar morena':'Para la salsa BBQ: 3/4 taza de azúcar morena',
        'Para la salsa BBQ: 1/2 taza de miel de abeja':'Para la salsa BBQ: 1/2 taza de miel de abeja',
        'Para la salsa BBQ: 1/4 taza de vinagre de sidra':'Para la salsa BBQ: 1/4 taza de vinagre de sidra',
        'Para la salsa BBQ: 2 cucharadas de salsa Worcestershire':'Para la salsa BBQ: 2 cucharadas de salsa Worcestershire',
        'Para la salsa BBQ: 1 cucharada de chile en polvo':'Para la salsa BBQ: 1 cucharada de chile en polvo',
        'Para la salsa BBQ: 1 cucharada de pimentón ahumado':'Para la salsa BBQ: 1 cucharada de pimentón ahumado',
        'Para la infusión de chile-cerveza: 142 gramos de chiles anchos secos, tallos y semillas eliminados':'Para la infusión de chile-cerveza: 142 gramos de chiles anchos secos, tallos y semillas eliminados',
        'Para la infusión de chile-cerveza: 2 tazas de Cerveza Sierra Fría Porter':'Para la infusión de chile-cerveza: 2 tazas de Cerveza Sierra Fría Porter',
        'Para la cobertura (en cada pizza): 85 gramos de queso mozzarella rallado de baja humedad':'Para la cobertura (en cada pizza): 85 gramos de queso mozzarella rallado de baja humedad',
        'Para la cobertura (en cada pizza): 1 chile jalapeño mediano, cortado en rodajas finas (use guantes cuando corte pimientos y evite tocarse los ojos)':'Para la cobertura (en cada pizza): 1 chile jalapeño mediano, cortado en rodajas finas (use guantes cuando corte pimientos y evite tocarse los ojos)',
        'Para la cobertura (en cada pizza): 1 chile jalapeño rojo mediano, cortado en rodajas finas (use guantes cuando corte pimientos y evite tocarse los ojos)':'Para la cobertura (en cada pizza): 1 chile jalapeño rojo mediano, cortado en rodajas finas (use guantes cuando corte pimientos y evite tocarse los ojos)',
        'Para la cobertura (en cada pizza): 28 gramos de cebolla roja, en rodajas finas':'Para la cobertura (en cada pizza): 28 gramos de cebolla roja, en rodajas finas',
        'Para la cobertura (en cada pizza): 42.5 gramos de queso de cabra desmenuzado':'Para la cobertura (en cada pizza): 42.5 gramos de queso de cabra desmenuzado',
        'Para la cobertura (en cada pizza): 1 cucharada de cilantro fresco, picado en trozos grandes':'Para la cobertura (en cada pizza): 1 cucharada de cilantro fresco, picado en trozos grandes',
        'Prepare la carne de cerdo: Precaliente el horno a 163 grados. Enjuague y seque con toallas de papel. Combine orégano, comino, 2 cucharadas de aceite vegetal, chile chipotle, ajo, sal y pimienta, y frótelo sobre la carne de cerdo.':'Prepare la carne de cerdo: Precaliente el horno a 163 grados. Enjuague y seque con toallas de papel. Combine orégano, comino, 2 cucharadas de aceite vegetal, chile chipotle, ajo, sal y pimienta, y frótelo sobre la carne de cerdo.',
        'Coloque la carne de cerdo en una fuente de horno profunda y cubra con las cebollas picadas y los jalapeños. Agregue el jugo de naranja y la cerveza, y cubra bien con papel de aluminio. Hornee durante 2 a 3 horas hasta que la carne esté tierna.':'Coloque la carne de cerdo en una fuente de horno profunda y cubra con las cebollas picadas y los jalapeños. Agregue el jugo de naranja y la cerveza, y cubra bien con papel de aluminio. Hornee durante 2 a 3 horas hasta que la carne esté tierna.',
        'Haga la masa: espolvoree la levadura sobre la cerveza a temperatura ambiente y revuelva para disolver. Dejar reposar unos 15 minutos en un lugar cálido.':'Haga la masa: espolvoree la levadura sobre la cerveza a temperatura ambiente y revuelva para disolver. Dejar reposar unos 15 minutos en un lugar cálido.',
        'En el tazón de una batidora, combine la harina, el azúcar, la sal y el aceite, y mezcle a baja velocidad durante 30 segundos.':'En el tazón de una batidora, combine la harina, el azúcar, la sal y el aceite, y mezcle a baja velocidad durante 30 segundos.',
        'Agregue la mezcla de levadura y la cerveza fría, y mezcle a baja velocidad hasta que la masa se una. Aumente la velocidad ligeramente y amase la masa hasta que esté suave y comience a separarse de los lados del tazón (aproximadamente 8 minutos).':'Agregue la mezcla de levadura y la cerveza fría, y mezcle a baja velocidad hasta que la masa se una. Aumente la velocidad ligeramente y amase la masa hasta que esté suave y comience a separarse de los lados del tazón (aproximadamente 8 minutos).',
        'Coloque la masa sobre una superficie enharinada y amase a mano durante otros 1 a 2 minutos. Use harina adicional para espolvorear para evitar que se pegue. Forme la masa en una bola grande y colóquela en un recipiente ligeramente engrasado cubierto holgadamente con una envoltura de plástico. Deje que aumente a aproximadamente el doble de tamaño (aproximadamente 1 hora).':'Coloque la masa sobre una superficie enharinada y amase a mano durante otros 1 a 2 minutos. Use harina adicional para espolvorear para evitar que se pegue. Forme la masa en una bola grande y colóquela en un recipiente ligeramente engrasado cubierto holgadamente con una envoltura de plástico. Deje que aumente a aproximadamente el doble de tamaño (aproximadamente 1 hora).',
        'Prepare la salsa BBQ: caliente el aceite en una sartén grande y saltee las cebollas, los pimientos y el ajo a fuego medio hasta que estén transparentes. Agregue todos los ingredientes restantes, revolviendo bien, y hierva la salsa. Reduzca el fuego y cocine a fuego lento durante unos 15 minutos.':'Prepare la salsa BBQ: caliente el aceite en una sartén grande y saltee las cebollas, los pimientos y el ajo a fuego medio hasta que estén transparentes. Agregue todos los ingredientes restantes, revolviendo bien, y hierva la salsa. Reduzca el fuego y cocine a fuego lento durante unos 15 minutos.',
        'Retire la salsa del fuego y enfríe. Luego mezcle en un procesador de alimentos hasta que quede suave. (Ajuste el grosor de la salsa con agua. Debe ser como una crema espesa). Ajuste el condimento con sal y pimienta y refrigere.':'Retire la salsa del fuego y enfríe. Luego mezcle en un procesador de alimentos hasta que quede suave. (Ajuste el grosor de la salsa con agua. Debe ser como una crema espesa). Ajuste el condimento con sal y pimienta y refrigere.',
        'Haga la infusión de cerveza de chile: coloque los chiles anchos en una cacerola pequeña con la cerveza y cocine a fuego lento hasta que los chiles estén suaves y la cerveza se reduzca a la mitad. Sáquelo del fuego y apártelo.':'Haga la infusión de cerveza de chile: coloque los chiles anchos en una cacerola pequeña con la cerveza y cocine a fuego lento hasta que los chiles estén suaves y la cerveza se reduzca a la mitad. Sáquelo del fuego y apártelo.',
        'Divida la masa: después de que la masa haya subido, divídala en 4 partes iguales y amase suavemente cada pieza. Forme una bola, colóquela en una bandeja para hornear ligeramente engrasada y cubra con una envoltura de plástico. Deje reposar la masa durante unos 30 minutos.':'Divida la masa: después de que la masa haya subido, divídala en 4 partes iguales y amase suavemente cada pieza. Forme una bola, colóquela en una bandeja para hornear ligeramente engrasada y cubra con una envoltura de plástico. Deje reposar la masa durante unos 30 minutos.',
        'Extienda cada bola de masa con un rodillo sobre una superficie ligeramente enharinada en un círculo de 25 a 30 centímetros. Espolvorea ligeramente la masa enrollada con harina, cubre ligeramente con una toalla limpia y repite con las bolas restantes.':'Extienda cada bola de masa con un rodillo sobre una superficie ligeramente enharinada en un círculo de 25 a 30 centímetros. Espolvorea ligeramente la masa enrollada con harina, cubre ligeramente con una toalla limpia y repite con las bolas restantes.',
        'Termine el cerdo: cuando el cerdo esté listo, retírelo del molde para hornear y déjelo enfriar. Separarlo en trozos pequeños. Calienta el aceite restante en una sartén grande y fríe los trozos de carne de cerdo hasta que estén dorados y crujientes. Retire las piezas doradas del aceite y escúrralas en toallas de papel.':'Termine el cerdo: cuando el cerdo esté listo, retírelo del molde para hornear y déjelo enfriar. Separarlo en trozos pequeños. Calienta el aceite restante en una sartén grande y fríe los trozos de carne de cerdo hasta que estén dorados y crujientes. Retire las piezas doradas del aceite y escúrralas en toallas de papel.',
        'Precaliente una piedra de pizza en un horno de 232 grados.':'Precaliente una piedra de pizza en un horno de 232 grados.',
        'Ensamble las pizzas: combine la infusión de chile ancho y cerveza con la salsa BBQ y extienda un poco para cubrir cada pizza, dejando un borde de aproximadamente 1,5 centímetros. A continuación, espolvoree uniformemente la mozzarella y cubra con aproximadamente 1/2 taza de carne de cerdo, jalapeños, cebolla y queso de cabra. Coloque la pizza con cuidado sobre la piedra para pizza y hornee hasta que los bordes se doren, aproximadamente 10 minutos. Retirar del horno, espolvorear con cilantro y cortar en 8 rebanadas iguales.':'Ensamble las pizzas: combine la infusión de chile ancho y cerveza con la salsa BBQ y extienda un poco para cubrir cada pizza, dejando un borde de aproximadamente 1,5 centímetros. A continuación, espolvoree uniformemente la mozzarella y cubra con aproximadamente 1/2 taza de carne de cerdo, jalapeños, cebolla y queso de cabra. Coloque la pizza con cuidado sobre la piedra para pizza y hornee hasta que los bordes se doren, aproximadamente 10 minutos. Retirar del horno, espolvorear con cilantro y cortar en 8 rebanadas iguales.',
        'Para la masa de pizza: 2 tazas de harina de pan':'Para la masa de pizza: 2 tazas de harina de pan',
        'Para la masa de pizza: 2 1/2 cucharadas de aceite de oliva':'Para la masa de pizza: 2 1/2 cucharadas de aceite de oliva',
        'Para la masa de pizza: 2/3 tazas de Cerveza Sierra Fría Session IPA':'Para la masa de pizza: 2/3 tazas de Cerveza Sierra Fría Session IPA',
        'Para la masa de pizza: 1 pizca o más de sal':'Para la masa de pizza: 1 pizca o más de sal',
        'Para la cobertura: 8 a 12 tomates Campari pequeños a medianos o similares, en rodajas finas':'Para la cobertura: 8 a 12 tomates Campari pequeños a medianos o similares, en rodajas finas',
        'Para la cobertura: 1/2 taza de vinagre balsámico':'Para la cobertura: 1/2 taza de vinagre balsámico',
        'Para la cobertura: 10 cucharadas de aceite de oliva':'Para la cobertura: 10 cucharadas de aceite de oliva',
        'Para la cobertura: Sal y pimienta al gusto':'Para la cobertura: Sal y pimienta al gusto',
        'Para la cobertura: 6 dientes de ajo picados':'Para la cobertura: 6 dientes de ajo picados',
        'Para la cobertura: 4 a 6 tazas de queso mozzarella, rallado':'Para la cobertura: 4 a 6 tazas de queso mozzarella, rallado',
        'Para la cobertura: 170 gramos de queso de cabra desmenuzado':'Para la cobertura: 170 gramos de queso de cabra desmenuzado',
        'Para la cobertura: 1/4 taza de miel':'Para la cobertura: 1/4 taza de miel',
        'Prepare la masa: en un tazón grande, combine los ingredientes para la masa.':'Prepare la masa: en un tazón grande, combine los ingredientes para la masa.',
        'Mezcle hasta que pueda comenzar a amasar la masa. Amasar hasta que la masa no se rompa fácilmente. Cubra y deje reposar la masa durante 30 minutos.':'Mezcle hasta que pueda comenzar a amasar la masa. Amasar hasta que la masa no se rompa fácilmente. Cubra y deje reposar la masa durante 30 minutos.',
        'Precaliente el horno a 232 grados.':'Precaliente el horno a 232 grados.',
        'Prepare los tomates para la cobertura: coloque los tomates en un tazón y marina en el vinagre balsámico. Dejar de lado.':'Prepare los tomates para la cobertura: coloque los tomates en un tazón y marina en el vinagre balsámico. Dejar de lado.',
        'Una vez que la masa haya descansado, córtela en cuartos. Forme cada pieza en una bola y extienda cada una, usando un poco más de harina si es necesario, hasta aproximadamente 3 milímetros de grosor.':'Una vez que la masa haya descansado, córtela en cuartos. Forme cada pieza en una bola y extienda cada una, usando un poco más de harina si es necesario, hasta aproximadamente 3 milímetros de grosor.',
        'Ponga las rondas de masa en bandejas para hornear engrasadas con 2 pizzas por hoja.':'Ponga las rondas de masa en bandejas para hornear engrasadas con 2 pizzas por hoja.',
        'Divida los ingredientes en cuatro porciones cada uno. Cubra cada pizza con aceite, sal, pimienta y ajo picado. Agregue la mozzarella y las rodajas de tomate marinado. Agregue el queso de cabra desmenuzado, luego rocíe la miel generosamente sobre cada pizza.':'Divida los ingredientes en cuatro porciones cada uno. Cubra cada pizza con aceite, sal, pimienta y ajo picado. Agregue la mozzarella y las rodajas de tomate marinado. Agregue el queso de cabra desmenuzado, luego rocíe la miel generosamente sobre cada pizza.',
        'Hornee hasta que la corteza esté dorada y crujiente, aproximadamente de 10 a 15 minutos. Dejar enfriar unos minutos antes de servir.':'Hornee hasta que la corteza esté dorada y crujiente, aproximadamente de 10 a 15 minutos. Dejar enfriar unos minutos antes de servir.',
        '1 cucharadita de aceite de oliva':'1 cucharadita de aceite de oliva',
        '1 taza de cebolla picada':'1 taza de cebolla picada',
        '1 chalote grande, picado':'1 chalote grande, picado',
        '5 dientes de ajo picados':'5 dientes de ajo picados',
        '1/2 taza de Cerveza Sierra Fría Session IPA':'1/2 taza de Cerveza Sierra Fría Session IPA',
        '2 cucharadas de miel':'2 cucharadas de miel',
        '1/2 taza de pasta de tomate':'1/2 taza de pasta de tomate',
        'Pizca de pimienta negra recién molida':'Pizca de pimienta negra recién molida',
        '1 1/2 cucharaditas de pimiento rojo picado':'1 1/2 cucharaditas de pimiento rojo picado',
        '1 kilogramo de tomates roma, en puré':'1 kilogramo de tomates roma, en puré',
        '454 gramos de linguini (o espagueti)':'454 gramos de linguini (o espagueti)',
        '4 cucharadas de mantequilla':'4 cucharadas de mantequilla',
        '4 cucharadas de perejil fresco, picado':'4 cucharadas de perejil fresco, picado',
        'Queso parmesano recién rallado':'Queso parmesano recién rallado',
        'Pon a hervir una olla de agua (suficiente para cocinar los 454 gramos de pasta)':'Pon a hervir una olla de agua (suficiente para cocinar los 454 gramos de pasta)',
        'En una sartén grande o en una sartén, caliente el aceite hasta que esté caliente y saltee la cebolla, la chalota y el ajo hasta que estén transparentes, aproximadamente 6 minutos.':'En una sartén grande o en una sartén, caliente el aceite hasta que esté caliente y saltee la cebolla, la chalota y el ajo hasta que estén transparentes, aproximadamente 6 minutos.',
        'Agregue la cerveza, miel, pasta de tomate, pimienta negra y hojuelas de pimiento rojo. Combina bien':'Agregue la cerveza, miel, pasta de tomate, pimienta negra y hojuelas de pimiento rojo. Combina bien',
        'Mezcle los tomates en puré, hierva y reduzca el fuego a medio. Cocine a fuego lento durante 20 minutos, descubierto.':'Mezcle los tomates en puré, hierva y reduzca el fuego a medio. Cocine a fuego lento durante 20 minutos, descubierto.',
        'Cocine linguini o espagueti hasta que esté al dente. Escurrir y agregar a la sartén. Cocine por 1 minuto con salsa.':'Cocine linguini o espagueti hasta que esté al dente. Escurrir y agregar a la sartén. Cocine por 1 minuto con salsa.',
        'Justo antes de servir, revuelva la mantequilla para derretir, agregue sal al gusto y espolvoree con perejil y queso parmesano recién rallado.':'Justo antes de servir, revuelva la mantequilla para derretir, agregue sal al gusto y espolvoree con perejil y queso parmesano recién rallado.',
        'Para el asado a la olla: 4 cucharadas de aceite de canola o aceite de oliva':'Para el asado a la olla: 4 cucharadas de aceite de canola o aceite de oliva',
        'Para el asado a la olla: 1.36 kilogramos de carne de res asada':'Para el asado a la olla: 1.36 kilogramos de carne de res asada',
        'Para el asado a la olla: Sal y pimienta al gusto':'Para el asado a la olla: Sal y pimienta al gusto',
        'Para el asado a la olla: 2 cebollas blancas, cortadas en cubitos':'Para el asado a la olla: 2 cebollas blancas, cortadas en cubitos',
        'Para el asado a la olla: 1 manojo de apio picado':'Para el asado a la olla: 1 manojo de apio picado',
        'Para el asado a la olla: 2 racimos de zanahorias, picadas, guarda la cáscara':'Para el asado a la olla: 2 racimos de zanahorias, picadas, guarda la cáscara',
        'Para el asado a la olla: 6 dientes de ajo, machacados':'Para el asado a la olla: 6 dientes de ajo, machacados',
        'Para el asado a la olla: 1 taza de chalotes, cortados en cubitos':'Para el asado a la olla: 1 taza de chalotes, cortados en cubitos',
        'Para el asado a la olla: 4 tazas de caldo de res':'Para el asado a la olla: 4 tazas de caldo de res',
        'Para el asado a la olla: 650 mililitros de Cerveza Sierra Fría Porter':'Para el asado a la olla: 650 mililitros de Cerveza Sierra Fría Porter',
        'Para el asado a la olla: 1.36 kilogramos de papas rojas, en cuartos':'Para el asado a la olla: 1.36 kilogramos de papas rojas, en cuartos',
        'Para el asado a la olla: 3 hojas de laurel':'Para el asado a la olla: 3 hojas de laurel',
        'Últimos retoques: Cáscaras de zanahoria frescas, picadas':'Últimos retoques: Cáscaras de zanahoria frescas, picadas',
        'Últimos retoques: Cebolletas frescas, picadas':'Últimos retoques: Cebolletas frescas, picadas',
        'Últimos retoques: Perejil fresco, picado':'Últimos retoques: Perejil fresco, picado',
        'Últimos retoques: Pan francés a la parrilla':'Últimos retoques: Pan francés a la parrilla',
        'Precaliente el horno a 177 grados. Calienta el aceite hasta que salga humo del sartén. Sazone generosamente la carne con sal y pimienta, y dore por todos lados. (La sartén debe estar humeante) Cocine durante 3 a 5 minutos, hasta que la carne esté dorada por un lado, y luego gírela y dore el otro lado.':'Precaliente el horno a 177 grados. Calienta el aceite hasta que salga humo del sartén. Sazone generosamente la carne con sal y pimienta, y dore por todos lados. (La sartén debe estar humeante) Cocine durante 3 a 5 minutos, hasta que la carne esté dorada por un lado, y luego gírela y dore el otro lado.',
        'Después de dorar la carne, agregue la cebolla, apio, zanahoria, ajo y chalotes. Saltee durante 5 minutos y agregue el caldo, la cerveza, las papas y las hojas de laurel. Cubra herméticamente con papel aluminio y cocine durante 3 horas, volteando el asado después de 1 hora y media.':'Después de dorar la carne, agregue la cebolla, apio, zanahoria, ajo y chalotes. Saltee durante 5 minutos y agregue el caldo, la cerveza, las papas y las hojas de laurel. Cubra herméticamente con papel aluminio y cocine durante 3 horas, volteando el asado después de 1 hora y media.',
        'Sirva una porción de carne con papas y zanahorias, cubra con salsa y adorne con cáscaras de zanahoria, cebolletas y perejil fresco. El pan francés a la parrilla también es un gran acompañamiento.':'Sirva una porción de carne con papas y zanahorias, cubra con salsa y adorne con cáscaras de zanahoria, cebolletas y perejil fresco. El pan francés a la parrilla también es un gran acompañamiento.',
        'Para el ajo asado: 1 cabeza de ajo':'Para el ajo asado: 1 cabeza de ajo',
        'Para el ajo asado: 1 cucharada de aceite de oliva virgen extra':'Para el ajo asado: 1 cucharada de aceite de oliva virgen extra',
        'Para el ajo asado: Sal y pimienta negra recién molida, al gusto':'Para el ajo asado: Sal y pimienta negra recién molida, al gusto',
        'Para las papas: 1 kilogramo de papas doradas Yukon, cortadas en cuartos':'Para las papas: 1 kilogramo de papas doradas Yukon, cortadas en cuartos',
        'Para las papas: 2 cucharadas de aceite con ajo':'Para las papas: 2 cucharadas de aceite con ajo',
        'Para las papas: 1/4 de taza de Cerveza Sierra Fría Session IPA':'Para las papas: 1/4 de taza de Cerveza Sierra Fría Session IPA',
        'Para las papas: 1/4 cucharadita de levadura de cerveza':'Para las papas: 1/4 cucharadita de levadura de cerveza',
        'Para las papas: 1 cucharadita de perejil fresco, picado':'Para las papas: 1 cucharadita de perejil fresco, picado',
        'Para el llenado: 1 kilogramo de tempeh':'Para el llenado: 1 kilogramo de tempeh',
        'Para el llenado: 1 cucharada de chiles pasilla molidos':'Para el llenado: 1 cucharada de chiles pasilla molidos',
        'Para el llenado: 1 cucharada de chiles chipotle molidos':'Para el llenado: 1 cucharada de chiles chipotle molidos',
        'Para el llenado: 1 cucharada de ajo en polvo':'Para el llenado: 1 cucharada de ajo en polvo',
        'Para el llenado: 2 cucharaditas de comino molido':'Para el llenado: 2 cucharaditas de comino molido',
        'Para el llenado: 2 cucharaditas de sal':'Para el llenado: 2 cucharaditas de sal',
        'Para el llenado: 4 cucharadas de aceite de oliva':'Para el llenado: 4 cucharadas de aceite de oliva',
        'Para el llenado: 1/2 taza de cebollas dulces, cortadas en cubitos':'Para el llenado: 1/2 taza de cebollas dulces, cortadas en cubitos',
        'Para el llenado: 1/2 taza de zanahoria, cortada en cubitos':'Para el llenado: 1/2 taza de zanahoria, cortada en cubitos',
        'Para el llenado: 1/2 taza de apio, cortado en cubitos':'Para el llenado: 1/2 taza de apio, cortado en cubitos',
        'Para el llenado: 1/2 calabacín, cortado en cubitos':'Para el llenado: 1/2 calabacín, cortado en cubitos',
        'Para el llenado: 3 dientes de ajo picados':'Para el llenado: 3 dientes de ajo picados',
        'Para el llenado: 1/4 taza de vino blanco seco':'Para el llenado: 1/4 taza de vino blanco seco',
        'Para el llenado: 2 tazas de queso cheddar rallado':'Para el llenado: 2 tazas de queso cheddar rallado',
        'Ase el ajo: Precaliente el horno a 176 grados. Corta los 6 milímetros superiores del ajo, dejando la cabeza intacta pero exponiendo los dientes individuales. Coloque el ajo, cortado hacia arriba, sobre una hoja grande de papel de aluminio y rocíe con aceite y sazone con sal y pimienta. Reúna el papel de aluminio alrededor del ajo, gire la parte superior para sellar y coloque en el horno durante 1 hora. Deje que el ajo se enfríe por completo antes de exprimir suavemente los dientes.':'Ase el ajo: Precaliente el horno a 176 grados. Corta los 6 milímetros superiores del ajo, dejando la cabeza intacta pero exponiendo los dientes individuales. Coloque el ajo, cortado hacia arriba, sobre una hoja grande de papel de aluminio y rocíe con aceite y sazone con sal y pimienta. Reúna el papel de aluminio alrededor del ajo, gire la parte superior para sellar y coloque en el horno durante 1 hora. Deje que el ajo se enfríe por completo antes de exprimir suavemente los dientes.',
        'Prepare las papas: En una cacerola mediana a fuego alto, cubra las papas con agua con sal fría y hierva. Baje el fuego a medio, cubra y cocine hasta que las papas estén tiernas y fácilmente perforadas con un tenedor (alrededor de 15 a 20 minutos).':'Prepare las papas: En una cacerola mediana a fuego alto, cubra las papas con agua con sal fría y hierva. Baje el fuego a medio, cubra y cocine hasta que las papas estén tiernas y fácilmente perforadas con un tenedor (alrededor de 15 a 20 minutos).',
        'Escurra las papas, colóquelas en un tazón mediano y agregue el ajo asado, la cerveza, la levadura de cerveza y el perejil. Use un machacador de papas o ricer para trabajar las papas con la consistencia deseada. (Si están demasiado rígidos, agregue más cerveza). Sazone con sal y pimienta al gusto.':'Escurra las papas, colóquelas en un tazón mediano y agregue el ajo asado, la cerveza, la levadura de cerveza y el perejil. Use un machacador de papas o ricer para trabajar las papas con la consistencia deseada. (Si están demasiado rígidos, agregue más cerveza). Sazone con sal y pimienta al gusto.',
        'Haga el relleno: en un tazón grande, desmorone el tempeh con las manos y agregue los polvos de chile, el ajo en polvo, el comino y la sal. Revuelva hasta que esté bien combinado.':'Haga el relleno: en un tazón grande, desmorone el tempeh con las manos y agregue los polvos de chile, el ajo en polvo, el comino y la sal. Revuelva hasta que esté bien combinado.',
        'Caliente 2 cucharadas de aceite de oliva en una sartén grande o sartén de hierro fundido a fuego medio-alto hasta que el aceite comience a brillar. Agregue la cebolla, la zanahoria y el apio, y saltee hasta que comiencen a ablandarse, aproximadamente 3 minutos. Agregue el calabacín y cocine 2 minutos adicionales. Agregue el ajo y cocine por 1 minuto más.':'Caliente 2 cucharadas de aceite de oliva en una sartén grande o sartén de hierro fundido a fuego medio-alto hasta que el aceite comience a brillar. Agregue la cebolla, la zanahoria y el apio, y saltee hasta que comiencen a ablandarse, aproximadamente 3 minutos. Agregue el calabacín y cocine 2 minutos adicionales. Agregue el ajo y cocine por 1 minuto más.',
        'Desglasar la sartén con el vino blanco, revolviendo, raspando y disolviendo cualquier parte marrón que pueda estar pegada a la sartén. Una vez que el vino blanco se haya evaporado en su mayoría, mueva las verduras a los bordes exteriores de la sartén, haciendo un pozo en el centro. Agregue las 2 cucharadas soperas de aceite de oliva y caliente ligeramente.':'Desglasar la sartén con el vino blanco, revolviendo, raspando y disolviendo cualquier parte marrón que pueda estar pegada a la sartén. Una vez que el vino blanco se haya evaporado en su mayoría, mueva las verduras a los bordes exteriores de la sartén, haciendo un pozo en el centro. Agregue las 2 cucharadas soperas de aceite de oliva y caliente ligeramente.',
        'Agregue el tempeh al pozo, revolviendo para cubrir. Revuelva todo junto, salteando hasta que el tempeh se caliente y las verduras estén tiernas, aproximadamente de 4 a 5 minutos.':'Agregue el tempeh al pozo, revolviendo para cubrir. Revuelva todo junto, salteando hasta que el tempeh se caliente y las verduras estén tiernas, aproximadamente de 4 a 5 minutos.',
        'Precalentar el asador.':'Precalentar el asador.',
        'Vierte la mezcla de tempeh en un molde para hornear de 23 x 33 centímetros y extiende en una capa uniforme. Cubra con una capa uniforme de puré de papas y espolvoree la parte superior con queso.':'Vierte la mezcla de tempeh en un molde para hornear de 23 x 33 centímetros y extiende en una capa uniforme. Cubra con una capa uniforme de puré de papas y espolvoree la parte superior con queso.',
        'Ase a la parrilla hasta que el queso se derrita, burbujee y se dore, de 5 a 7 minutos.':'Ase a la parrilla hasta que el queso se derrita, burbujee y se dore, de 5 a 7 minutos.',
        'Para la mermelada de cerveza y tocino: 114 gramos de tocino crudo, cortado en cubos grandes':'Para la mermelada de cerveza y tocino: 114 gramos de tocino crudo, cortado en cubos grandes',
        'Para la mermelada de cerveza y tocino: 1 1/2 tazas de cebolla, cortada en cubos pequeños':'Para la mermelada de cerveza y tocino: 1 1/2 tazas de cebolla, cortada en cubos pequeños',
        'Para la mermelada de cerveza y tocino: 1/2 chile jalapeño, cortado en cubos pequeños (use guantes cuando corte el chile jalapeño en cubitos y evite tocarse los ojos)':'Para la mermelada de cerveza y tocino: 1/2 chile jalapeño, cortado en cubos pequeños (use guantes cuando corte el chile jalapeño en cubitos y evite tocarse los ojos)',
        'Para la mermelada de cerveza y tocino: 1/4 cucharada de ajo picado':'Para la mermelada de cerveza y tocino: 1/4 cucharada de ajo picado',
        'Para la mermelada de cerveza y tocino: 1 cucharadita de cilantro molido':'Para la mermelada de cerveza y tocino: 1 cucharadita de cilantro molido',
        'Para la mermelada de cerveza y tocino: 1/2 taza de azúcar granulada':'Para la mermelada de cerveza y tocino: 1/2 taza de azúcar granulada',
        'Para la mermelada de cerveza y tocino: 355 mililitros de Cerveza Sierra Fría Porter':'Para la mermelada de cerveza y tocino: 355 mililitros de Cerveza Sierra Fría Porter',
        'Para la mermelada de cerveza y tocino: 1/2 cucharadita de sal kosher':'Para la mermelada de cerveza y tocino: 1/2 cucharadita de sal kosher',
        'Para la mermelada de cerveza y tocino: 1/4 cucharadita de pimienta negra recién molida':'Para la mermelada de cerveza y tocino: 1/4 cucharadita de pimienta negra recién molida',
        'Para la mermelada de cerveza y tocino: 1/2 taza de mermelada de naranja':'Para la mermelada de cerveza y tocino: 1/2 taza de mermelada de naranja',
        'Para la mermelada de cerveza y tocino: 1/2 cucharada de hojas de tomillo fresco':'Para la mermelada de cerveza y tocino: 1/2 cucharada de hojas de tomillo fresco',
        'Para la salsa de la casa Bunz: 3/4 taza de mayonesa':'Para la salsa de la casa Bunz: 3/4 taza de mayonesa',
        'Para la salsa de la casa Bunz: 1/8 taza de mostaza integral':'Para la salsa de la casa Bunz: 1/8 taza de mostaza integral',
        'Para la salsa de la casa Bunz: 3 cucharadas de salsa de tomate Heinz':'Para la salsa de la casa Bunz: 3 cucharadas de salsa de tomate Heinz',
        'Para la salsa de la casa Bunz: 1 1/2 cucharaditas de salsa Worcestershire':'Para la salsa de la casa Bunz: 1 1/2 cucharaditas de salsa Worcestershire',
        'Para la salsa de la casa Bunz: 1/4 cucharadita de ajo en polvo':'Para la salsa de la casa Bunz: 1/4 cucharadita de ajo en polvo',
        'Para la salsa de la casa Bunz: 1/4 cucharadita de comino molido':'Para la salsa de la casa Bunz: 1/4 cucharadita de comino molido',
        'Para la salsa de la casa Bunz: 1/4 cucharadita de chile en polvo':'Para la salsa de la casa Bunz: 1/4 cucharadita de chile en polvo',
        'Para las hamburguesas: 600 gramos de carne molida (80 por ciento magra), dividida en 4 empanadas':'Para las hamburguesas: 600 gramos de carne molida (80 por ciento magra), dividida en 4 empanadas',
        'Para las hamburguesas: Sal y pimienta':'Para las hamburguesas: Sal y pimienta',
        'Para las hamburguesas: 2 chiles poblanos, asados, sin semillas y en rodajas':'Para las hamburguesas: 2 chiles poblanos, asados, sin semillas y en rodajas',
        'Para las hamburguesas: 4 hojas de lechuga romana':'Para las hamburguesas: 4 hojas de lechuga romana',
        'Para las hamburguesas: 8 rodajas de tomate':'Para las hamburguesas: 8 rodajas de tomate',
        'Para las hamburguesas: 8 rodajas de cebolla roja, separadas en aros':'Para las hamburguesas: 8 rodajas de cebolla roja, separadas en aros',
        'Para las hamburguesas: 4 bollos de hamburguesa de trigo integral':'Para las hamburguesas: 4 bollos de hamburguesa de trigo integral',
        'Prepara la mermelada: en una cacerola grande a fuego medio, fríe el tocino hasta que esté completamente cocido. Retire el tocino y escúrralo sobre toallas de papel, dejando la grasa en la sartén. En la misma sartén a fuego medio-alto, saltee la cebolla hasta que esté caramelizada, de 15 a 20 minutos. Agregue el jalapeño y el ajo, y saltee durante unos 2 minutos, hasta que estén tiernos. Agregue los ingredientes restantes (excepto el tomillo) y cocine rodando durante 10 a 15 minutos. Una vez que la mezcla se haya espesado, retírela del fuego y agregue el tomillo y los trozos de tocino cocido. Reserva.':'Prepara la mermelada: en una cacerola grande a fuego medio, fríe el tocino hasta que esté completamente cocido. Retire el tocino y escúrralo sobre toallas de papel, dejando la grasa en la sartén. En la misma sartén a fuego medio-alto, saltee la cebolla hasta que esté caramelizada, de 15 a 20 minutos. Agregue el jalapeño y el ajo, y saltee durante unos 2 minutos, hasta que estén tiernos. Agregue los ingredientes restantes (excepto el tomillo) y cocine rodando durante 10 a 15 minutos. Una vez que la mezcla se haya espesado, retírela del fuego y agregue el tomillo y los trozos de tocino cocido. Reserva.',
        'Prepara la salsa Bunz House: combina bien todos los ingredientes en un tazón.':'Prepara la salsa Bunz House: combina bien todos los ingredientes en un tazón.',
        'Precalentar una parrilla en alto.':'Precalentar una parrilla en alto.',
        'Ase las hamburguesas a la parrilla: agregue sal y pimienta de manera uniforme en cada hamburguesa y ase a la temperatura deseada.':'Ase las hamburguesas a la parrilla: agregue sal y pimienta de manera uniforme en cada hamburguesa y ase a la temperatura deseada.',
        'Ensamble las hamburguesas: tueste los bollos ligeramente en la parrilla. Agregue un poco de salsa Bunz en la parte inferior y superior de cada bollo, y coloque una empanada en la mitad inferior de cada bollo. Coloque unas rodajas de chile poblano, un trozo de lechuga, 2 rodajas de tomate y 2 aros de cebolla en cada hamburguesa. Cubra con 2 cucharadas de mermelada de tocino y la parte superior del bollo.':'Ensamble las hamburguesas: tueste los bollos ligeramente en la parrilla. Agregue un poco de salsa Bunz en la parte inferior y superior de cada bollo, y coloque una empanada en la mitad inferior de cada bollo. Coloque unas rodajas de chile poblano, un trozo de lechuga, 2 rodajas de tomate y 2 aros de cebolla en cada hamburguesa. Cubra con 2 cucharadas de mermelada de tocino y la parte superior del bollo.',
        'Para el pollo: 2 kilogramos de pollo, preferiblemente muslos deshuesados':'Para el pollo: 2 kilogramos de pollo, preferiblemente muslos deshuesados',
        'Para el pollo: 1 chalote, finamente cortado en cubitos':'Para el pollo: 1 chalote, finamente cortado en cubitos',
        'Para el pollo: 4 dientes de ajo, finamente picados':'Para el pollo: 4 dientes de ajo, finamente picados',
        'Para el pollo: 1 cebolla roja, finamente picada':'Para el pollo: 1 cebolla roja, finamente picada',
        'Para el pollo: 4 a 5 pimientos habaneros, asados y sin semillas (Use guantes cuando trabaje con pimientos y evite tocarse los ojos)':'Para el pollo: 4 a 5 pimientos habaneros, asados y sin semillas (Use guantes cuando trabaje con pimientos y evite tocarse los ojos)',
        'Para el pollo: 6 limas, en jugo':'Para el pollo: 6 limas, en jugo',
        'Para el pollo: 2 naranjas sanguinas, en jugo':'Para el pollo: 2 naranjas sanguinas, en jugo',
        'Para el pollo: 2 cucharadas de sal':'Para el pollo: 2 cucharadas de sal',
        'Para el pollo: 1 cucharada de pimienta':'Para el pollo: 1 cucharada de pimienta',
        'Para el pollo: 1 cucharada de azúcar morena':'Para el pollo: 1 cucharada de azúcar morena',
        'Para el pollo: 2 cucharadas de miel':'Para el pollo: 2 cucharadas de miel',
        'Para el pollo: 1 rama de canela':'Para el pollo: 1 rama de canela',
        'Para el pollo: 2 cucharaditas de pimienta de Jamaica':'Para el pollo: 2 cucharaditas de pimienta de Jamaica',
        'Para el pollo: 1 cucharadita de nuez moscada':'Para el pollo: 1 cucharadita de nuez moscada',
        'Para el pollo: 650 mililitros de Cerveza Sierra Fría Porter':'Para el pollo: 650 mililitros de Cerveza Sierra Fría Porter',
        'Para la succotash: 2 cucharadas de aceite de oliva':'Para la succotash: 2 cucharadas de aceite de oliva',
        'Para la succotash: 1/2 taza de cebolla roja, cortada en dados pequeños':'Para la succotash: 1/2 taza de cebolla roja, cortada en dados pequeños',
        'Para la succotash: 1/2 taza de apio, cortado en dados pequeños':'Para la succotash: 1/2 taza de apio, cortado en dados pequeños',
        'Para la succotash: 1/2 taza de zanahoria, cortada en dados pequeños':'Para la succotash: 1/2 taza de zanahoria, cortada en dados pequeños',
        'Para la succotash: 1/8 taza de pimientos Anaheim, cortados en dados pequeños':'Para la succotash: 1/8 taza de pimientos Anaheim, cortados en dados pequeños',
        'Para la succotash: 1 diente de ajo, finamente picado':'Para la succotash: 1 diente de ajo, finamente picado',
        'Para la succotash: 1/2 taza de alubias frescas (o habas)':'Para la succotash: 1/2 taza de alubias frescas (o habas)',
        'Para la succotash: 1 cucharada de mantequilla':'Para la succotash: 1 cucharada de mantequilla',
        'Para la succotash: Sal y pimienta al gusto':'Para la succotash: Sal y pimienta al gusto',
        'Marinar el pollo: combine todos los ingredientes, incluido el pollo, en un tazón grande. Marinar, refrigerado, durante al menos 2 horas, pero preferiblemente durante la noche.':'Marinar el pollo: combine todos los ingredientes, incluido el pollo, en un tazón grande. Marinar, refrigerado, durante al menos 2 horas, pero preferiblemente durante la noche.',
        'Cuando el pollo esté listo para cocinar, prepare la succotash. En una sartén caliente, combine el aceite de oliva, la cebolla, el apio, la zanahoria, la pimienta de Anaheim y el ajo. Cocine por 4 minutos, o hasta que las cebollas y el apio comiencen a ablandarse. Revolviendo continuamente, agregue los frijoles. Cocine por otros 4 minutos y retire del fuego. Agregue la mantequilla, sal y pimienta, mezcle bien y reserve.':'Cuando el pollo esté listo para cocinar, prepare la succotash. En una sartén caliente, combine el aceite de oliva, la cebolla, el apio, la zanahoria, la pimienta de Anaheim y el ajo. Cocine por 4 minutos, o hasta que las cebollas y el apio comiencen a ablandarse. Revolviendo continuamente, agregue los frijoles. Cocine por otros 4 minutos y retire del fuego. Agregue la mantequilla, sal y pimienta, mezcle bien y reserve.',
        'Cubra ligeramente la superficie de cocción de una parrilla con aceite de oliva antes de encenderla a fuego medio. Deje que la parrilla se caliente durante 5 o 10 minutos antes de colocar el pollo marinado en la parrilla.':'Cubra ligeramente la superficie de cocción de una parrilla con aceite de oliva antes de encenderla a fuego medio. Deje que la parrilla se caliente durante 5 o 10 minutos antes de colocar el pollo marinado en la parrilla.',
        'Asa el pollo durante 4 a 5 minutos por cada lado, hasta que esté completamente cocido. Use un termómetro de carne para asegurarse de que el pollo haya alcanzado una temperatura interna de 74 a 77 grados.':'Asa el pollo durante 4 a 5 minutos por cada lado, hasta que esté completamente cocido. Use un termómetro de carne para asegurarse de que el pollo haya alcanzado una temperatura interna de 74 a 77 grados.',
        'Arme este plato en una fuente grande, estilo familiar, colocando la succotash en el centro y colocando el pollo, caliente de la parrilla, encima.':'Arme este plato en una fuente grande, estilo familiar, colocando la succotash en el centro y colocando el pollo, caliente de la parrilla, encima.',
        '1/2 taza más 3 cucharadas de mantequilla':'1/2 taza más 3 cucharadas de mantequilla',
        '3 manzanas, peladas, sin corazón y cortadas en cubos medianos (Granny Smith, Galas o Fujis)':'3 manzanas, peladas, sin corazón y cortadas en cubos medianos (Granny Smith, Galas o Fujis)',
        '1 taza de azúcar morena':'1 taza de azúcar morena',
        '1 huevo':'1 huevo',
        '1 1/2 tazas de harina para todo uso':'1 1/2 tazas de harina para todo uso',
        '1 cucharadita de levadura en polvo':'1 cucharadita de levadura en polvo',
        '1/2 cucharadita de bicarbonato de sodio':'1/2 cucharadita de bicarbonato de sodio',
        '1/2 cucharadita de canela molida':'1/2 cucharadita de canela molida',
        'Pizca de sal':'Pizca de sal',
        '1 taza de Cerveza Sierra Fría Porter':'1 taza de Cerveza Sierra Fría Porter',
        'Precaliente el horno a 163 grados. Forre moldes para muffins con vasos de papel o engrase.':'Precaliente el horno a 163 grados. Forre moldes para muffins con vasos de papel o engrase.',
        'En una sartén grande para saltear, derrita 3 cucharadas de mantequilla y saltee las manzanas hasta que estén blandas (alrededor de 10 a 15 minutos). Dejar de lado.':'En una sartén grande para saltear, derrita 3 cucharadas de mantequilla y saltee las manzanas hasta que estén blandas (alrededor de 10 a 15 minutos). Dejar de lado.',
        'En un tazón, mezcle la mantequilla restante y el azúcar morena hasta que quede esponjoso. Agregue el huevo y bata hasta que esté combinado.':'En un tazón, mezcle la mantequilla restante y el azúcar morena hasta que quede esponjoso. Agregue el huevo y bata hasta que esté combinado.',
        'En un tazón mediano, mezcle los ingredientes secos. Agregue la mezcla seca a la mezcla de mantequilla y mezcle hasta que no haya grumos y todo esté incorporado. Agregue la cerveza y las manzanas. Llene los moldes para muffins aproximadamente tres cuartos del camino y hornee durante unos 15 minutos. Pruebe la cocción colocando un palillo de dientes en el centro de un panecillo; el palillo de dientes debe salir con solo unas pocas migajas. Enfríe completamente antes de servir.':'En un tazón mediano, mezcle los ingredientes secos. Agregue la mezcla seca a la mezcla de mantequilla y mezcle hasta que no haya grumos y todo esté incorporado. Agregue la cerveza y las manzanas. Llene los moldes para muffins aproximadamente tres cuartos del camino y hornee durante unos 15 minutos. Pruebe la cocción colocando un palillo de dientes en el centro de un panecillo; el palillo de dientes debe salir con solo unas pocas migajas. Enfríe completamente antes de servir.',
        'Para la masa: 4 tazas de Cerveza Sierra Fría Porter, medido sin espuma':'Para la masa: 4 tazas de Cerveza Sierra Fría Porter, medido sin espuma',
        'Para la masa: 4 barras (2 tazas) de mantequilla derretida':'Para la masa: 4 barras (2 tazas) de mantequilla derretida',
        'Para la masa: 1 1/2 tazas de cacao en polvo sin azúcar':'Para la masa: 1 1/2 tazas de cacao en polvo sin azúcar',
        'Para la masa: 4 tazas de harina para todo uso':'Para la masa: 4 tazas de harina para todo uso',
        'Para la masa: 3 1/2 tazas de azúcar granulada':'Para la masa: 3 1/2 tazas de azúcar granulada',
        'Para la masa: 3 cucharaditas de bicarbonato de sodio':'Para la masa: 3 cucharaditas de bicarbonato de sodio',
        'Para la masa: 1 1/2 cucharaditas de sal':'Para la masa: 1 1/2 cucharaditas de sal',
        'Para la masa: 227 gramos de crema agria':'Para la masa: 227 gramos de crema agria',
        'Para la masa: 4 huevos':'Para la masa: 4 huevos',
        'Para el glaseado: 3 barras (1 1/2 tazas) de mantequilla, ablandadas':'Para el glaseado: 3 barras (1 1/2 tazas) de mantequilla, ablandadas',
        'Para el glaseado: 680 gramos de queso crema, ablandado':'Para el glaseado: 680 gramos de queso crema, ablandado',
        'Para el glaseado: 454 gramos de azúcar en polvo':'Para el glaseado: 454 gramos de azúcar en polvo',
        'Para el glaseado: 1 1/2 cucharaditas de extracto puro de vainilla':'Para el glaseado: 1 1/2 cucharaditas de extracto puro de vainilla',
        'Últimos retoques: Salsa de caramelo':'Últimos retoques: Salsa de caramelo',
        'Últimos retoques: Salsa de chocolate o dulce de azúcar':'Últimos retoques: Salsa de chocolate o dulce de azúcar',
        'Precaliente el horno a 190 grados.':'Precaliente el horno a 190 grados.',
        'En una sartén, calienta la cerveza hasta que se reduzca a la mitad. Agregue la mantequilla y revuelva para combinar.':'En una sartén, calienta la cerveza hasta que se reduzca a la mitad. Agregue la mantequilla y revuelva para combinar.',
        'En el tazón de una batidora, combine el cacao en polvo, la harina, el azúcar, el bicarbonato de sodio y la sal. Mezcle a temperatura baja durante 1 minuto, o hasta que esté bien combinado.':'En el tazón de una batidora, combine el cacao en polvo, la harina, el azúcar, el bicarbonato de sodio y la sal. Mezcle a temperatura baja durante 1 minuto, o hasta que esté bien combinado.',
        'Agregue lentamente la mezcla de cerveza a los ingredientes secos y mezcle hasta que esté bien incorporada.':'Agregue lentamente la mezcla de cerveza a los ingredientes secos y mezcle hasta que esté bien incorporada.',
        'Agregue la crema agria y mezcle. Agregue los huevos y mezcle a velocidad media hasta que quede suave.':'Agregue la crema agria y mezcle. Agregue los huevos y mezcle a velocidad media hasta que quede suave.',
        'Permita que la masa descanse durante 20 minutos (para eliminar las bolsas de aire).':'Permita que la masa descanse durante 20 minutos (para eliminar las bolsas de aire).',
        'Coloque bolsas de papel en los pozos de moldes para muffins o engrase los pozos. Llene las bolsas con cantidades iguales de masa y colóquelas en el horno durante 20 a 25 minutos. Inserte un palillo en el medio de un pastelito después de 20 minutos. Si el palillo sale limpio, los pastelitos están listos. Enfriar a temperatura ambiente.':'Coloque bolsas de papel en los pozos de moldes para muffins o engrase los pozos. Llene las bolsas con cantidades iguales de masa y colóquelas en el horno durante 20 a 25 minutos. Inserte un palillo en el medio de un pastelito después de 20 minutos. Si el palillo sale limpio, los pastelitos están listos. Enfriar a temperatura ambiente.',
        'Haga el glaseado: en una batidora con un accesorio de paleta, batir la mantequilla hasta que se vuelva de color claro y duplique su volumen (aproximadamente 5 minutos).':'Haga el glaseado: en una batidora con un accesorio de paleta, batir la mantequilla hasta que se vuelva de color claro y duplique su volumen (aproximadamente 5 minutos).',
        'Agregue el queso crema y batir por 2 minutos. Raspe los lados del tazón.':'Agregue el queso crema y batir por 2 minutos. Raspe los lados del tazón.',
        'Agregue el azúcar en polvo y la vainilla, y mezcle a baja velocidad hasta que quede suave y cremosa (aproximadamente 2 minutos). Refrigere hasta que sea necesario.':'Agregue el azúcar en polvo y la vainilla, y mezcle a baja velocidad hasta que quede suave y cremosa (aproximadamente 2 minutos). Refrigere hasta que sea necesario.',
        'Cuando las magdalenas estén heladas, rocíe con salsa de caramelo y/o salsa de chocolate caliente. Servir.':'Cuando las magdalenas estén heladas, rocíe con salsa de caramelo y/o salsa de chocolate caliente. Servir.',
        'Alitas de Pollo Buffalo':'Alitas de Pollo Buffalo',
        'Para la salsa de queso azul: 60 ml de queso roquefort':'Para la salsa de queso azul: 60 ml de queso roquefort',
        'Para la salsa de queso azul: 60 ml de suero de leche':'Para la salsa de queso azul: 60 ml de suero de leche',
        'Para la salsa de queso azul: 60 ml de mayonesa':'Para la salsa de queso azul: 60 ml de mayonesa',
        'Para la salsa de queso azul: 60 ml de crema agria':'Para la salsa de queso azul: 60 ml de crema agria',
        'Para la salsa de queso azul: 1 cucharada de jugo de limón':'Para la salsa de queso azul: 1 cucharada de jugo de limón',
        'Para la salsa de queso azul: Sal y pimienta negra':'Para la salsa de queso azul: Sal y pimienta negra',
        'Para las alitas: 2 cucharadas de mantequilla sin sal, derretida':'Para las alitas: 2 cucharadas de mantequilla sin sal, derretida',
        'Para las alitas: 120 ml de salsa picante':'Para las alitas: 120 ml de salsa picante',
        'Para las alitas: 1/2 cucharadita de pimienta cayena':'Para las alitas: 1/2 cucharadita de pimienta cayena',
        'Para las alitas: Sal y pimienta negra':'Para las alitas: Sal y pimienta negra',
        'Para las alitas: 2 litros de aceite vegetal para freír':'Para las alitas: 2 litros de aceite vegetal para freír',
        'Para las alitas: 90 gramos de maizena':'Para las alitas: 90 gramos de maizena',
        'Para las alitas: 1.5 kilos de alitas de pollo':'Para las alitas: 1.5 kilos de alitas de pollo',
        'Para las alitas: 4 apios, cortados finamente':'Para las alitas: 4 apios, cortados finamente',
        'Para la salsa de queso azul: Mezclamos el roquefort, el suero de leche, la mayonesa, la crema agria, y el jugo de limón en un bol mediano. Lo cubrimos y lo metimos en la nevera. Añadimos unas pizcas de sal y pimienta. Podemos hacer esto hasta dos días antes.':'Para la salsa de queso azul: Mezclamos el roquefort, el suero de leche, la mayonesa, la crema agria, y el jugo de limón en un bol mediano. Lo cubrimos y lo metimos en la nevera. Añadimos unas pizcas de sal y pimienta. Podemos hacer esto hasta dos días antes.',
        'Para las alitas: Dejamos las alitas fuera de la nevera para media hora, para coger la temperatura de ambiente para que cocinen equilibradamente. Mientras, derritamos las mantequilla en una olla pequeña.':'Para las alitas: Dejamos las alitas fuera de la nevera para media hora, para coger la temperatura de ambiente para que cocinen equilibradamente. Mientras, derritamos las mantequilla en una olla pequeña.',
        'Para las alitas: Mezclamos la salsa picante, la cayena, 1/2 cucharadita de pimienta negra y otra de sal. Quitamos del fuego y la recalentamos justo antes de bañar las alitas en ella.':'Para las alitas: Mezclamos la salsa picante, la cayena, 1/2 cucharadita de pimienta negra y otra de sal. Quitamos del fuego y la recalentamos justo antes de bañar las alitas en ella.',
        'Para las alitas: Echamos el aceite en una olla grande, o una freidora. Calentamos hasta que llegue a 190 grados.':'Para las alitas: Echamos el aceite en una olla grande, o una freidora. Calentamos hasta que llegue a 190 grados.',
        'Para las alitas: En un tazón grande, echamos la maizena y sazonamos las alitas con sal y pimienta. En tres grupos, rebozamos las alitas en la maizena, quitando el exceso antes de echarlas en el aceite. La damos vueltas de vez en cuando hasta que la piel esté dorada y crujiente, unos 10-12 minutos.':'Para las alitas: En un tazón grande, echamos la maizena y sazonamos las alitas con sal y pimienta. En tres grupos, rebozamos las alitas en la maizena, quitando el exceso antes de echarlas en el aceite. La damos vueltas de vez en cuando hasta que la piel esté dorada y crujiente, unos 10-12 minutos.',
        'Para las alitas: Transferimos las alitas a un tazón grande, añadimos la salsa calentada y mezclamos.':'Para las alitas: Transferimos las alitas a un tazón grande, añadimos la salsa calentada y mezclamos.',
        'Para las alitas: Servimos las alitas con apio y el queso azul.':'Para las alitas: Servimos las alitas con apio y el queso azul.',
        'BlogTitulo':'Blog',
        'BlogSubtitulo':'Conoce sobre nuestras aventuras y las últimas noticias del mundo cervecero.',
        'BlogLeer':'Leer Más',
        'BlogVacio':'Lo sentimos, no hay artículos.',
        'comentariosFormularioTitulo':'Deja un comentario',
        'comentarioNombre':'Nombre',
        'comentarioCorreo':'Correo',
        'comentarioMensaje':'Escribe tu comentario...',
        'comentarioEnviar':'Enviar',
        'comentariosTitulo':'Comentarios',
        'compartirTitulo':'Compartir'
    },
    'en': {
        'menuInicio': 'Home',
        'menuTiendas': 'Where to buy?',
        'menuProductos': 'Products',
        'menuRecetas': 'Recipes',
        'menuBlog': 'Blog',
        'menuContacto': 'Contact',
        'menuCarrito': 'Cart',
        'menuSelect': 'Menu',
        'menuSiguenos': 'Follow Us',
        'menuMusica': 'Music',
        'menuPedidos': 'My Orders',
        'menuIniciarSesion': 'Log In',
        'menuCerrarSesion': 'Logout',
        'footerDeveloper': 'Site developed by',
        'visitorMensaje': 'We request the following information to know the type of people who enter our website. Thanks for your help!',
        'visitorCorreo': 'Email',
        'visitorEdad': 'Age',
        'visitorGenero': 'Gender',
        'visitorGeneroSelect': 'Select an option...',
        'visitorEstado': 'State of Residence',
        'visitorEstadoSelect': 'Select your state...',
        'visitorEnviar': 'Send my data',
        'visitorCancelar': 'I don\'t want to share my data',
        'visitorValidateEdad': 'Fill the age please.',
        'visitorValidateEdadValida': 'The age must be a value greater than 0 and less than 100 please.',
        'visitorValidateGenero': 'Fill your gender please.',
        'visitorValidateEstado': 'Fill your state of residence please.',
        'visitorMasculino': 'Male',
        'visitorFemenino': 'Female',
        'direccionTitulo': 'Where are we going to send your beers?',
        'direccionCalle': 'Street',
        'direccionNumero': 'Number',
        'direccionColonia': 'Suburb',
        'direccionPostal': 'Postal Code',
        'direccionCiudad': 'City',
        'direccionEstado': 'State',
        'direccionValidateCalle': 'Fill the street please.',
        'direccionValidateNumero': 'Fill the number please.',
        'direccionValidateColonia': 'Fill the suburb please.',
        'direccionValidatePostal': 'Fill the postal code please.',
        'direccionValidateCiudad': 'Fill the city please.',
        'direccionValidateEstado': 'Fill the state please.',
        'contactoNombre': 'Name',
        'contactoCorreo': 'Email',
        'contactoAsunto': 'Subject',
        'contactoMensaje': 'Write your message...',
        'contactoEnviar': 'Send Message',
        'contactoValidateNombre': 'Fill the name please.',
        'contactoValidateAsunto': 'Fill the subject please.',
        'promotionCorreo': 'Email',
        'promotionSuscribirse': 'Subscribe',
        'ValidateCorreo': 'Fill the email please.',
        'ValidateCorreoValido': 'Write a valid email please.',
        'HomeExplora': 'Explore',
        'HomeExploraSubtitulo': 'An exciting beer',
        'HomeHistoriaTitulo': 'Our History',
        'HomeHistoriaP1': 'Cerveza Sierra Fría is born from combining the adventurous spirit, living new experiences, with a unique drink that refreshes our day. The place that gives rise to us is the midpoint between the highest mountain in Mexico and the beach.',
        'HomeHistoriaP2': 'What inspired us to have a unique Beer with flavors that reflect the depth of the unknown forest and the freshness and joy of the coast.',
        'HomeHistoriaP3': 'Always having in the background that mountain body that, with its high snowy, cold parts, refreshes us like each of our varieties.',
        'HomeHistoriaP4': 'The best companions to enjoy: meetings, celebrations and meals that pair with our great Mexican cuisine.',
        'HomeFrase': 'Reinvent your way of living',
        'HomeTestimonio1': 'Congratulations Cerveza Sierra Fría, for its Porter beer, a true delight in body and flavor!',
        'HomeTestimonio2': 'Without being an expert, I thought that beer is very good, when drinking it, you can notice its composition and notes of cocoa. It is a full-bodied beer, I relax a lot and immediately put me in a warm atmosphere with a marked appreciation of the moment.',
        'HomeTestimonio3': 'I really liked that it was a craft beer from the taste, to the history. I would try it again and again, my favorite.',
        'HomeTestimonio4': 'I loved the IPA for its flavor and especially for the herbal, citrus aromas. I did not expect it, I was pleasantly surprised. It enveloped me in the atmosphere of adventure.',
        'HomeTestimonio5': 'I really liked the Sierra Fría Porter beer with roast beef and my friends, the IPA with fish enchanted me!!, it took me to the beach.',
        'Anterior': 'Previous',
        'Siguiente': 'Next',
        'HomeBeneficiosTitulo': 'Discover new experiences with our flavors',
        'HomeBeneficiosNombre1': 'Flavor',
        'HomeBeneficiosSubtitulo1': 'Depth of flavors so you can enjoy that moment with the people you love the most.',
        'HomeBeneficiosNombre2': 'Gastronomy',
        'HomeBeneficiosSubtitulo2': 'Discover a new world with the different dishes that you can enjoy with our beers.',
        'HomeBeneficiosNombre3': 'Adventure',
        'HomeBeneficiosSubtitulo3': 'Our beers are a worthwhile adventure to enjoy extraordinary moments.',
        'HomeBeneficiosNombre4': 'Emotions',
        'HomeBeneficiosSubtitulo4': 'Beer is an exciting drink with great complexity as well as the emotions of everyday life.',
        'HomeSocialTitulo': 'Our Social Networks',
        'HomePromocionesTitulo': 'Participate in our promotions',
        'HomeActividadesTitulo': 'How to enjoy our beer?',
        'HomeActividades1': 'Sports',
        'HomeActividades2': 'Travels',
        'HomeActividades3': 'Friends',
        'HomeActividades4': 'Gastronomy',
        'ModalBotonContinuar': 'Continue',
        'ModalLoSentimos': 'We are sorry!',
        'ModalGracias': 'Thank you!',
        'ModalPromotionExito': 'Now you will receive emails with our promotions.',
        'ModalPromotionError': 'There was a problem with your subscription.',
        'TiendasTitulo': 'Shops',
        'TiendasSubtitulo': 'Find the closest place to you to enjoy our beer.',
        'ProductosTitulo': 'Our Beers',
        'ProductosSubtitulo': 'Made to enjoy a good meal with those you love the most.',
        'ProductosContenido': 'Content',
        'ProductosAlcohol': 'Alcohol Volume',
        'RecetasTitulo': 'Recipes',
        'RecetasSubtitulo': 'Our list of ideal dishes to pair with our beers.',
        'ContactoTitulo': 'Contact Us',
        'ContactoSubtitulo': 'We are interested in hearing your opinion, questions and suggestions.',
        'ModalContactoExito': 'Your message has been sent successfully.',
        'ModalContactoError': 'There was a problem sending your message.',
        'ViewRecipeIngredientes': 'Ingredients',
        'ViewRecipeInstrucciones': 'Instructions',
        'ViewProductModalExito': 'Your product has been successfully added.',
        'ViewProductAddCart': 'Add to cart',
        'ViewProductInformacion': 'Information',
        'ViewProductSabor': 'Flavor',
        'ViewProductMaridaje': 'Pairing',
        'ViewProductAspecto': 'Appearance',
        'ViewProductAromas': 'Scents',
        'ViewProductCategoria': 'Category',
        'ViewProductContenido': 'Content',
        'ViewProductAlcohol': 'Alcohol Volume',
        'ViewProductAmargor': 'Bitterness IBUs',
        'LoginTitulo': 'A new adventure begins',
        'LoginSubtitulo': 'Start enjoying our beers and reinvent your way of life.',
        'LoginGoogle': 'Login with Google',
        'LoginFacebook': 'Login with Facebook',
        'PedidoCapturado': 'Captured',
        'ModalPedidoExito': 'Your order has been created successfully.',
        'ModalPedidoCorreoError': 'There was a problem sending the email about your new order.',
        'ModalPedidoPagoError': 'There was a problem creating your order. Check your payment method.',
        'CarritoTitulo': 'Order summary',
        'CarritoEliminar': 'Remove',
        'CarritoProducto': 'Product',
        'CarritoPrecio': 'Price',
        'CarritoCantidad': 'Quantity',
        'CarritoTotal': 'Total',
        'CarritoActualizar': 'Update cart',
        'CarritoNoLogged': 'You must log in with your Google or Facebook account to place your order.',
        'CarritoLogIn': 'Log In',
        'CarritoPagar': 'Pay',
        'CarritoFinalizar': 'Finalize Purchase',
        'CarritoVacio': 'Your cart is empty.',
        'PedidosVer': 'View',
        'PedidosTitulo': 'My Orders',
        'PedidosFecha': 'Date',
        'PedidosDireccion': 'Address',
        'PedidosTotal': 'Total',
        'PedidosEstado': 'State',
        'PedidosDetalles': 'Details',
        'PedidosVacio': 'You have no orders yet.',
        'VerPedidoTitulo': 'Order details',
        'VerPedidoVolver': 'Go Back',
        'VerPedidoInformacion': 'Order Information',
        'VerPedidoFecha': 'Date',
        'VerPedidoDireccion': 'Address',
        'VerPedidoTotal': 'Total',
        'VerPedidoEstado': 'State',
        'VerPedidoNombre': 'Name',
        'VerPedidoCantidad': 'Quantity',
        'VerPedidoPrecio': 'Price',
        'VerPedidoVacio': 'Sorry, there are no records.',
        'PedidoPagado': 'Paid',
        'PedidoEnviado': 'Sent',
        'PedidoEntregado': 'Delivered',
        'CarritoEnvio': 'We will send you an email with the shipping options and once we receive your selection we will send your order and charge your card.',
        'LogoSubtitulo': 'Craft Beer',
        'HeaderFrase': 'Live the Adventure!',
        'Entrada': 'Starter',
        'PlatilloFuerte': 'Main Course',
        'Postre': 'Dessert',
        'A malta obscura, notas a chocolate, café y presencia de tostado al final; con persistencia en boca y cuerpo.': 'A dark malt, notes of chocolate, coffee and the presence of toast at the end; with persistence in the mouth and body.',
        'Fresco, ligero, cítricos.': 'Fresh, light, citrus.',
        'Equilibra sabores fuertes como son carne asada, ahumada, condimentada, barbacoa. Quesos ahumados, maduros: parmesano, gruyer, gouda y pan. postres de chocolate.': 'It balances strong flavors such as roast, smoked, seasoned, barbecue. Smoked, ripe cheeses: parmesan, gruyer, gouda and bread. chocolate desserts.',
        'Matices profundos ámbar, café obscuro achocolatado. Espuma color marrón claro.': 'Deep nuances of amber, dark chocolate brown. Light brown foam.',
        'A granos tostados, chocolate, café, caramelo, pan.': 'To roasted beans, chocolate, coffee, caramel, bread.',
        'Comida mexicana, guacamole, carnes blancas a la parrilla, ensaladas, botanas, sushi y mariscos.': 'Mexican food, guacamole, grilled white meats, salads, snacks, sushi and seafood.',
        'Ámbar dorado, con buena persistencia en boca, con espuma blanca.': 'Golden amber, with good persistence in the mouth, with white foam.',
        'Notas cítricas y frutales. Con presencia de lúpulo moderado.': 'Citrus and fruit notes. With the presence of moderate hops.',
        'Ceviche de Tilapia': 'Tilapia Ceviche',
        'Dip de queso y cerveza':'Cheese and beer dip',
        'El famoso fuerte fromage de Toronado, San Diego':'The famous fort fromage of Toronado, San Diego',
        'Sopa de coliflor, cerveza y gruyere':'Cauliflower soup, beer and gruyere',
        'Ceviche de camarones con cerveza':'Shrimp ceviche with beer',
        'Sándwich de pollo vaquero':'Cowboy Chicken Sandwich',
        'Pizza de carnitas a la BBQ':'BBQ carnitas pizza',
        'Pizza de Pan Plano del Cervecero':'Brewer\'s Flatbread Pizza',
        'Pasta Arrabiata':'Arrabiata Pasta',
        'Asado a la olla con cerveza':'Roast pot with beer',
        'Pastel de tempeh':'Tempeh cake',
        'Hamburguesa Bunz con mermelada de tocino ahumado':'Bunz burger with smoked bacon jam',
        'Pollo a la barbacoa con succotash':'Barbecue chicken with succotash',
        'Muffins de manzana y cerveza':'Apple and beer muffins',
        'Pastelitos de fondo de roca':'Rock bottom cupcakes',
        '500 gramos de tilapia (u otro pescado sostenible)':'500 grams of tilapia (or other sustainable fish)',
        '1 1/4 tazas de jugo de limón fresco': '1 1/4 cups of fresh lemon juice',
        '3 tomates grandes, sin corazón, sin semillas y cortados en cubitos': '3 large tomatoes, cored, seeded, and diced',
        '1/2 pepino grande, sin semillas y cortado en cubitos': '1/2 large cucumber, seeded and diced',
        '1/2 chile jalapeño, picado (use guantes al picar el jalapeño y evite tocarse los ojos)': '1/2 jalapeño pepper, chopped (wear gloves when chopping jalapeño and avoid touching your eyes)',
        '1/4 taza de cilantro, picado, y más para decorar':'1/4 cup coriander, chopped, and more for garnish',
        '1/4 taza de cebolla roja, cortada en cubitos': '1/4 cup red onion, diced',
        '1 cucharadita de salsa de tomate totalmente natural (sin jarabe de maíz)': '1 teaspoon of all-natural tomato sauce (without corn syrup)',
        '1 cucharadita de salsa tabasco o salsa tapatio':'1 teaspoon of tabasco sauce or tapatio sauce',
        '1/2 cucharadita de salsa Worcestershire':'1/2 teaspoon Worcestershire sauce',
        'Sal kosher y pimienta blanca molida':'Kosher salt and ground white pepper',
        'Chips de tortilla, para servir':'Tortilla chips, to serve',
        'Cortar la tilapia en cubos de 6 milímetros y ponerla en un tazón grande. Agregue el jugo de limón fresco, mezcle bien y deje reposar, refrigerado, durante 2 horas.':'Cut the tilapia into 6 millimeter cubes and put it in a large bowl. Add fresh lemon juice, mix well, and let stand, refrigerated, for 2 hours.',
        'Escurra el exceso de jugo de la tilapia y mézclelo con el tomate, el pepino, el jalapeño, el cilantro, la cebolla roja, el ketchup, el tabasco y el Worcestershire. Sazone con sal y pimienta blanca al gusto. Adorne con cilantro adicional, si lo desea.':'Drain excess juice from tilapia and mix with tomato, cucumber, jalapeño, coriander, red onion, ketchup, tabasco, and Worcestershire. Season with salt and white pepper to taste. Garnish with additional coriander, if desired.',
        'Sirva inmediatamente con chips de tortilla, o almacene, cubierto y refrigerado, hasta por 2 días.':'Serve immediately with tortilla chips, or store, covered and refrigerated, for up to 2 days.',
        '2 dientes de ajo':'2 cloves of garlic',
        '3/4 tazas de Cerveza Sierra Fría Session IPA':'3/4 cups Sierra Fría Session IPA Beer',
        '3 tazas de queso cheddar rallado':'3 cups of cheddar cheese grated',
        '1 taza de queso crema, en cubos':'1 cup diced cream cheese',
        '1 cucharadita de mostaza en polvo': '1 teaspoon of mustard powder',
        '1 cucharadita de pimienta negra recién molida':'1 teaspoon freshly ground black pepper',
        '1/4 cucharadita de cayena':'1/4 teaspoon of cayenne',
        '1/4 cucharadita de rábano picante cremoso':'1/4 teaspoon creamy horseradish',
        'Galletas, para servir':'Cookies, to serve',
        'Rebanadas de baguette tostadas, para servir':'Toasted baguette slices, to serve',
        'Con un procesador de alimentos en funcionamiento, deje caer el ajo a través del tubo de alimentación y continúe durante 30 segundos. Detener.':'With a food processor running, drop the garlic through the feed tube and continue for 30 seconds. Stop.',
        'Agrega los ingredientes restantes. Ejecute el procesador de alimentos hasta que todos los ingredientes estén bien mezclados.':'Add the remaining ingredients. Run the food processor until all the ingredients are well mixed.',
        'Enfríe por al menos 2 horas. Sirva con galletas saladas o rebanadas de baguette.':'Chill for at least 2 hours. Serve with crackers or baguette slices.',
        '1/2 taza de queso cheddar ahumado, rallado':'1/2 cup grated smoked cheddar cheese',
        '1/2 taza de queso crema':'1/2 cup cream cheese',
        '1/2 taza de queso de cabra':'1/2 cup of goat cheese',
        '1 cucharada de mostaza Dijon':'1 tablespoon of Dijon mustard',
        '3 dientes de ajo, machacados':'3 garlic cloves, crushed',
        '1 manojo de perejil fresco':'1 bunch of fresh parsley',
        '1 chalote grande':'1 large shallot',
        '140 gr de tocino, opcional':'140 gr of bacon, optional',
        '180 ml de Cerveza Sierra Fría Session IPA':'180 ml Sierra Fría Session IPA Beer',
        '1 a 3 cucharadas de aceite de oliva':'1 to 3 tablespoons of olive oil',
        'Sal y pimienta al gusto':'Salt and pepper to taste',
        'Rebanadas de baguette tostado, para servir':'Toasted baguette slices, to serve',
        'Buenas rebanadas de salami, para servir':'Good slices of salami, to serve',
        'Moras y arándanos frescos, para servir':'Blackberries and fresh blueberries, to serve',
        'Permita que los quesos alcancen la temperatura ambiente. Coloque los quesos en un procesador de alimentos junto con mostaza, ajo, perejil, chalota y tocino, si los usa. Haga puré la mezcla durante 1 a 2 minutos, hasta que se combine.':'Allow the cheeses to reach room temperature. Place the cheeses in a food processor along with mustard, garlic, parsley, shallot, and bacon, if you use them. Puree the mixture for 1 to 2 minutes, until combined.',
        'Agregue la cerveza y el aceite de oliva y mezcle hasta que la mezcla esté suave. Sazone con sal y pimienta al gusto.':'Add beer and olive oil and mix until mixture is smooth. Season with salt and pepper to taste.',
        'Servir inmediatamente con rebanadas de baguette, salami o bayas.':'Serve immediately with slices of baguette, salami, or berries.',
        'Nota: si no sirve de inmediato, envuelva bien y refrigere. Dejar calentar a temperatura ambiente antes de servir.':'Note: if it doesn\'t work right away, wrap well and refrigerate. Let warm to room temperature before serving.',
        '2 cucharaditas de mantequilla':'2 teaspoons of butter',
        '1 1/2 tazas de cebolla picada':'1 1/2 cups chopped onion',
        '1 1/2 tazas de apio picado':'1 1/2 cups chopped celery',
        '1 1/2 cucharadas de ajo picado':'1 1/2 tablespoons minced garlic',
        '1 a 1.4 kilos de coliflor, cortada en trozos pequeños':'1 to 1.4 kilos of cauliflower, cut into small pieces',
        '650 ml de Cerveza Sierra Fría Session IPA':'650 ml Sierra Fría Session IPA Beer',
        '2 tazas de caldo de pollo o vegetales':'2 cups chicken or vegetable broth',
        '1 taza de crema batida':'1 cup whipped cream',
        '2 tazas de queso gruyere, rallado (uso dividido)':'2 cups gruyere cheese, grated (divided use)',
        'Pimienta de cayena, al gusto':'Cayenne pepper, to taste',
        'Sal y pimiento, al gusto':'Salt and pepper, to taste',
        '1/2 baguette, en rodajas':'1/2 baguette, sliced',
        'Mantequilla, para baguettes':'Butter, for baguettes',
        'En una olla grande a fuego medio, derrita la mantequilla. Agregue las cebollas, el apio, el ajo y la coliflor. Saltee hasta que las cebollas estén translúcidas y la coliflor esté tierna.':'In a large saucepan over medium heat, melt the butter. Add onions, celery, garlic, and cauliflower. Sauté until the onions are translucent and the cauliflower is tender.',
        'Agregue la cerveza y el caldo, hierva y reduzca el fuego. Cubra y cocine a fuego lento durante 15 a 20 minutos.':'Add beer and stock, bring to a boil and reduce heat. Cover and simmer for 15 to 20 minutes.',
        'Agregue la crema y cocine a fuego lento durante 15 minutos. Retirar del fuego y enfriar un poco.':'Add cream and simmer for 15 minutes. Remove from the heat and cool slightly.',
        'Haga puré la sopa con una licuadora de inmersión o una licuadora de sobremesa suave. Lleva la sopa a fuego lento y bate 1 1/2 tazas de queso. Agregue la pimienta de cayena, sal y pimienta y, si lo desea, más ajo, al gusto.':'Puree the soup with an immersion blender or a smooth bench-top blender. Bring the soup to a simmer and beat 1 1/2 cups of the cheese. Add the cayenne pepper, salt and pepper and, if desired, more garlic, to taste.',
        'Precaliente el horno para asar: coloque las rebanadas de baguette en una bandeja para hornear, mantequilla ligeramente, espolvoree con el queso restante y ase hasta que estén doradas y burbujeantes. Sirva la sopa en tazones y haga flotar la tostada con queso encima.':'Preheat oven for roasting: Place baguette slices on baking sheet, lightly butter, sprinkle with remaining cheese and grill until golden and bubbly. Serve the soup in bowls and float the toast with cheese on top.',
        '2 chiles habaneros grandes, cortados en cubitos (use guantes al cortarlos en cubitos y evite tocarse los ojos)':'2 large habanero peppers, diced (wear gloves when dicing and avoid touching your eyes)',
        '355 ml de Cerveza Sierra Fría Session IPA':'355 ml Sierra Fría Session IPA Beer',
        '1 cebolla morada mediana, cortada en cubitos':'1 medium red onion, diced',
        '2 cebollas amarillas medianas, cortadas en cubitos':'2 medium yellow onions, diced',
        '3 aguacates, cortados en cubitos (no demasiado maduros)':'3 avocados, diced (not overripe)',
        '1 mango grande, cortado en cubitos':'1 large mango, diced',
        '6 tomates cortados en cubitos':'6 diced tomatoes',
        '6 cebolletas pequeñas, finamente cortadas en cubitos':'6 small chives, finely diced',
        '5 dientes de ajo finamente picados':'5 garlic cloves, finely chopped',
        '1 manojo de cilantro, finamente picado':'1 bunch coriander, finely chopped',
        '1 kilogramo de camarones de 30 a 50 unidades, cocidos, pelados, desvenados y picados':'1 kilogram of shrimp from 30 to 50 units, cooked, peeled, deveined and minced',
        '6 limas, en jugo':'6 limes, in juice',
        '1 cucharada de salsa de chile tailandés Sriracha':'1 tablespoon Sriracha Thai chili sauce',
        '2 chiles jalapeños grandes, sin semillas y finamente picados (use guantes al picarlos y evite tocarse los ojos)':'2 large jalapeño peppers, seeded and finely chopped (wear gloves when chopping and avoid touching your eyes)',
        '1 1/2 cucharadas de sal':'1 1/2 tablespoons of salt',
        '2 cucharadas de pimienta negra recién molida':'2 tablespoons freshly ground black pepper',
        'En un tazón pequeño, combine los habaneros y la cerveza. Déjalo reposar durante 1 hora':'In a small bowl, combine the habaneros and the beer. Let it sit for 1 hour',
        'En un tazón grande, combine todos los demás ingredientes y cubra con una envoltura de plástico hasta que la cerveza con infusión de habanero esté lista.':'In a large bowl, combine all other ingredients and cover with plastic wrap until habanero-infused beer is ready.',
        'Dependiendo de la cantidad de picante que desee, puede colar las semillas de habanero de la cerveza antes de verterlas en la mezcla o mantenerlas todas adentro.':'Depending on the amount of spiciness you want, you can strain the habanero seeds from the beer before pouring them into the mix or keeping them all inside.',
        'Agregue la cerveza a los otros ingredientes, mezcle suavemente para evitar que los ingredientes se vuelvan blandos. Cubra con una envoltura de plástico y deje reposar por un mínimo de 1 hora. Para mejores resultados, refrigere durante la noche.':'Add beer to the other ingredients, mix gently to prevent the ingredients from becoming soft. Cover with plastic wrap and let sit for a minimum of 1 hour. For best results, refrigerate overnight.',
        'Para la salsa BBQ: 1 cucharada de aceite de canola':'For the BBQ sauce: 1 tablespoon of canola oil',
        'Para la salsa BBQ: 2/3 taza de cebolla amarilla picada':'For the BBQ sauce: 2/3 cup chopped yellow onion',
        'Para la salsa BBQ: 1/3 taza de ajo, picado':'For the BBQ sauce: 1/3 cup garlic, minced',
        'Para la salsa BBQ: 2 cucharaditas de semillas de cilantro, tostadas y molidas':'For the BBQ sauce: 2 teaspoons coriander seeds, toasted and ground',
        'Para la salsa BBQ: 1 cucharadita de pimentón ahumado':'For the BBQ sauce: 1 teaspoon of smoked paprika',
        'Para la salsa BBQ: 1 cucharadita de pimienta de cayena':'For the BBQ sauce: 1 teaspoon of cayenne pepper',
        'Para la salsa BBQ: 2 3/4 tazas de Cerveza Sierra Fría Porter':'For the BBQ sauce: 2 3/4 cups of Sierra Fría Porter Beer',
        'Para la salsa BBQ: 1 chile ancho, rehidratado con 1 taza de agua caliente':'For the BBQ sauce: 1 ancho chili, rehydrated with 1 cup of hot water',
        'Para la salsa BBQ: 1 lima, rallada':'For the BBQ sauce: 1 lime, grated',
        'Para la salsa BBQ: 4 1/3 tazas de salsa de tomate':'For the BBQ sauce: 4 1/3 cups of tomato sauce',
        'Para la salsa BBQ: 1/2 manojo de cilantro':'For the BBQ sauce: 1/2 bunch of coriander',
        'Para la salsa BBQ: 2/3 taza de melaza':'For the BBQ sauce: 2/3 cup of molasses',
        'Para la salsa BBQ: 1/2 taza de vinagre de vino de arroz':'For the BBQ sauce: 1/2 cup rice wine vinegar',
        'Para la salsa BBQ: 1/4 taza de azúcar morena':'For the BBQ sauce: 1/4 cup brown sugar',
        'Para la salsa BBQ: 1 cucharadita de sal kosher':'For the BBQ sauce: 1 teaspoon of kosher salt',
        'Para los aros de cebolla: 2 1/2 tazas de harina para todo uso, uso dividido':'For the onion rings: 2 1/2 cups all-purpose flour, divided use',
        'Para los aros de cebolla: 2 cucharaditas de perejil seco':'For the onion rings: 2 teaspoons of dried parsley',
        'Para los aros de cebolla: 2 cucharaditas de ajo en polvo':'For the onion rings: 2 teaspoons of garlic powder',
        'Para los aros de cebolla: 1 cucharadita de comino':'For the onion rings: 1 teaspoon of cumin',
        'Para los aros de cebolla: 1 cucharadita de chile en polvo':'For the onion rings: 1 teaspoon of chili powder',
        'Para los aros de cebolla: 1 cucharadita de chile chipotle seco, molido':'For the onion rings: 1 teaspoon dry chipotle chile, ground',
        'Para los aros de cebolla: 2 cucharaditas de pimentón ahumado':'For the onion rings: 2 teaspoons of smoked paprika',
        'Para los aros de cebolla: 1 cucharada de sal kosher':'For the onion rings: 1 tablespoon of kosher salt',
        'Para los aros de cebolla: 1 huevo batido':'For the onion rings: 1 beaten egg',
        'Para los aros de cebolla: 355 ml de Cerveza Sierra Fría Porter':'For the onion rings: 355 ml of Sierra Fría Porter Beer',
        'Para los aros de cebolla: 2 a 3 cebollas amarillas grandes, cortadas en aros gruesos':'For the onion rings: 2 to 3 large yellow onions, cut into thick rings',
        'Para los aros de cebolla: 2 tazas de aceite, para freír':'For the onion rings: 2 cups of oil, for frying',
        'Para el pollo: 4 grandes muslos de pollo deshuesados':'For the chicken: 4 large boneless chicken thighs',
        'Para el pollo: Sal y pimienta negra recién molida':'For the chicken: Salt and freshly ground black pepper',
        'Para el pollo: 4 onzas de rebanadas afiladas de queso cheddar':'For the chicken: 4 ounces of sharp slices of cheddar cheese',
        'Para el pollo: 2 chiles poblanos, asados y sin semillas':'For the chicken: 2 poblano peppers, roasted and seedless',
        'Para el pollo: 8 rebanadas de tocino cocido':'For the chicken: 8 slices of cooked bacon',
        'Para el pollo: 4 hojas de lechuga romana':'For the chicken: 4 romaine lettuce leaves',
        'Para el pollo: 8 rodajas de tomate':'For the chicken: 8 tomato slices',
        'Para el pollo: 4 bollos de hamburguesa de trigo integral':'For the chicken: 4 whole wheat burger buns',
        'Para el pollo: 4 brochetas grandes':'For the chicken: 4 large skewers',
        'Haga la salsa BBQ: En una cacerola mediana a fuego alto, caliente el aceite y saltee la cebolla durante unos 3 minutos. Baje el fuego y agregue el ajo. Cocine la cebolla y el ajo por otros 3 minutos y luego agregue las especias secas. Agregue los ingredientes restantes, hierva, baje el fuego y cocine a fuego lento durante aproximadamente 2 horas. Retirar del fuego y dejar de lado.':'Make the BBQ sauce: In a medium saucepan over high heat, heat the oil and sauté the onion for about 3 minutes. Lower the heat and add the garlic. Cook the onion and garlic for another 3 minutes, and then add the dried spices. Add remaining ingredients, bring to a boil, lower heat and simmer for about 2 hours. Remove from the heat and set aside.',
        'Prepare los aros de cebolla: en un tazón poco profundo combine 2 tazas de harina con perejil seco, ajo en polvo, comino, chile en polvo, chipotle, pimentón y sal. En un recipiente aparte, mezcle el huevo batido y la cerveza. Poco a poco revuelva la mezcla de cerveza en la mezcla de harina, revolviendo hasta que se forme una masa espesa. (Agregue más cerveza si la masa es demasiado espesa).':'Prepare the onion rings: in a shallow bowl combine 2 cups of flour with dried parsley, garlic powder, cumin, chili powder, chipotle, paprika, and salt. In a separate bowl, mix the beaten egg and beer. Gradually stir the beer mixture into the flour mixture, stirring until a thick dough forms. (Add more beer if the dough is too thick.)',
        'Asa el pollo: agrega sal y pimienta de manera uniforme en cada muslo y cocina a la parrilla. Derrita 28 gramos de queso en cada muslo antes de sacarlo de la parrilla.':'Grill the chicken: add salt and pepper evenly to each thigh and grill. Melt 28 grams of cheese on each thigh before removing it from the grill.',
        'En una sartén pesada o sartén de hierro fundido, caliente el aceite a fuego medio-alto. (Ajuste la cantidad, dependiendo del tamaño de su sartén, de modo que tenga 5 centímetros de aceite). Coloque las cebollas en un recipiente con agua, luego métalas en un recipiente con la 1/2 taza de harina. Cuando el aceite esté caliente (176 a 190 grados), sumerja las cebollas en la masa, sacuda el exceso y fríe, volteando una vez para que se dore uniformemente en ambos lados. Escurrir sobre toallas de papel.':'In a heavy skillet or cast iron skillet, heat oil over medium-high heat. (Adjust the amount, depending on the size of your pan, so that it has 5 centimeters of oil). Put the onions in a bowl of water, then put them in a bowl with the 1/2 cup of flour. When the oil is hot (176 to 190 degrees), dip the onions in the batter, shake off the excess and fry, turning once so that it is evenly browned on both sides. Drain on paper towels.',
        'Ensamble los sándwiches: tueste los bollos ligeramente y esparza un poco de salsa BBQ en la parte inferior y superior de cada bollo. Coloque un muslo con queso en el fondo de cada bollo. Coloque unas rodajas de chile pimiento y 2 rodajas de tocino en cada snadwich, y cubra con un trozo de lechuga y 2 rodajas de tomate. Coloque un anillo de cebolla bien dorado en la parte superior y rocíe con un poco más de salsa BBQ. Cubra cada sándwich con pan y use una brocheta para mantener juntos a estos gigantes.':'Assemble the sandwiches: toast the buns lightly and sprinkle a little BBQ sauce on the bottom and top of each bun. Place a cheese thigh on the bottom of each bun. Place a few slices of bell pepper and 2 slices of bacon in each snadwich, and top with a slice of lettuce and 2 slices of tomato. Place a well-browned onion ring on top and drizzle with a little more BBQ sauce. Top each sandwich with bread and use a skewer to keep these giants together.',
        'Para el cerdo: 1 kilogramo de paleta de cerdo deshuesada':'For the pork: 1 kilogram boneless pork shoulder',
        'Para el cerdo: 1 cucharada de orégano seco':'For the pork: 1 tablespoon of dried oregano',
        'Para el cerdo: 1/2 cucharada de comino molido':'For the pork: 1/2 tablespoon of ground cumin',
        'Para el cerdo: 8 cucharadas de aceite vegetal, uso dividido':'For the pork: 8 tablespoons vegetable oil, divided use',
        'Para el cerdo: 2 pimientos chipotles enlatados en adobo, picados en trozos grandes':'For the pork: 2 canned chipotle peppers in marinade, chopped into large pieces',
        'Para el cerdo: 6 dientes de ajo picados':'For the pork: 6 minced garlic cloves',
        'Para el cerdo: 1 1/2 cucharadas de sal':'For the pork: 1 1/2 tablespoons of salt',
        'Para el cerdo: 1 1/2 cucharadas de pimienta negra recién molida':'For the pork: 1 1/2 tablespoons freshly ground black pepper',
        'Para el cerdo: 1 cebolla picada gruesa':'For the pork: 1 thick chopped onion',
        'Para el cerdo: 1 jalapeño mediano, picado en trozos gruesos (use guantes cuando corte pimientos y evite tocarse los ojos)':'For the pork: 1 medium jalapeño, coarsely chopped (wear gloves when cutting bell peppers and avoid touching your eyes)',
        'Para el cerdo: 1 naranja, en jugo':'For the pork: 1 orange, in juice',
        'Para el cerdo: 1 1/2 tazas de Cerveza Sierra Fría Porter':'For the pork: 1 1/2 cups of Sierra Fría Porter Beer',
        'Para la masa: 1/2 sobre de levadura seca':'For the dough: 1/2 on dry yeast',
        'Para la masa: 3/4 tazas de Cerveza Sierra Fría Porter (1/4 taza de temperatura ambiente, 1/2 taza enfriada)':'For the dough: 3/4 cups Sierra Fría Porter Beer (1/4 cup room temperature, 1/2 cup chilled)',
        'Para la masa: 1 taza de harina de pan, más harina adicional para estirar la masa':'For the dough: 1 cup of bread flour, plus additional flour to roll out the dough',
        'Para la masa: 1 taza de harina para todo uso':'For the dough: 1 cup all-purpose flour',
        'Para la masa: 1 cucharada de azúcar granulada':'For the dough: 1 tablespoon of granulated sugar',
        'Para la masa: 1 1/2 cucharaditas de sal kosher':'For the dough: 1 1/2 teaspoons of kosher salt',
        'Para la masa: 2 cucharadas de aceite de oliva, más extra para engrasar el tazón':'For the dough: 2 tablespoons of olive oil, plus extra to grease the bowl',
        'Para la salsa BBQ: 3 cucharadas de aceite vegetal':'For the BBQ sauce: 3 tablespoons of vegetable oil',
        'Para la salsa BBQ: 1/2 taza de cebolla, cortada en cubos pequeños':'For the BBQ sauce: 1/2 cup onion, cut into small cubes',
        'Para la salsa BBQ: 1/4 taza de pimientos verdes, cortados en cubos pequeños':'For the BBQ sauce: 1/4 cup green bell peppers, cut into small cubes',
        'Para la salsa BBQ: 1 cucharada de ajo picado':'For the BBQ sauce: 1 tablespoon minced garlic',
        'Para la salsa BBQ: 3 tazas de salsa de tomate (preferiblemente Heinz)':'For the BBQ sauce: 3 cups of tomato sauce (preferably Heinz)',
        'Para la salsa BBQ: 1/2 taza de mostaza amarilla':'For the BBQ sauce: 1/2 cup yellow mustard',
        'Para la salsa BBQ: 3/4 taza de azúcar morena':'For the BBQ sauce: 3/4 cup brown sugar',
        'Para la salsa BBQ: 1/2 taza de miel de abeja':'For the BBQ sauce: 1/2 cup of honey',
        'Para la salsa BBQ: 1/4 taza de vinagre de sidra':'For the BBQ sauce: 1/4 cup of cider vinegar',
        'Para la salsa BBQ: 2 cucharadas de salsa Worcestershire':'For the BBQ sauce: 2 tablespoons of Worcestershire sauce',
        'Para la salsa BBQ: 1 cucharada de chile en polvo':'For the BBQ sauce: 1 tablespoon of chili powder',
        'Para la salsa BBQ: 1 cucharada de pimentón ahumado':'For the BBQ sauce: 1 tablespoon of smoked paprika',
        'Para la infusión de chile-cerveza: 142 gramos de chiles anchos secos, tallos y semillas eliminados':'For the chili-beer infusion: 142 grams of dried ancho peppers, stems and seeds removed',
        'Para la infusión de chile-cerveza: 2 tazas de Cerveza Sierra Fría Porter':'For the chili-beer infusion: 2 cups of Sierra Fría Porter Beer',
        'Para la cobertura (en cada pizza): 85 gramos de queso mozzarella rallado de baja humedad':'For the topping (on each pizza): 85 grams of low-moisture grated mozzarella cheese',
        'Para la cobertura (en cada pizza): 1 chile jalapeño mediano, cortado en rodajas finas (use guantes cuando corte pimientos y evite tocarse los ojos)':'For the topping (on each pizza): 1 medium jalapeño pepper, cut into thin slices (wear gloves when cutting bell peppers and avoid touching your eyes)',
        'Para la cobertura (en cada pizza): 1 chile jalapeño rojo mediano, cortado en rodajas finas (use guantes cuando corte pimientos y evite tocarse los ojos)':'For the topping (on each pizza): 1 medium red jalapeño pepper, cut into thin slices (wear gloves when cutting bell peppers and avoid touching your eyes)',
        'Para la cobertura (en cada pizza): 28 gramos de cebolla roja, en rodajas finas':'For the topping (on each pizza): 28 grams of red onion, thinly sliced',
        'Para la cobertura (en cada pizza): 42.5 gramos de queso de cabra desmenuzado':'For the topping (on each pizza): 42.5 grams of crumbled goat cheese',
        'Para la cobertura (en cada pizza): 1 cucharada de cilantro fresco, picado en trozos grandes':'For the topping (on each pizza): 1 tablespoon fresh coriander, chopped into large pieces',
        'Prepare la carne de cerdo: Precaliente el horno a 163 grados. Enjuague y seque con toallas de papel. Combine orégano, comino, 2 cucharadas de aceite vegetal, chile chipotle, ajo, sal y pimienta, y frótelo sobre la carne de cerdo.':'Prepare the pork: Preheat the oven to 163 degrees. Rinse and pat dry with paper towels. Combine oregano, cumin, 2 tablespoons of vegetable oil, chipotle chili, garlic, salt, and pepper, and rub it over the pork.',
        'Coloque la carne de cerdo en una fuente de horno profunda y cubra con las cebollas picadas y los jalapeños. Agregue el jugo de naranja y la cerveza, y cubra bien con papel de aluminio. Hornee durante 2 a 3 horas hasta que la carne esté tierna.':'Place the pork in a deep baking dish and top with the chopped onions and jalapeños. Add the orange juice and beer, and cover well with aluminum foil. Bake 2 to 3 hours until meat is tender.',
        'Haga la masa: espolvoree la levadura sobre la cerveza a temperatura ambiente y revuelva para disolver. Dejar reposar unos 15 minutos en un lugar cálido.':'Make the dough: sprinkle the yeast over the beer at room temperature and stir to dissolve. Let stand about 15 minutes in a warm place.',
        'En el tazón de una batidora, combine la harina, el azúcar, la sal y el aceite, y mezcle a baja velocidad durante 30 segundos.':'In the mixer bowl, combine the flour, sugar, salt and oil, and mix on low speed for 30 seconds.',
        'Agregue la mezcla de levadura y la cerveza fría, y mezcle a baja velocidad hasta que la masa se una. Aumente la velocidad ligeramente y amase la masa hasta que esté suave y comience a separarse de los lados del tazón (aproximadamente 8 minutos).':'Add the yeast mixture and cold beer, and mix on low speed until the dough comes together. Increase speed slightly and knead the dough until smooth and begins to separate from the sides of the bowl (about 8 minutes).',
        'Coloque la masa sobre una superficie enharinada y amase a mano durante otros 1 a 2 minutos. Use harina adicional para espolvorear para evitar que se pegue. Forme la masa en una bola grande y colóquela en un recipiente ligeramente engrasado cubierto holgadamente con una envoltura de plástico. Deje que aumente a aproximadamente el doble de tamaño (aproximadamente 1 hora).':'Place the dough on a floured surface and knead by hand for another 1 to 2 minutes. Use additional flour to sprinkle to prevent sticking. Roll the dough into a large ball and place it in a lightly greased container loosely covered with plastic wrap. Let it increase to about twice the size (about 1 hour).',
        'Prepare la salsa BBQ: caliente el aceite en una sartén grande y saltee las cebollas, los pimientos y el ajo a fuego medio hasta que estén transparentes. Agregue todos los ingredientes restantes, revolviendo bien, y hierva la salsa. Reduzca el fuego y cocine a fuego lento durante unos 15 minutos.':'Prepare the BBQ sauce: heat the oil in a large skillet and sauté the onions, peppers and garlic over medium heat until they are transparent. Add all remaining ingredients, stirring well, and bring sauce to a boil. Reduce heat and simmer for about 15 minutes.',
        'Retire la salsa del fuego y enfríe. Luego mezcle en un procesador de alimentos hasta que quede suave. (Ajuste el grosor de la salsa con agua. Debe ser como una crema espesa). Ajuste el condimento con sal y pimienta y refrigere.':'Remove the sauce from the heat and cool. Then mix in a food processor until smooth. (Adjust the thickness of the sauce with water. It should be like a thick cream.) Adjust the seasoning with salt and pepper and refrigerate.',
        'Haga la infusión de cerveza de chile: coloque los chiles anchos en una cacerola pequeña con la cerveza y cocine a fuego lento hasta que los chiles estén suaves y la cerveza se reduzca a la mitad. Sáquelo del fuego y apártelo.':'Make the chili beer infusion: Place the ancho chiles in a small saucepan with the beer and simmer until the chiles are soft and the beer is halved. Remove from the heat and set aside.',
        'Divida la masa: después de que la masa haya subido, divídala en 4 partes iguales y amase suavemente cada pieza. Forme una bola, colóquela en una bandeja para hornear ligeramente engrasada y cubra con una envoltura de plástico. Deje reposar la masa durante unos 30 minutos.':'Divide the dough: After the dough has risen, divide it into 4 equal parts and gently knead each piece. Form into a ball, place on lightly greased baking sheet, and cover with plastic wrap. Let the dough rest for about 30 minutes.',
        'Extienda cada bola de masa con un rodillo sobre una superficie ligeramente enharinada en un círculo de 25 a 30 centímetros. Espolvorea ligeramente la masa enrollada con harina, cubre ligeramente con una toalla limpia y repite con las bolas restantes.':'Roll out each ball of dough on a lightly floured surface in a 25 to 30 centimeter circle. Lightly sprinkle the rolled dough with flour, lightly cover with a clean towel and repeat with the remaining balls.',
        'Termine el cerdo: cuando el cerdo esté listo, retírelo del molde para hornear y déjelo enfriar. Separarlo en trozos pequeños. Calienta el aceite restante en una sartén grande y fríe los trozos de carne de cerdo hasta que estén dorados y crujientes. Retire las piezas doradas del aceite y escúrralas en toallas de papel.':'Finish the pork: When the pork is done, remove it from the baking pan and let cool. Separate it into small pieces. Heat the remaining oil in a large skillet and fry the pieces of pork until golden and crisp. Remove the golden pieces from the oil and drain them on paper towels.',
        'Precaliente una piedra de pizza en un horno de 232 grados.':'Preheat a pizza stone in a 232-degree oven.',
        'Ensamble las pizzas: combine la infusión de chile ancho y cerveza con la salsa BBQ y extienda un poco para cubrir cada pizza, dejando un borde de aproximadamente 1,5 centímetros. A continuación, espolvoree uniformemente la mozzarella y cubra con aproximadamente 1/2 taza de carne de cerdo, jalapeños, cebolla y queso de cabra. Coloque la pizza con cuidado sobre la piedra para pizza y hornee hasta que los bordes se doren, aproximadamente 10 minutos. Retirar del horno, espolvorear con cilantro y cortar en 8 rebanadas iguales.':'Assemble the pizzas: combine the ancho chili and beer infusion with the BBQ sauce and spread a little to cover each pizza, leaving a border of approximately 1.5 centimeters. Next, sprinkle the mozzarella evenly and top with about 1/2 cup pork, jalapeños, onion, and goat cheese. Gently lay the pizza on the pizza stone and bake until the edges are browned, about 10 minutes. Remove from the oven, sprinkle with coriander and cut into 8 equal slices.',
        'Para la masa de pizza: 2 tazas de harina de pan':'For the pizza dough: 2 cups of bread flour',
        'Para la masa de pizza: 2 1/2 cucharadas de aceite de oliva':'For the pizza dough: 2 1/2 tablespoons of olive oil',
        'Para la masa de pizza: 2/3 tazas de Cerveza Sierra Fría Session IPA':'For the pizza dough: 2/3 cups of Sierra Fría Beer IPA Session',
        'Para la masa de pizza: 1 pizca o más de sal':'For the pizza dough: 1 pinch or more of salt',
        'Para la cobertura: 8 a 12 tomates Campari pequeños a medianos o similares, en rodajas finas':'For the topping: 8 to 12 small to medium Campari tomatoes or similar, thinly sliced',
        'Para la cobertura: 1/2 taza de vinagre balsámico':'For the topping: 1/2 cup balsamic vinegar',
        'Para la cobertura: 10 cucharadas de aceite de oliva':'For the topping: 10 tablespoons of olive oil',
        'Para la cobertura: Sal y pimienta al gusto':'For the topping: salt and pepper to taste',
        'Para la cobertura: 6 dientes de ajo picados':'For the topping: 6 minced garlic cloves',
        'Para la cobertura: 4 a 6 tazas de queso mozzarella, rallado':'For the topping: 4 to 6 cups of mozzarella cheese, grated',
        'Para la cobertura: 170 gramos de queso de cabra desmenuzado':'For the topping: 170 grams of crumbled goat cheese',
        'Para la cobertura: 1/4 taza de miel':'For the topping: 1/4 cup of honey',
        'Prepare la masa: en un tazón grande, combine los ingredientes para la masa.':'Prepare the dough: In a large bowl, combine the ingredients for the dough.',
        'Mezcle hasta que pueda comenzar a amasar la masa. Amasar hasta que la masa no se rompa fácilmente. Cubra y deje reposar la masa durante 30 minutos.':'Mix until you can start kneading the dough. Knead until the dough does not break easily. Cover and let the dough rest for 30 minutes.',
        'Precaliente el horno a 232 grados.':'Preheat oven to 232 degrees.',
        'Prepare los tomates para la cobertura: coloque los tomates en un tazón y marina en el vinagre balsámico. Dejar de lado.':'Prepare the tomatoes for topping: Place the tomatoes in a bowl and marinade in the balsamic vinegar. Set aside.',
        'Una vez que la masa haya descansado, córtela en cuartos. Forme cada pieza en una bola y extienda cada una, usando un poco más de harina si es necesario, hasta aproximadamente 3 milímetros de grosor.':'Once the dough has rested, cut it into quarters. Form each piece into a ball and spread each, using a little more flour if necessary, up to approximately 3 millimeters thick.',
        'Ponga las rondas de masa en bandejas para hornear engrasadas con 2 pizzas por hoja.':'Put the dough rounds on greased baking sheets with 2 pizzas per sheet.',
        'Divida los ingredientes en cuatro porciones cada uno. Cubra cada pizza con aceite, sal, pimienta y ajo picado. Agregue la mozzarella y las rodajas de tomate marinado. Agregue el queso de cabra desmenuzado, luego rocíe la miel generosamente sobre cada pizza.':'Divide the ingredients into four servings each. Coat each pizza with oil, salt, pepper, and minced garlic. Add the mozzarella and the marinated tomato slices. Add the crumbled goat cheese, then drizzle honey generously on each pizza.',
        'Hornee hasta que la corteza esté dorada y crujiente, aproximadamente de 10 a 15 minutos. Dejar enfriar unos minutos antes de servir.':'Bake until the crust is golden and crisp, about 10 to 15 minutes. Let cool a few minutes before serving.',
        '1 cucharadita de aceite de oliva':'1 teaspoon of olive oil',
        '1 taza de cebolla picada':'1 cup chopped onion',
        '1 chalote grande, picado':'1 large shallot, chopped',
        '5 dientes de ajo picados':'5 garlic cloves, minced',
        '1/2 taza de Cerveza Sierra Fría Session IPA':'1/2 cup Sierra Fría Beer Session IPA',
        '2 cucharadas de miel':'2 tablespoons of honey',
        '1/2 taza de pasta de tomate':'1/2 cup tomato paste',
        'Pizca de pimienta negra recién molida':'Pinch of freshly ground black pepper',
        '1 1/2 cucharaditas de pimiento rojo picado':'1 1/2 teaspoons minced red pepper',
        '1 kilogramo de tomates roma, en puré':'1 kilogram of roma tomatoes, pureed',
        '454 gramos de linguini (o espagueti)':'454 grams of linguine (or spaghetti)',
        '4 cucharadas de mantequilla':'4 tablespoons of butter',
        '4 cucharadas de perejil fresco, picado':'4 tablespoons chopped fresh parsley',
        'Queso parmesano recién rallado':'Freshly grated Parmesan cheese',
        'Pon a hervir una olla de agua (suficiente para cocinar los 454 gramos de pasta)':'Bring a pot of water to a boil (enough to cook the 454 grams of pasta)',
        'En una sartén grande o en una sartén, caliente el aceite hasta que esté caliente y saltee la cebolla, la chalota y el ajo hasta que estén transparentes, aproximadamente 6 minutos.':'In a large skillet or skillet, heat oil until hot, and sauté onion, shallot, and garlic until transparent, about 6 minutes.',
        'Agregue la cerveza, miel, pasta de tomate, pimienta negra y hojuelas de pimiento rojo. Combina bien':'Add beer, honey, tomato paste, black pepper, and red pepper flakes. Combine well',
        'Mezcle los tomates en puré, hierva y reduzca el fuego a medio. Cocine a fuego lento durante 20 minutos, descubierto.':'Mix the puréed tomatoes, bring to a boil and reduce the heat to medium. Simmer for 20 minutes, uncovered.',
        'Cocine linguini o espagueti hasta que esté al dente. Escurrir y agregar a la sartén. Cocine por 1 minuto con salsa.':'Cook linguine or spaghetti until al dente. Drain and add to the pan. Cook for 1 minute with sauce.',
        'Justo antes de servir, revuelva la mantequilla para derretir, agregue sal al gusto y espolvoree con perejil y queso parmesano recién rallado.':'Just before serving, stir butter to melt, add salt to taste and sprinkle with parsley and freshly grated Parmesan cheese.',
        'Para el asado a la olla: 4 cucharadas de aceite de canola o aceite de oliva':'For the roast pot: 4 tablespoons of canola oil or olive oil',
        'Para el asado a la olla: 1.36 kilogramos de carne de res asada':'For the roast pot: 1.36 kilograms of roast beef',
        'Para el asado a la olla: Sal y pimienta al gusto':'For the roast pot: Salt and pepper to taste',
        'Para el asado a la olla: 2 cebollas blancas, cortadas en cubitos':'For the roast pot: 2 white onions, diced',
        'Para el asado a la olla: 1 manojo de apio picado':'For the roast pot: 1 bunch of chopped celery',
        'Para el asado a la olla: 2 racimos de zanahorias, picadas, guarda la cáscara':'For the roast pot: 2 bunches of carrots, chopped, save the peel',
        'Para el asado a la olla: 6 dientes de ajo, machacados':'For the roast pot: 6 garlic cloves, crushed',
        'Para el asado a la olla: 1 taza de chalotes, cortados en cubitos':'For the roast pot: 1 cup shallots, diced',
        'Para el asado a la olla: 4 tazas de caldo de res':'For the roast pot: 4 cups of beef broth',
        'Para el asado a la olla: 650 mililitros de Cerveza Sierra Fría Porter':'For the roast pot: 650 milliliters of Sierra Fría Porter Beer',
        'Para el asado a la olla: 1.36 kilogramos de papas rojas, en cuartos':'For the roast pot: 1.36 kilograms of red potatoes, quartered',
        'Para el asado a la olla: 3 hojas de laurel':'For the roast pot: 3 bay leaves',
        'Últimos retoques: Cáscaras de zanahoria frescas, picadas':'Last touches: Fresh carrot peels, chopped',
        'Últimos retoques: Cebolletas frescas, picadas':'Last touches: Fresh chives, chopped',
        'Últimos retoques: Perejil fresco, picado':'Last touches: fresh parsley, chopped',
        'Últimos retoques: Pan francés a la parrilla':'Last touches: Grilled French bread',
        'Precaliente el horno a 177 grados. Calienta el aceite hasta que salga humo del sartén. Sazone generosamente la carne con sal y pimienta, y dore por todos lados. (La sartén debe estar humeante) Cocine durante 3 a 5 minutos, hasta que la carne esté dorada por un lado, y luego gírela y dore el otro lado.':'Preheat oven to 177 degrees. Heat the oil until smoke comes out of the pan. Season meat liberally with salt and pepper, and brown on all sides. (Skillet should be steaming) Cook for 3 to 5 minutes, until meat is browned on one side, then rotate and brown on the other side.',
        'Después de dorar la carne, agregue la cebolla, apio, zanahoria, ajo y chalotes. Saltee durante 5 minutos y agregue el caldo, la cerveza, las papas y las hojas de laurel. Cubra herméticamente con papel aluminio y cocine durante 3 horas, volteando el asado después de 1 hora y media.':'After browning the meat, add the onion, celery, carrot, garlic, and shallots. Sauté for 5 minutes and add the stock, beer, potatoes, and bay leaves. Cover tightly with aluminum foil and cook for 3 hours, turning the roast after 1.5 hours.',
        'Sirva una porción de carne con papas y zanahorias, cubra con salsa y adorne con cáscaras de zanahoria, cebolletas y perejil fresco. El pan francés a la parrilla también es un gran acompañamiento.':'Serve a serving of meat with potatoes and carrots, top with sauce and garnish with carrot shells, chives, and fresh parsley. Grilled French bread is also a great accompaniment.',
        'Para el ajo asado: 1 cabeza de ajo':'For the roasted garlic: 1 head of garlic',
        'Para el ajo asado: 1 cucharada de aceite de oliva virgen extra':'For the roasted garlic: 1 tablespoon of extra virgin olive oil',
        'Para el ajo asado: Sal y pimienta negra recién molida, al gusto':'For the roasted garlic: Salt and freshly ground black pepper, to taste',
        'Para las papas: 1 kilogramo de papas doradas Yukon, cortadas en cuartos':'For the potatoes: 1 kilogram of Yukon golden potatoes, cut into quarters',
        'Para las papas: 2 cucharadas de aceite con ajo':'For the potatoes: 2 tablespoons of garlic oil',
        'Para las papas: 1/4 de taza de Cerveza Sierra Fría Session IPA':'For the potatoes: 1/4 cup Sierra Fría Beer Session IPA',
        'Para las papas: 1/4 cucharadita de levadura de cerveza':'For the potatoes: 1/4 teaspoon of brewer\'s yeast',
        'Para las papas: 1 cucharadita de perejil fresco, picado':'For the potatoes: 1 teaspoon fresh parsley, chopped',
        'Para el llenado: 1 kilogramo de tempeh':'For the filling: 1 kilogram of tempeh',
        'Para el llenado: 1 cucharada de chiles pasilla molidos':'For the filling: 1 tablespoon of ground pasilla peppers',
        'Para el llenado: 1 cucharada de chiles chipotle molidos':'For the filling: 1 tablespoon of ground chipotle peppers',
        'Para el llenado: 1 cucharada de ajo en polvo':'For the filling: 1 tablespoon of garlic powder',
        'Para el llenado: 2 cucharaditas de comino molido':'For the filling: 2 teaspoons of ground cumin',
        'Para el llenado: 2 cucharaditas de sal':'For the filling: 2 teaspoons of salt',
        'Para el llenado: 4 cucharadas de aceite de oliva':'For the filling: 4 tablespoons of olive oil',
        'Para el llenado: 1/2 taza de cebollas dulces, cortadas en cubitos':'For the filling: 1/2 cup sweet onions, diced',
        'Para el llenado: 1/2 taza de zanahoria, cortada en cubitos':'For the filling: 1/2 cup carrot, diced',
        'Para el llenado: 1/2 taza de apio, cortado en cubitos':'For the filling: 1/2 cup celery, diced',
        'Para el llenado: 1/2 calabacín, cortado en cubitos':'For the filling: 1/2 zucchini, diced',
        'Para el llenado: 3 dientes de ajo picados':'For the filling: 3 minced garlic cloves',
        'Para el llenado: 1/4 taza de vino blanco seco':'For the filling: 1/4 cup of dry white wine',
        'Para el llenado: 2 tazas de queso cheddar rallado':'For the filling: 2 cups of grated cheddar cheese',
        'Ase el ajo: Precaliente el horno a 176 grados. Corta los 6 milímetros superiores del ajo, dejando la cabeza intacta pero exponiendo los dientes individuales. Coloque el ajo, cortado hacia arriba, sobre una hoja grande de papel de aluminio y rocíe con aceite y sazone con sal y pimienta. Reúna el papel de aluminio alrededor del ajo, gire la parte superior para sellar y coloque en el horno durante 1 hora. Deje que el ajo se enfríe por completo antes de exprimir suavemente los dientes.':'Roast the garlic: Preheat the oven to 176 degrees. Cut the top 6 millimeters of the garlic, leaving the head intact but exposing the individual cloves. Place the garlic, cut side up, on a large sheet of aluminum foil and drizzle with oil and season with salt and pepper. Gather the foil around the garlic, turn the top to seal and place in the oven for 1 hour. Let the garlic cool completely before gently squeezing the cloves.',
        'Prepare las papas: En una cacerola mediana a fuego alto, cubra las papas con agua con sal fría y hierva. Baje el fuego a medio, cubra y cocine hasta que las papas estén tiernas y fácilmente perforadas con un tenedor (alrededor de 15 a 20 minutos).':'Prepare the potatoes: In a medium saucepan over high heat, cover the potatoes with cold salt water and bring to a boil. Lower the heat to medium, cover and cook until the potatoes are tender and easily pierced with a fork (about 15 to 20 minutes).',
        'Escurra las papas, colóquelas en un tazón mediano y agregue el ajo asado, la cerveza, la levadura de cerveza y el perejil. Use un machacador de papas o ricer para trabajar las papas con la consistencia deseada. (Si están demasiado rígidos, agregue más cerveza). Sazone con sal y pimienta al gusto.':'Drain the potatoes, place them in a medium bowl, and add the roasted garlic, beer, brewer\'s yeast, and parsley. Use a potato masher or ricer to work the potatoes to the desired consistency. (If they are too stiff, add more beer.) Season with salt and pepper to taste.',
        'Haga el relleno: en un tazón grande, desmorone el tempeh con las manos y agregue los polvos de chile, el ajo en polvo, el comino y la sal. Revuelva hasta que esté bien combinado.':'Make the filling: in a large bowl, crumble the tempeh with your hands and add the chili powders, garlic powder, cumin and salt. Stir until well combined.',
        'Caliente 2 cucharadas de aceite de oliva en una sartén grande o sartén de hierro fundido a fuego medio-alto hasta que el aceite comience a brillar. Agregue la cebolla, la zanahoria y el apio, y saltee hasta que comiencen a ablandarse, aproximadamente 3 minutos. Agregue el calabacín y cocine 2 minutos adicionales. Agregue el ajo y cocine por 1 minuto más.':'Heat 2 tablespoons of olive oil in a large skillet or cast iron skillet over medium-high heat until the oil begins to glow. Add onion, carrot, and celery, and sauté until soft, about 3 minutes. Add zucchini and cook 2 additional minutes. Add garlic and cook for 1 more minute.',
        'Desglasar la sartén con el vino blanco, revolviendo, raspando y disolviendo cualquier parte marrón que pueda estar pegada a la sartén. Una vez que el vino blanco se haya evaporado en su mayoría, mueva las verduras a los bordes exteriores de la sartén, haciendo un pozo en el centro. Agregue las 2 cucharadas soperas de aceite de oliva y caliente ligeramente.':'Deglaze the pan with the white wine, stirring, scraping, and dissolving any brown parts that may be stuck to the pan. Once the white wine has mostly evaporated, move the vegetables to the outer edges of the pan, making a well in the center. Add the 2 tablespoons of olive oil and heat slightly.',
        'Agregue el tempeh al pozo, revolviendo para cubrir. Revuelva todo junto, salteando hasta que el tempeh se caliente y las verduras estén tiernas, aproximadamente de 4 a 5 minutos.':'Add the tempeh to the well, stirring to cover. Stir everything together, sautéing until the tempeh is warm and the vegetables are tender, about 4 to 5 minutes.',
        'Precalentar el asador.':'Preheat the grill.',
        'Vierte la mezcla de tempeh en un molde para hornear de 23 x 33 centímetros y extiende en una capa uniforme. Cubra con una capa uniforme de puré de papas y espolvoree la parte superior con queso.':'Pour the tempeh mixture into a 23 x 33 cm baking pan and spread out in an even layer. Top with an even layer of mashed potatoes and sprinkle top with cheese.',
        'Ase a la parrilla hasta que el queso se derrita, burbujee y se dore, de 5 a 7 minutos.':'Grill until cheese is melted, bubbly, and browned, 5 to 7 minutes.',
        'Para la mermelada de cerveza y tocino: 114 gramos de tocino crudo, cortado en cubos grandes':'For the beer and bacon jam: 114 grams of raw bacon, cut into large cubes',
        'Para la mermelada de cerveza y tocino: 1 1/2 tazas de cebolla, cortada en cubos pequeños':'For the beer and bacon jam: 1 1/2 cups onion, cut into small cubes',
        'Para la mermelada de cerveza y tocino: 1/2 chile jalapeño, cortado en cubos pequeños (use guantes cuando corte el chile jalapeño en cubitos y evite tocarse los ojos)':'For the beer and bacon jam: 1/2 jalapeño pepper, diced (wear gloves when dicing the jalapeño pepper and avoid touching your eyes)',
        'Para la mermelada de cerveza y tocino: 1/4 cucharada de ajo picado':'For the beer and bacon jam: 1/4 tablespoon minced garlic',
        'Para la mermelada de cerveza y tocino: 1 cucharadita de cilantro molido':'For the beer and bacon jam: 1 teaspoon of ground coriander',
        'Para la mermelada de cerveza y tocino: 1/2 taza de azúcar granulada':'For the beer and bacon jam: 1/2 cup granulated sugar',
        'Para la mermelada de cerveza y tocino: 355 mililitros de Cerveza Sierra Fría Porter':'For the beer and bacon jam: 355 milliliters of Sierra Fría Porter Beer',
        'Para la mermelada de cerveza y tocino: 1/2 cucharadita de sal kosher':'For the beer and bacon jam: 1/2 teaspoon of kosher salt',
        'Para la mermelada de cerveza y tocino: 1/4 cucharadita de pimienta negra recién molida':'For the beer and bacon jam: 1/4 teaspoon freshly ground black pepper',
        'Para la mermelada de cerveza y tocino: 1/2 taza de mermelada de naranja':'For the beer and bacon jam: 1/2 cup orange jam',
        'Para la mermelada de cerveza y tocino: 1/2 cucharada de hojas de tomillo fresco':'For the beer and bacon jam: 1/2 tablespoon of fresh thyme leaves',
        'Para la salsa de la casa Bunz: 3/4 taza de mayonesa':'For the Bunz house sauce: 3/4 cup mayonnaise',
        'Para la salsa de la casa Bunz: 1/8 taza de mostaza integral':'For the Bunz house sauce: 1/8 cup whole mustard',
        'Para la salsa de la casa Bunz: 3 cucharadas de salsa de tomate Heinz':'For the Bunz house sauce: 3 tablespoons of Heinz tomato sauce',
        'Para la salsa de la casa Bunz: 1 1/2 cucharaditas de salsa Worcestershire':'For the Bunz house sauce: 1 1/2 teaspoons Worcestershire sauce',
        'Para la salsa de la casa Bunz: 1/4 cucharadita de ajo en polvo':'For the Bunz house sauce: 1/4 teaspoon garlic powder',
        'Para la salsa de la casa Bunz: 1/4 cucharadita de comino molido':'For the Bunz house sauce: 1/4 teaspoon ground cumin',
        'Para la salsa de la casa Bunz: 1/4 cucharadita de chile en polvo':'For the Bunz house sauce: 1/4 teaspoon chili powder',
        'Para las hamburguesas: 600 gramos de carne molida (80 por ciento magra), dividida en 4 empanadas':'For the hamburgers: 600 grams of ground meat (80 percent lean), divided into 4 patties',
        'Para las hamburguesas: Sal y pimienta':'For the hamburgers: Salt and pepper',
        'Para las hamburguesas: 2 chiles poblanos, asados, sin semillas y en rodajas':'For the hamburgers: 2 poblano peppers, roasted, seeded and sliced',
        'Para las hamburguesas: 4 hojas de lechuga romana':'For the hamburgers: 4 romaine lettuce leaves',
        'Para las hamburguesas: 8 rodajas de tomate':'For the hamburgers: 8 tomato slices',
        'Para las hamburguesas: 8 rodajas de cebolla roja, separadas en aros':'For the hamburgers: 8 slices of red onion, separated into rings',
        'Para las hamburguesas: 4 bollos de hamburguesa de trigo integral':'For the hamburgers: 4 whole wheat burger buns',
        'Prepara la mermelada: en una cacerola grande a fuego medio, fríe el tocino hasta que esté completamente cocido. Retire el tocino y escúrralo sobre toallas de papel, dejando la grasa en la sartén. En la misma sartén a fuego medio-alto, saltee la cebolla hasta que esté caramelizada, de 15 a 20 minutos. Agregue el jalapeño y el ajo, y saltee durante unos 2 minutos, hasta que estén tiernos. Agregue los ingredientes restantes (excepto el tomillo) y cocine rodando durante 10 a 15 minutos. Una vez que la mezcla se haya espesado, retírela del fuego y agregue el tomillo y los trozos de tocino cocido. Reserva.':'Prepare the jam: In a large saucepan over medium heat, fry the bacon until fully cooked. Remove the bacon and drain it on paper towels, leaving the fat in the pan. In the same skillet over medium-high heat, sauté the onion until caramelized, 15 to 20 minutes. Add the jalapeño and garlic, and sauté for about 2 minutes, until tender. Add remaining ingredients (except thyme) and cook, rolling for 10 to 15 minutes. Once the mixture has thickened, remove it from the heat and add the thyme and the pieces of cooked bacon. Reserve.',
        'Prepara la salsa Bunz House: combina bien todos los ingredientes en un tazón.':'Prepare the Bunz House sauce: combine all the ingredients well in a bowl.',
        'Precalentar una parrilla en alto.':'Preheat grill on high.',
        'Ase las hamburguesas a la parrilla: agregue sal y pimienta de manera uniforme en cada hamburguesa y ase a la temperatura deseada.':'Grill the patties: Add salt and pepper evenly to each pat and broil at the desired temperature.',
        'Ensamble las hamburguesas: tueste los bollos ligeramente en la parrilla. Agregue un poco de salsa Bunz en la parte inferior y superior de cada bollo, y coloque una empanada en la mitad inferior de cada bollo. Coloque unas rodajas de chile poblano, un trozo de lechuga, 2 rodajas de tomate y 2 aros de cebolla en cada hamburguesa. Cubra con 2 cucharadas de mermelada de tocino y la parte superior del bollo.':'Assemble the Burgers: Lightly toast the buns on the grill. Add a little Bunz sauce to the bottom and top of each bun, and place a patty on the bottom half of each bun. Place a few slices of poblano pepper, a slice of lettuce, 2 tomato slices, and 2 onion rings on each burger. Top with 2 tablespoons of bacon jam and the top of the bun.',
        'Para el pollo: 2 kilogramos de pollo, preferiblemente muslos deshuesados':'For the chicken: 2 kilograms of chicken, preferably boneless thighs',
        'Para el pollo: 1 chalote, finamente cortado en cubitos':'For the chicken: 1 shallot, finely diced',
        'Para el pollo: 4 dientes de ajo, finamente picados':'For the chicken: 4 garlic cloves, finely chopped',
        'Para el pollo: 1 cebolla roja, finamente picada':'For the chicken: 1 red onion, finely chopped',
        'Para el pollo: 4 a 5 pimientos habaneros, asados y sin semillas (Use guantes cuando trabaje con pimientos y evite tocarse los ojos)':'For the chicken: 4 to 5 habanero peppers, roasted and seedless (Wear gloves when working with peppers and avoid touching your eyes)',
        'Para el pollo: 6 limas, en jugo':'For the chicken: 6 limes, in juice',
        'Para el pollo: 2 naranjas sanguinas, en jugo':'For the chicken: 2 blood oranges, in juice',
        'Para el pollo: 2 cucharadas de sal':'For the chicken: 2 tablespoons of salt',
        'Para el pollo: 1 cucharada de pimienta':'For the chicken: 1 tablespoon of pepper',
        'Para el pollo: 1 cucharada de azúcar morena':'For the chicken: 1 tablespoon of brown sugar',
        'Para el pollo: 2 cucharadas de miel':'For the chicken: 2 tablespoons of honey',
        'Para el pollo: 1 rama de canela':'For the chicken: 1 cinnamon stick',
        'Para el pollo: 2 cucharaditas de pimienta de Jamaica':'For the chicken: 2 teaspoons of allspice',
        'Para el pollo: 1 cucharadita de nuez moscada':'For the chicken: 1 teaspoon of nutmeg',
        'Para el pollo: 650 mililitros de Cerveza Sierra Fría Porter':'For the chicken: 650 milliliters of Sierra Fría Porter Beer',
        'Para la succotash: 2 cucharadas de aceite de oliva':'For the succotash: 2 tablespoons of olive oil',
        'Para la succotash: 1/2 taza de cebolla roja, cortada en dados pequeños':'For the succotash: 1/2 cup red onion, diced',
        'Para la succotash: 1/2 taza de apio, cortado en dados pequeños':'For the succotash: 1/2 cup celery, diced',
        'Para la succotash: 1/2 taza de zanahoria, cortada en dados pequeños':'For the succotash: 1/2 cup carrot, diced',
        'Para la succotash: 1/8 taza de pimientos Anaheim, cortados en dados pequeños':'For the succotash: 1/8 cup Anaheim peppers, diced',
        'Para la succotash: 1 diente de ajo, finamente picado':'For the succotash: 1 garlic clove, finely minced',
        'Para la succotash: 1/2 taza de alubias frescas (o habas)':'For the succotash: 1/2 cup fresh beans (or broad beans)',
        'Para la succotash: 1 cucharada de mantequilla':'For the succotash: 1 tablespoon of butter',
        'Para la succotash: Sal y pimienta al gusto':'For the succotash: Salt and pepper to taste',
        'Marinar el pollo: combine todos los ingredientes, incluido el pollo, en un tazón grande. Marinar, refrigerado, durante al menos 2 horas, pero preferiblemente durante la noche.':'Marinating Chicken: Combine all ingredients, including chicken, in large bowl. Marinate, refrigerated, for at least 2 hours, but preferably overnight.',
        'Cuando el pollo esté listo para cocinar, prepare la succotash. En una sartén caliente, combine el aceite de oliva, la cebolla, el apio, la zanahoria, la pimienta de Anaheim y el ajo. Cocine por 4 minutos, o hasta que las cebollas y el apio comiencen a ablandarse. Revolviendo continuamente, agregue los frijoles. Cocine por otros 4 minutos y retire del fuego. Agregue la mantequilla, sal y pimienta, mezcle bien y reserve.':'When the chicken is ready to cook, prepare the succotash. In a hot skillet, combine olive oil, onion, celery, carrot, Anaheim pepper, and garlic. Cook for 4 minutes, or until the onions and celery begin to soften. Stirring continuously, add the beans. Cook for another 4 minutes and remove from heat. Add the butter, salt and pepper, mix well and reserve.',
        'Cubra ligeramente la superficie de cocción de una parrilla con aceite de oliva antes de encenderla a fuego medio. Deje que la parrilla se caliente durante 5 o 10 minutos antes de colocar el pollo marinado en la parrilla.':'Lightly coat the cooking surface of a grill with olive oil before lighting it over medium heat. Let the grill heat for 5-10 minutes before placing the marinated chicken on the grill.',
        'Asa el pollo durante 4 a 5 minutos por cada lado, hasta que esté completamente cocido. Use un termómetro de carne para asegurarse de que el pollo haya alcanzado una temperatura interna de 74 a 77 grados.':'Grill the chicken for 4 to 5 minutes on each side, until fully cooked. Use a meat thermometer to make sure the chicken has reached an internal temperature of 74 to 77 degrees.',
        'Arme este plato en una fuente grande, estilo familiar, colocando la succotash en el centro y colocando el pollo, caliente de la parrilla, encima.':'Assemble this dish in a large, family-style platter, placing the succotash in the center and placing the chicken, hot from the grill, on top.',
        '1/2 taza más 3 cucharadas de mantequilla':'1/2 cup plus 3 tablespoons butter',
        '3 manzanas, peladas, sin corazón y cortadas en cubos medianos (Granny Smith, Galas o Fujis)':'3 apples, peeled, cored and cut into medium cubes (Granny Smith, Galas or Fujis)',
        '1 taza de azúcar morena':'1 cup brown sugar',
        '1 huevo':'1 egg',
        '1 1/2 tazas de harina para todo uso':'1 1/2 cups all-purpose flour',
        '1 cucharadita de levadura en polvo':'1 teaspoon of baking powder',
        '1/2 cucharadita de bicarbonato de sodio':'1/2 teaspoon of baking soda',
        '1/2 cucharadita de canela molida':'1/2 teaspoon ground cinnamon',
        'Pizca de sal':'Pinch of salt',
        '1 taza de Cerveza Sierra Fría Porter':'1 cup of Sierra Fría Porter beer',
        'Precaliente el horno a 163 grados. Forre moldes para muffins con vasos de papel o engrase.':'Preheat oven to 163 degrees. Line muffin cups with paper cups or grease.',
        'En una sartén grande para saltear, derrita 3 cucharadas de mantequilla y saltee las manzanas hasta que estén blandas (alrededor de 10 a 15 minutos). Dejar de lado.':'In a large sauté pan, melt 3 tablespoons butter and sauté apples until soft (about 10 to 15 minutes). Set aside.',
        'En un tazón, mezcle la mantequilla restante y el azúcar morena hasta que quede esponjoso. Agregue el huevo y bata hasta que esté combinado.':'In a bowl, mix the remaining butter and brown sugar until fluffy. Add the egg and beat until combined.',
        'En un tazón mediano, mezcle los ingredientes secos. Agregue la mezcla seca a la mezcla de mantequilla y mezcle hasta que no haya grumos y todo esté incorporado. Agregue la cerveza y las manzanas. Llene los moldes para muffins aproximadamente tres cuartos del camino y hornee durante unos 15 minutos. Pruebe la cocción colocando un palillo de dientes en el centro de un panecillo; el palillo de dientes debe salir con solo unas pocas migajas. Enfríe completamente antes de servir.':'In a medium bowl, mix the dry ingredients. Add the dry mix to the butter mix and mix until smooth and everything is incorporated. Add beer and apples. Fill muffin pans about three-fourths of the way and bake for about 15 minutes. Test cooking by placing a toothpick in the center of a bun; the toothpick should come out with only a few crumbs. Chill completely before serving.',
        'Para la masa: 4 tazas de Cerveza Sierra Fría Porter, medido sin espuma':'For the dough: 4 cups Sierra Fría Porter beer, measured without foam',
        'Para la masa: 4 barras (2 tazas) de mantequilla derretida':'For the dough: 4 sticks (2 cups) of melted butter',
        'Para la masa: 1 1/2 tazas de cacao en polvo sin azúcar':'For the dough: 1 1/2 cups unsweetened cocoa powder',
        'Para la masa: 4 tazas de harina para todo uso':'For the dough: 4 cups of all-purpose flour',
        'Para la masa: 3 1/2 tazas de azúcar granulada':'For the dough: 3 1/2 cups of granulated sugar',
        'Para la masa: 3 cucharaditas de bicarbonato de sodio':'For the dough: 3 teaspoons of baking soda',
        'Para la masa: 1 1/2 cucharaditas de sal':'For the dough: 1 1/2 teaspoons of salt',
        'Para la masa: 227 gramos de crema agria':'For the dough: 227 grams of sour cream',
        'Para la masa: 4 huevos':'For the dough: 4 eggs',
        'Para el glaseado: 3 barras (1 1/2 tazas) de mantequilla, ablandadas':'For the frosting: 3 sticks (1 1/2 cups) of butter, softened',
        'Para el glaseado: 680 gramos de queso crema, ablandado':'For the frosting: 680 grams of cream cheese, softened',
        'Para el glaseado: 454 gramos de azúcar en polvo':'For the frosting: 454 grams of powdered sugar',
        'Para el glaseado: 1 1/2 cucharaditas de extracto puro de vainilla':'For the frosting: 1 1/2 teaspoons of pure vanilla extract',
        'Últimos retoques: Salsa de caramelo':'Last touches: Caramel sauce',
        'Últimos retoques: Salsa de chocolate o dulce de azúcar':'Last touches: Chocolate sauce or fudge',
        'Precaliente el horno a 190 grados.':'Preheat oven to 190 degrees.',
        'En una sartén, calienta la cerveza hasta que se reduzca a la mitad. Agregue la mantequilla y revuelva para combinar.':'In a skillet, heat the beer until it is halved. Add butter and stir to combine.',
        'En el tazón de una batidora, combine el cacao en polvo, la harina, el azúcar, el bicarbonato de sodio y la sal. Mezcle a temperatura baja durante 1 minuto, o hasta que esté bien combinado.':'In a mixer bowl, combine cocoa powder, flour, sugar, baking soda, and salt. Mix on low for 1 minute, or until well combined.',
        'Agregue lentamente la mezcla de cerveza a los ingredientes secos y mezcle hasta que esté bien incorporada.':'Slowly add the beer mixture to the dry ingredients and mix until well incorporated.',
        'Agregue la crema agria y mezcle. Agregue los huevos y mezcle a velocidad media hasta que quede suave.':'Add sour cream and mix. Add the eggs and mix on medium speed until smooth.',
        'Permita que la masa descanse durante 20 minutos (para eliminar las bolsas de aire).':'Allow the dough to rest for 20 minutes (to remove air pockets).',
        'Coloque bolsas de papel en los pozos de moldes para muffins o engrase los pozos. Llene las bolsas con cantidades iguales de masa y colóquelas en el horno durante 20 a 25 minutos. Inserte un palillo en el medio de un pastelito después de 20 minutos. Si el palillo sale limpio, los pastelitos están listos. Enfriar a temperatura ambiente.':'Put paper bags in the muffin pan wells or grease the wells. Fill the bags with equal amounts of dough and put them in the oven for 20 to 25 minutes. Insert a toothpick in the middle of a cupcake after 20 minutes. If the toothpick comes out clean, the cupcakes are ready. Cool down to room temperature.',
        'Haga el glaseado: en una batidora con un accesorio de paleta, batir la mantequilla hasta que se vuelva de color claro y duplique su volumen (aproximadamente 5 minutos).':'Make the frosting: In a mixer with a paddle attachment, beat the butter until it turns light in color and doubles its volume (about 5 minutes).',
        'Agregue el queso crema y batir por 2 minutos. Raspe los lados del tazón.':'Add cream cheese and beat for 2 minutes. Scrape down the sides of the bowl.',
        'Agregue el azúcar en polvo y la vainilla, y mezcle a baja velocidad hasta que quede suave y cremosa (aproximadamente 2 minutos). Refrigere hasta que sea necesario.':'Add the powdered sugar and vanilla, and mix on low speed until smooth and creamy (about 2 minutes). Refrigerate until necessary.',
        'Cuando las magdalenas estén heladas, rocíe con salsa de caramelo y/o salsa de chocolate caliente. Servir.':'When the muffins are frozen, drizzle with caramel sauce and / or hot chocolate sauce. To serve.',
        'Alitas de Pollo Buffalo':'Buffalo Chicken Wings',
        'Para la salsa de queso azul: 60 ml de queso roquefort':'For the blue cheese sauce: 60 ml of roquefort cheese',
        'Para la salsa de queso azul: 60 ml de suero de leche':'For the blue cheese sauce: 60 ml of buttermilk',
        'Para la salsa de queso azul: 60 ml de mayonesa':'For the blue cheese sauce: 60 ml of mayonnaise',
        'Para la salsa de queso azul: 60 ml de crema agria':'For the blue cheese sauce: 60 ml of sour cream',
        'Para la salsa de queso azul: 1 cucharada de jugo de limón':'For the blue cheese sauce: 1 tablespoon of lemon juice',
        'Para la salsa de queso azul: Sal y pimienta negra':'For the blue cheese sauce: Salt and black pepper',
        'Para las alitas: 2 cucharadas de mantequilla sin sal, derretida':'For the wings: 2 tablespoons unsalted butter, melted',
        'Para las alitas: 120 ml de salsa picante':'For the wings: 120 ml of hot sauce',
        'Para las alitas: 1/2 cucharadita de pimienta cayena':'For the wings: 1/2 teaspoon of cayenne pepper',
        'Para las alitas: Sal y pimienta negra':'For the wings: Salt and black pepper',
        'Para las alitas: 2 litros de aceite vegetal para freír':'For the wings: 2 liters of vegetable oil for frying',
        'Para las alitas: 90 gramos de maizena':'For the wings: 90 grams of cornstarch',
        'Para las alitas: 1.5 kilos de alitas de pollo':'For the wings: 1.5 kilos of chicken wings',
        'Para las alitas: 4 apios, cortados finamente':'For the wings: 4 celery, finely chopped',
        'Para la salsa de queso azul: Mezclamos el roquefort, el suero de leche, la mayonesa, la crema agria, y el jugo de limón en un bol mediano. Lo cubrimos y lo metimos en la nevera. Añadimos unas pizcas de sal y pimienta. Podemos hacer esto hasta dos días antes.':'For the blue cheese sauce: Mix the roquefort, buttermilk, mayonnaise, sour cream, and lemon juice in a medium bowl. We covered it and put it in the fridge. Add a pinch of salt and pepper. We can do this up to two days in advance.',
        'Para las alitas: Dejamos las alitas fuera de la nevera para media hora, para coger la temperatura de ambiente para que cocinen equilibradamente. Mientras, derritamos las mantequilla en una olla pequeña.':'For the wings: We leave the wings out of the fridge for half an hour, to take the room temperature so that they cook balanced. Meanwhile, we melt the butter in a small saucepan.',
        'Para las alitas: Mezclamos la salsa picante, la cayena, 1/2 cucharadita de pimienta negra y otra de sal. Quitamos del fuego y la recalentamos justo antes de bañar las alitas en ella.':'For the wings: Mix the hot sauce, the cayenne, 1/2 teaspoon of black pepper and another of salt. We remove from the fire and reheat it just before bathing the wings in it.',
        'Para las alitas: Echamos el aceite en una olla grande, o una freidora. Calentamos hasta que llegue a 190 grados.':'For the wings: We put the oil in a large pot, or a deep fryer. We heat until it reaches 190 degrees.',
        'Para las alitas: En un tazón grande, echamos la maizena y sazonamos las alitas con sal y pimienta. En tres grupos, rebozamos las alitas en la maizena, quitando el exceso antes de echarlas en el aceite. La damos vueltas de vez en cuando hasta que la piel esté dorada y crujiente, unos 10-12 minutos.':'For the wings: In a large bowl, add the cornstarch and season the wings with salt and pepper. In three groups, we coat the wings in the cornstarch, removing the excess before pouring them into the oil. We turn it from time to time until the skin is golden and crisp, about 10-12 minutes.',
        'Para las alitas: Transferimos las alitas a un tazón grande, añadimos la salsa calentada y mezclamos.':'For the wings: Transfer the wings to a large bowl, add the heated sauce and mix.',
        'Para las alitas: Servimos las alitas con apio y el queso azul.':'For the wings: We serve the wings with celery and blue cheese.',
        'BlogTitulo':'Blog',
        'BlogSubtitulo':'Learn about our adventures and the latest news from the beer world.',
        'BlogLeer':'Read More',
        'BlogVacio':'Sorry, there are no articles.',
        'comentariosFormularioTitulo':'Leave a comment',
        'comentarioNombre':'Name',
        'comentarioCorreo':'Email',
        'comentarioMensaje':'Write your comment...',
        'comentarioEnviar':'Send',
        'comentariosTitulo':'Comments',
        'compartirTitulo':'Share'
    }
};
