import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import firebase from "../../Firebase";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import ViewOrderWebContainer from "./ViewOrderWebContainer";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class OrdersWebContainer extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.state = {
            ordersList: [],
            section: 'orders',
            order: null
        };
        this.handlerOrder = this.handlerOrder.bind(this);
        this.tableOrders = this.db.collection('clientes').where("correo", "==", localStorage.getItem('clientEmail'));
    }

    componentDidMount() {
        this.unsubscribe = this.tableOrders.onSnapshot(this.getOrdersList);
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    handlerOrder() {
        window.scrollTo(0, 0);
        this.setState({ section: 'orders' });
    }

    viewOrder = (order) => {
        window.scrollTo(0, 0);
        this.setState({ section: 'order', order });
    };

    getOrdersList = (querySnapshot) => {
        let ordersList = [];
        const options = {dateStyle: "long",timeStyle: "short",timeZone: "America/Mexico_City"};
        querySnapshot.forEach((doc) => {
            const {pedidos} = doc.data();
            if (pedidos !== undefined) {
                pedidos.map( (pedido,key) => {
                    const { fecha, total, productos, estado, direccion } = pedido;
                    let estadoValor = '';
                    if(estado === 'Capturado'){
                        estadoValor = this.context.t("PedidoCapturado");
                    }else if(estado === 'Pagado'){
                        estadoValor = this.context.t("PedidoPagado");
                    }else if(estado === 'Enviado'){
                        estadoValor = this.context.t("PedidoEnviado");
                    }else if(estado === 'Entregado'){
                        estadoValor = this.context.t("PedidoEntregado");
                    }
                    const timestampOrder = fecha.seconds * 1000;
                    const locale = this.props.lang === 'en' ? 'en-US' : 'es-MX';
                    const dateOrder = new Intl.DateTimeFormat(locale, options).format(timestampOrder);
                    const order = { key, fecha: dateOrder, direccion, total, estado: estadoValor, productos };
                    const acciones = [<button key={key} onClick={() => this.viewOrder(order)}>{this.context.t("PedidosVer")}</button>];
                    ordersList.push({ key, fecha: dateOrder, direccion, total, estado: estadoValor, productos, acciones });
                    return true;
                });
            }
        });
        this.setState({ordersList});
    };

    renderBody() {
        if(this.state.section === 'orders'){
            return (
                <div className="orders-container">
                    <div className="qodef-title-holder orders-web-image">
                        <div className="qodef-title-wrapper">
                            <div className="qodef-title-inner">
                                <div className="qodef-grid">
                                    <h1 className="qodef-page-title">{this.context.t("PedidosTitulo")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="body">
                            {this.state.ordersList.length > 0 ?
                                <Table className="table_orders">
                                    <Thead>
                                        <Tr>
                                            <Th>{this.context.t("PedidosFecha")}</Th>
                                            <Th>{this.context.t("PedidosDireccion")}</Th>
                                            <Th>{this.context.t("PedidosTotal")}</Th>
                                            <Th>{this.context.t("PedidosEstado")}</Th>
                                            <Th>{this.context.t("PedidosDetalles")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.state.ordersList.map((order, key) => {
                                            return (
                                                <Tr className="order_item" key={key}>
                                                    <Td>{order.fecha}</Td>
                                                    <Td>{order.direccion}</Td>
                                                    <Td>${order.total.toFixed(2)} MXN</Td>
                                                    <Td>{order.estado}</Td>
                                                    <Td>{order.acciones}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                                : <div className="empty-orders-container">
                                    <h5 className="empty-orders">{this.context.t("PedidosVacio")}</h5>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        } else if(this.state.section === 'order'){
            return <ViewOrderWebContainer handler={this.handlerOrder} order={this.state.order} />
        }
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        lang: state.i18nState.lang
    }
};

OrdersWebContainer.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(OrdersWebContainer);
