import React, {Component} from 'react';
import Swal from "sweetalert2";
import AppFrame from "../../components/AppFrame";
import firebase from './../../../Firebase';
import EditCategory from "../../components/category/EditCategory";

class EditCategoryArticle extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.tableCategories = this.db.collection('categorias-articulos');
    }

    handleSubmit = (values) => {
        const {id, nombre} = values;
        this.tableCategories.doc(id).update({nombre}).then(() => {
            Swal.fire({
                title: '¡Operación exitosa!',
                text: 'El elemento ha sido guardado correctamente.',
                icon: 'success',
                confirmButtonText: 'Continuar'
            }).then(() => {
            });
            this.props.history.push('categorias-articulos');
        }).catch(() => {
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Hubo un problema con la base de datos.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then(() => {
            });
        });
    }


    renderBody() {
        return (
            <EditCategory data={this.props.location.category} onSubmit={this.handleSubmit}/>
        );
    }

    render() {
        return (
            <AppFrame title="Editar Categoría" body={this.renderBody()}/>
        );
    }
}

export default EditCategoryArticle;
