import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';

class ViewMessageContainer extends Component {
  renderBody(){
    const { fecha, nombre, correo, asunto, mensaje } = this.props.location.message;
    return (
      <div className="card">
        <div className="body">
          <small className="text-muted">Fecha: </small>
          <p>{fecha}</p>
          <small className="text-muted">Nombre: </small>
          <p>{nombre}</p>
          <small className="text-muted">Correo: </small>
          <p>{correo}</p>
          <small className="text-muted">Asunto: </small>
          <p>{asunto}</p>
          <small className="text-muted">Mensaje: </small>
          <p>{mensaje}</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <AppFrame title="Detalles del Mensaje" body={this.renderBody()} />
    );
  }
}

export default ViewMessageContainer;
