import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

const listStates = [{'key': 'Aguascalientes', 'nombre': 'Aguascalientes'}, {
    'key': 'Baja California',
    'nombre': 'Baja California'
}, {'key': 'Baja California Sur', 'nombre': 'Baja California Sur'}, {
    'key': 'Campeche',
    'nombre': 'Campeche'
}, {'key': 'Coahuila', 'nombre': 'Coahuila'}, {'key': 'Colima', 'nombre': 'Colima'}, {
    'key': 'Chiapas',
    'nombre': 'Chiapas'
}, {'key': 'Chihuahua', 'nombre': 'Chihuahua'}, {
    'key': 'Distrito Federal',
    'nombre': 'Distrito Federal'
}, {'key': 'Durango', 'nombre': 'Durango'}, {'key': 'Guanajuato', 'nombre': 'Guanajuato'}, {
    'key': 'Guerrero',
    'nombre': 'Guerrero'
}, {'key': 'Hidalgo', 'nombre': 'Hidalgo'}, {'key': 'Jalisco', 'nombre': 'Jalisco'}, {
    'key': 'Estado de México',
    'nombre': 'Estado de México'
}, {'key': 'Michoacán', 'nombre': 'Michoacán'}, {'key': 'Morelos', 'nombre': 'Morelos'}, {
    'key': 'Nayarit',
    'nombre': 'Nayarit'
}, {'key': 'Nuevo León', 'nombre': 'Nuevo León'}, {'key': 'Oaxaca', 'nombre': 'Oaxaca'}, {
    'key': 'Puebla',
    'nombre': 'Puebla'
}, {'key': 'Querétaro', 'nombre': 'Querétaro'}, {
    'key': 'Quintana Roo',
    'nombre': 'Quintana Roo'
}, {'key': 'San Luis Potosí', 'nombre': 'San Luis Potosí'}, {'key': 'Sinaloa', 'nombre': 'Sinaloa'}, {
    'key': 'Sonora',
    'nombre': 'Sonora'
}, {'key': 'Tabasco', 'nombre': 'Tabasco'}, {'key': 'Tamaulipas', 'nombre': 'Tamaulipas'}, {
    'key': 'Tlaxcala',
    'nombre': 'Tlaxcala'
}, {'key': 'Veracruz', 'nombre': 'Veracruz'}, {'key': 'Yucatán', 'nombre': 'Yucatán'}, {
    'key': 'Zacatecas',
    'nombre': 'Zacatecas'
}];

class VisitorWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowSubmit: true,
            listGenres: []
        };
    }

    componentDidMount() {
        const listGenres = [{
            'key': this.context.t("visitorMasculino"),
            'nombre': this.context.t("visitorMasculino")
        }, {'key': this.context.t("visitorFemenino"), 'nombre': this.context.t("visitorFemenino")}];
        this.setState({listGenres});
    }

    renderField = ({input, type, placeholder}) => (
        <div className="form-group">
            <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} required/>
        </div>
    );

    renderSelect = ({input, label, name, empty, options}) => (
        <div className="form-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <select className="form-control" {...input} disabled={options.length === 0} required>
                <option value="">{empty}</option>
                {options.length > 0 && options.map(element => {
                    return (<option key={element.key} value={element.nombre}>{element.nombre}</option>);
                })}
            </select>
        </div>
    );

    onChange = (value) => {
        if (value !== '') {
            this.setState({allowSubmit: false});
        }
    }

    render() {
        const {handleSubmit, onClose} = this.props;
        return (
            <form onSubmit={handleSubmit} className="form-visitor">
                <h2>Cerveza Sierra Fría</h2>
                <p>{this.context.t("visitorMensaje")}</p>
                <Field name="correo" component={this.renderField} type="email"
                       placeholder={this.context.t("visitorCorreo")}/>
                <Field name="edad" component={this.renderField} type="number" min="0" max="100"
                       placeholder={this.context.t("visitorEdad")}/>
                <Field name="genero" label={this.context.t("visitorGenero")} component={this.renderSelect}
                       options={this.state.listGenres} empty={this.context.t("visitorGeneroSelect")}/>
                <Field name="estadoResidencia" label={this.context.t("visitorEstado")} component={this.renderSelect}
                       options={listStates} empty={this.context.t("visitorEstadoSelect")}/>
                <div className="captcha">
                    <ReCAPTCHA
                        sitekey="6LdUc-0UAAAAAHpY0hK9gvUt8lXFzCfSt2j654sc"
                        onChange={this.onChange}
                        theme="light"
                    />
                </div>
                <div className="botones-modal">
                    <button type="submit" disabled={this.state.allowSubmit}
                            className="btn btn-inicio">{this.context.t("visitorEnviar")}</button>
                    <button type="button" className="btn btn-inicio-cerrar"
                            onClick={onClose}>{this.context.t("visitorCancelar")}</button>
                </div>
            </form>
        );
    }
}

const VisitorWebForm = reduxForm({
    form: 'VisitorWebForm'
})(VisitorWeb);

VisitorWeb.contextTypes = {
    t: PropTypes.func.isRequired
};

export default VisitorWebForm;
