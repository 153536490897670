import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';

const validate = values => {
  const error = {};
  if(!values.nombre){
  error.nombre = 'Escribe el nombre por favor.';
  }
  return error;
};

class EditCategory extends Component {
  componentDidMount() {
    const { data } = this.props;
    this.props.initialize({ id: data.key });
    this.props.dispatch(change('EditCategoryForm', 'nombre', data.nombre));
  }

  renderField = ({input, meta, type, label, name, placeholder, autocomplete }) => (
    type === 'hidden' ?
    <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={autocomplete} />
    :
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={autocomplete} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div> 
  );

  render() {
    const {handleSubmit, submitting, pristine } = this.props;
    return (
      <div className="card">
        <div className="body">
          <div className="form-container m-b-30">
            <form onSubmit={handleSubmit}>
              <Field name="id" label="ID" component={this.renderField} type="hidden" placeholder="ID" />
              <Field name="nombre" label="Nombre" component={this.renderField} type="text" placeholder="Nombre" />
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Guardar</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const EditCategoryForm = reduxForm({ 
  form: 'EditCategoryForm',
  validate
})(EditCategory);

export default EditCategoryForm;