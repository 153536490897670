import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import Swal from 'sweetalert2';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'nombre',
  text: 'Nombre',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'productos',
  text: 'Productos',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'acciones',
  text: 'Acciones',
  classes: 'row-flex'
}];

class CategoryProductsContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      categoriesList: []
    };
    this.tableCategories = this.db.collection('categorias-productos');
  }

  componentDidMount() {
    this.unsubscribe = this.tableCategories.onSnapshot(this.getCategoriesList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getCategoriesList = (querySnapshot) => {
    const categoriesList = [];
    querySnapshot.forEach((doc) => {
      const { nombre } = doc.data();
      this.db.collection('productos').where('categoria','==',nombre).get().then( snap => {
        const categoria = { key: doc.id, nombre, productos: snap.size };
        const keyEdit = 'edit' + doc.id;
        const keyDelete = 'delete' + doc.id;
        let acciones = '';
        if(snap.size > 0){
          acciones = <button key={keyEdit} className="btn btn-warning" onClick={() => this.editCategory(categoria)}><i className="fa fa-edit"></i> Editar</button>;
        }else{
          acciones = [<button key={keyEdit} className="btn btn-warning" onClick={() => this.editCategory(categoria)}><i className="fa fa-edit"></i> Editar</button>,<button key={keyDelete} className="btn btn-danger" onClick={() => this.deleteCategory(categoria.key)}><i className="far fa-trash-alt"></i> Eliminar</button>];
        }
        categoriesList.push({ key: doc.id, nombre, productos: snap.size, acciones });
        this.setState({ categoriesList });
      });
    });
  }

  addCategory = () => {
    this.props.history.push('nueva-categoria');
  }

  editCategory = (category) => {
    this.props.history.push({
      pathname: '/editar-categoria',
      category
    });
  }

  deleteCategory = (key) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás recuperar este elemento.",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then( (result) => {
      if(result.value){
        this.tableCategories.doc(key).delete().then( () => {
          Swal.fire({
            title: '¡Operación exitosa!',
            text: 'El elemento ha sido eliminado correctamente.',
            icon: 'success',
            confirmButtonText: 'Continuar'
          });
        }).catch( () => {
          Swal.fire({
            title: '¡Lo sentimos!',
            text: 'Hubo un problema con la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Continuar'
          });
        });
      }
    })
  }

  renderBody(){
    return(
      <div className="categories-container">
        <div className="row clearfix m-b-25">
          <div className="col-md-12 col-sm-12">
            <button className="btn btn-sm btn-success" onClick={() => this.addCategory()}>Agregar Categoría</button>
          </div>
        </div>
        <div className="card">
          <div className="body">
            { this.state.categoriesList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Nombre</Th>
                  <Th>Productos</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.categoriesList.map(category => {
                  return(
                    <Tr key={category.key}>
                      <Td>{category.nombre}</Td>
                      <Td>{category.productos}</Td>
                      <Td>{category.acciones}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.categoriesList} columns={columns} />
          </div>
        </div>
      </div>            
    );
  }

  render() {
    return (
      <AppFrame title="Categorías de Productos" body={this.renderBody()} />
    );
  }
}

export default CategoryProductsContainer;
