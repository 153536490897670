import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';
import firebase from './../../../Firebase';
import Swal from 'sweetalert2';
import NewStore from '../../components/stores/NewStore';

class NewStoreContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.tableStores = this.db.collection('tiendas');
  }

  handleSubmit = (values) => {
    const { nombre, direccion, latitud, longitud } = values;
    const lat = Number(latitud);
    const lng = Number(longitud);
    this.tableStores.add({ nombre, direccion, latitud: lat, longitud: lng }).then( () => {
      Swal.fire({
        title: '¡Operación exitosa!',
        text: 'El elemento ha sido agregado correctamente.',
        icon: 'success',
        confirmButtonText: 'Continuar'
      });
      this.props.history.push('lista-tiendas');
    }).catch( () => {
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'Hubo un problema con la base de datos.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      });
    });
  }

  renderBody(){
    return (
      <NewStore onSubmit={this.handleSubmit} />
    );
  }

  render() {
    return (
      <AppFrame title="Nueva Tienda" body={this.renderBody()} />
    );
  }
}

export default NewStoreContainer;
