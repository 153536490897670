import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';
import EditCategory from './../../components/categories/EditCategory';
import firebase from './../../../Firebase';
import Swal from 'sweetalert2';

class EditCategoryContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.tableCategories = this.db.collection('categorias-productos');
  }

  handleSubmit = (values) => {
    const { id, nombre } = values;
    this.tableCategories.doc(id).update({ nombre }).then( () => {
      Swal.fire({
        title: '¡Operación exitosa!',
        text: 'El elemento ha sido guardado correctamente.',
        icon: 'success',
        confirmButtonText: 'Continuar'
      });
      this.props.history.push('categorias-productos');
    }).catch( () => {
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'Hubo un problema con la base de datos.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      });
    });
  }

  renderBody(){
    return (
      <EditCategory data={this.props.location.category} onSubmit={this.handleSubmit}  />
    );
  }

  render() {
    return (
      <AppFrame title="Editar Categoría" body={this.renderBody()} />
    );
  }
}

export default EditCategoryContainer;
