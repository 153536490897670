import React, { Component } from 'react';
import AppFrame from '../components/AppFrame';
import firebase from '../../Firebase';
import ContactWeb from '../components/ContactWeb';
import Swal from 'sweetalert2';
import PropTypes from "prop-types";

class ContactWebContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.tableContact = this.db.collection('contacto');
  }

  handleSubmit = (values) => {
    const { nombre, correo, asunto, mensaje } = values;
    const fecha = new Date();
    this.tableContact.add({ nombre, correo, asunto, mensaje, fecha }).then( () => {
      Swal.fire({
        title: this.context.t("ModalGracias"),
        text: this.context.t("ModalContactoExito"),
        icon: 'success',
        confirmButtonText: this.context.t("ModalBotonContinuar")
      }).then( () => {
        window.location.reload();
      });
    }).catch( () => {
      Swal.fire({
        title: this.context.t("ModalLoSentimos"),
        text: this.context.t("ModalContactoError"),
        icon: 'warning',
        confirmButtonText: this.context.t("ModalBotonContinuar")
      }).then( () => {});
    });
  };

  renderBody(){
    return(
      <div className="contact-web-container">
        <div className="qodef-title-holder contact-web-image">
          <div className="qodef-title-wrapper">
            <div className="qodef-title-inner">
              <div className="qodef-grid">
                <h1 className="qodef-page-title">{this.context.t("ContactoTitulo")}</h1>
                <span className="qodef-page-subtitle">{this.context.t("ContactoSubtitulo")}</span>
              </div>
            </div>
          </div>
        </div>
        <section id="contact-form">
          <ContactWeb onSubmit={this.handleSubmit} />
        </section>
      </div>
    );
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

ContactWebContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContactWebContainer;
