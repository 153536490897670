const initState = {
    activeContent: ''
};

const TextEditor = (state = initState, action) => {
    if(action.type === 'SET_ACTIVE_CONTENT'){
        return{ ...state, activeContent: action.content }
    }else{
        return state;
    }
};

export default TextEditor;
