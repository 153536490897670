const initState = {
  activeMenu: ''
}

const MobileMenu = (state = initState, action) => {
  if(action.type === 'SET_ACTIVE_MENU'){
    return{ ...state, activeMenu: action.menu }
  }else{
    return state;
  }
}

export default MobileMenu;