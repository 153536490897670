import React, {Component} from 'react';
//import PropTypes from "prop-types";

class AppFooter extends Component {
    render() {
        return (
            <footer>
                <p>Copyright &copy; {(new Date().getFullYear())} Cerveza Sierra Fría.</p>
            </footer>
        );
    }
}

//AppFooter.contextTypes = {
//    t: PropTypes.func.isRequired
//};

export default AppFooter;
//<p>{this.context.t("footerDeveloper")} <a href="http://bytalabs.mx/" target="_blank" rel="noopener noreferrer"><b>Byta Labs</b></a></p>
