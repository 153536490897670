import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import { textFilter } from 'react-bootstrap-table2-filter';
import OrdersClientContainer from './OrdersClientContainer';
import OrderClientContainer from './OrderClientContainer';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'fechaRegistro',
  text: 'Fecha de Registro',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'nombre',
  text: 'Nombre',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'correo',
  text: 'Correo',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'acciones',
  text: 'Acciones',
  classes: 'row-flex'
}];

class ClientsContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      clientsList: [],
      section: 'clients',
      client: null,
      order: null
    };
    this.handlerOrders = this.handlerOrders.bind(this);
    this.handlerOrder = this.handlerOrder.bind(this);
    this.tableClients = this.db.collection('clientes');
  }

  handlerOrders() {
    this.setState({ section: 'clients' });
  }

  handlerOrder() {
    this.setState({ section: 'orders-client' });
  }

  componentDidMount() {
    this.unsubscribe = this.tableClients.onSnapshot(this.getclientsList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getclientsList = (querySnapshot) => {
    const clientsList = [];
    const options = {dateStyle: "long",timeStyle: "short",timeZone: "America/Mexico_City"};
    querySnapshot.forEach((doc) => {
      const { fechaRegistro, nombre, correo, pedidos } = doc.data();
      const timestampClient = fechaRegistro.seconds * 1000;
      const dateClient = new Intl.DateTimeFormat('es-MX', options).format(timestampClient);
      const pedidosArr = [];
      if(pedidos !== undefined) {
        pedidos.map((pedido, keyOrder) => {
          const {fecha, direccion, total, productos, estado, stripe_id} = pedido;
          const timestampOrder = fecha.seconds * 1000;
          const dateOrder = new Intl.DateTimeFormat('es-MX', options).format(timestampOrder);
          const productosArr = [];
          productos.map((producto, keyProduct) => {
            const {cantidad, nombre, precio} = producto;
            productosArr.push({key: keyProduct, nombre, cantidad, precio});
            return true;
          });
          const order = {
            key: keyOrder,
            cliente: correo,
            fecha: dateOrder,
            direccion,
            total,
            productos: productosArr,
            estado,
            stripe_id
          };
          const keyView = 'view' + doc.id;
          const accionesOrder = [<button key={keyView} className="btn btn-primary"
                                         onClick={() => this.viewOrder(order)}><i className="fa fa-eye"/> Ver Detalles
          </button>];
          pedidosArr.push({
            key: keyOrder,
            fecha: dateOrder,
            direccion,
            total,
            productos: productosArr,
            estado,
            stripe_id,
            acciones: accionesOrder
          });
          return true;
        });
      }
      const client = { key: doc.id, fechaRegistro: dateClient, nombre, correo, pedidos: pedidosArr };
      const keyView = 'view' + doc.id;
      const accionesClient = [<button key={keyView} className="btn btn-primary" onClick={() => this.viewOrders(client)}><i className="fa fa-eye"/> Ver Pedidos</button>];
      clientsList.push({ key: doc.id, fechaRegistro: dateClient, nombre, correo, acciones: accionesClient });
    });
    this.setState({ clientsList });
  };

  viewOrder = (order) => {
    this.setState({ section: 'order-client', order });
  };

  viewOrders = (client) => {
    this.setState({ section: 'orders-client', client });
  };

  renderBody(){
    if(this.state.section === 'clients'){
      return(
        <div id="clients-container">
          <div className="card">
            <div className="body">
              { this.state.clientsList.length > 0 ? <Table className="table-admin">
                <Thead>
                  <Tr>
                    <Th>Fecha de Registro</Th>
                    <Th>Nombre</Th>
                    <Th>Correo</Th>
                    <Th>Acciones</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  { this.state.clientsList.map(client => {
                    return(
                      <Tr key={client.key}>
                        <Td>{client.fechaRegistro}</Td>
                        <Td>{client.nombre}</Td>
                        <Td>{client.correo}</Td>
                        <Td>{client.acciones}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
              <TableDesktop data={this.state.clientsList} columns={columns} />
            </div>
          </div>
        </div>            
      );
    } else if(this.state.section === 'orders-client'){
      return <OrdersClientContainer handler={this.handlerOrders} client={this.state.client} />;
    } else if(this.state.section === 'order-client'){
      return <OrderClientContainer handler={this.handlerOrder} order={this.state.order} />
    }
  }

  render() {
    return (
      <AppFrame title="Clientes" body={this.renderBody()} />
    );
  }
}

export default ClientsContainer;
