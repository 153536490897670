const initState = {
  activeImage: null
};

const Photo = (state = initState, action) => {
  if(action.type === 'SET_ACTIVE_IMAGE'){
    return{ ...state, activeImage: action.image }
  }else{
    return state;
  }
};

export default Photo;
