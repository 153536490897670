import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import firebase from '../../Firebase';
import Swal from 'sweetalert2';
import ReactHtmlParser from "react-html-parser";
import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from "react-share";
import Comment from "../components/Comment";
import PropTypes from "prop-types";

class ArticleWebContainer extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.state = {
            article: null,
            comments: []
        }
        this.tableArticles = this.db.collection('articulos');
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        this.getData(params.get('articuloId'));
    }

    getData = (id) => {
        this.tableArticles.doc(id).get().then(doc => {
            if (doc.exists) {
                const options = {dateStyle: "long", timeZone: "America/Mexico_City"};
                const {fecha, titulo, categoria, portada, contenido, comentarios} = doc.data();
                const timestamp = fecha.seconds * 1000;
                const date = new Intl.DateTimeFormat('es-MX', options).format(timestamp);
                let comments = [];
                if (comentarios !== undefined) {
                    comentarios.forEach(comment => {
                        const ts = comment.fecha.seconds * 1000;
                        comment.fecha = new Intl.DateTimeFormat('es-MX', options).format(ts);
                        comments.push(comment);
                    });
                }
                const content = contenido.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;mdash;/g, '-');
                const article = {key: doc.id, fecha: date, titulo, categoria, portada, contenido: content};
                this.setState({article, comments});
            }
        });
    }

    handleSubmit = (values) => {
        const {nombre, correo, comentario} = values;
        const fecha = new Date();
        const comment = {nombre, correo, comentario, fecha};
        let articleRef = this.db.collection('articulos').doc(this.state.article.key);
        articleRef.update({comentarios: firebase.firestore.FieldValue.arrayUnion(comment)}).then(() => {
            Swal.fire({
                title: '¡Gracias!',
                text: 'Tu comentario ha sido enviado exitosamente.',
                icon: 'success',
                confirmButtonText: 'Continuar'
            }).then(() => {
                window.location.reload();
            });
        }).catch(() => {
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Hubo un problema enviando tu comentario.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then(() => {
            });
        });
    };

    renderBody() {
        return (
            <div className="article-web-container">
                <div className="title-container">
                    <h2>{this.state.article && this.state.article.titulo}</h2>
                    <p className="fecha">{this.state.article && this.state.article.fecha}</p>
                </div>
                <div className="content-container">
                    <div className="portada">
                        <img src={this.state.article && this.state.article.portada} alt="Cerveza Sierra Fría"/>
                    </div>
                    <div className="meta">
                        <p className="categoria">{this.state.article && this.state.article.categoria}</p>
                    </div>
                    <div className="contenido">
                        {ReactHtmlParser(this.state.article && this.state.article.contenido)}
                    </div>
                </div>
                <div className="share-container">
                    <h3>{this.context.t("compartirTitulo")}</h3>
                    <div className="botones">
                        <FacebookShareButton
                            url={'https://www.facebook.com/sharer.php?u=' + window.location.href}
                            quote={this.state.article && this.state.article.titulo}
                        >
                            <FacebookIcon size={32} round/>
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={window.location.href}
                            title={this.state.article && this.state.article.titulo}
                        >
                            <TwitterIcon size={32} round/>
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={window.location.href}
                            title={this.state.article && this.state.article.titulo}
                            separator=" "
                        >
                            <WhatsappIcon size={32} round/>
                        </WhatsappShareButton>
                    </div>
                </div>
                {
                    this.state.comments.length > 0 ?
                        <div className="comments-container">
                            <h3>{this.context.t("comentariosTitulo")}</h3>
                            {
                                this.state.comments.map((comment, index) => {
                                    return (
                                        <div className="comment" key={index}>
                                            <p className="nombre">{comment.nombre}</p>
                                            <p className="correo">{comment.correo}</p>
                                            {comment.comentario}
                                            <p className="fecha">{comment.fecha}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        : ''
                }
                <div className="form-comment-container">
                    <h3>{this.context.t("comentariosFormularioTitulo")}</h3>
                    <Comment onSubmit={this.handleSubmit} />
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

ArticleWebContainer.contextTypes = {
    t: PropTypes.func.isRequired
};

export default ArticleWebContainer;
