import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import Swal from 'sweetalert2';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'imagen',
  text: 'Imagen',
  formatter: (cell) => {
    return <img width="100" src={cell} alt="Receta Cerveza Sierra Fría" />;
  }
}, {
  dataField: 'nombre',
  text: 'Nombre',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'categoria',
  text: 'Categoría',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'acciones',
  text: 'Acciones',
  classes: 'row-image'
}];

class RecipesContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      recipesList: []
    };
    this.tableRecipes = this.db.collection('recetas');
  }

  componentDidMount() {
    this.unsubscribe = this.tableRecipes.onSnapshot(this.getRecipesList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getRecipesList = (querySnapshot) => {
    const recipesList = [];
    querySnapshot.forEach((doc) => {
      const { imagen, nombre, categoria, ingredientes, pasos } = doc.data();
      const receta = { key: doc.id, imagen, nombre, categoria, ingredientes, pasos };
      const keyEdit = 'edit' + doc.id;
      const keyDelete = 'delete' + doc.id;
      let acciones = [<button key={keyEdit} className="btn btn-warning" onClick={() => this.editRecipe(receta)}><i className="fa fa-edit"/> Editar</button>,<button key={keyDelete} className="btn btn-danger" onClick={() => this.deleteRecipe(receta.key)}><i className="far fa-trash-alt"/> Eliminar</button>];
      recipesList.push({ key: doc.id, imagen, nombre, categoria, ingredientes, pasos, acciones });
    });
    this.setState({ recipesList });
  };

  addRecipe = () => {
    this.props.history.push('nueva-receta');
  };

  editRecipe = (recipe) => {
    this.props.history.push({
      pathname: '/editar-receta',
      recipe
    });
  };

  deleteRecipe = (key) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás recuperar este elemento.",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then( (result) => {
      if(result.value){
        this.tableRecipes.doc(key).delete().then( () => {
          Swal.fire({
            title: '¡Operación exitosa!',
            text: 'El elemento ha sido eliminado correctamente.',
            icon: 'success',
            confirmButtonText: 'Continuar'
          }).then( () => {});
        }).catch( () => {
          Swal.fire({
            title: '¡Lo sentimos!',
            text: 'Hubo un problema con la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Continuar'
          }).then( () => {});
        }); 
      }
    })
  };

  renderBody(){
    return(
      <div className="categories-container">
        <div className="row clearfix m-b-25">
          <div className="col-md-12 col-sm-12">
            <button className="btn btn-sm btn-success" onClick={() => this.addRecipe()}>Agregar Receta</button>
          </div>
        </div>
        <div className="card">
          <div className="body">
          { this.state.recipesList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Imagen</Th>
                  <Th>Nombre</Th>
                  <Th>Categoría</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.recipesList.map(product => {
                  return(
                    <Tr key={product.key}>
                      <Td><img width="100" src={product.imagen} alt="Receta Cerveza Sierra Fría" /></Td>
                      <Td>{product.nombre}</Td>
                      <Td>{product.categoria}</Td>
                      <Td>{product.acciones}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.recipesList} columns={columns} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <AppFrame title="Recetas" body={this.renderBody()} />
    );
  }
}

export default RecipesContainer;
