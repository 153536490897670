import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/sitio.css';
import './../css/font-awesome.min.css';
import logo from './../img/logo.png';
import { withRouter } from 'react-router-dom';
import firebase from "../../Firebase";

class AppHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      showMenu: '',
      correo: localStorage.getItem('admin_correo')
    }
  }

  handleClickMenu = (route) => {
    this.props.history.push(route);
    this.active = route;
  };
  
  setActiveMenu = (menu) => {
    return menu === this.active ? 'active' : '';
  };

  toggleMenu = (show) => {
    this.setState({ showMenu: show ? 'show-menu' : '' })
  };

  logOut = () => {
    firebase.auth().signOut().then(() => {});
    localStorage.clear();
    this.props.history.push('administrar');
  };

  render() {
    const { location } = this.props;
    this.active = location.pathname.replace('/','');
    return (
      <div className="header-container">
        <nav className="navbar top-navbar">
          <div className="container-fluid">
            <div className="navbar-left">
              <div className="navbar-btn">
                <button onClick={() => this.toggleMenu(true)}><i className="fa fa-bars"/></button>
              </div>
            </div>
            <div className="navbar-right">
              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  <li><button onClick={() => this.logOut()} className="icon-menu"><i className="fas fa-sign-out-alt"/> Cerrar Sesión</button></li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div id="left-sidebar" className={this.state.showMenu + ' sidebar'}>
          <div className="navbar-brand">
            <img src={logo} alt="Cerveza Sierra Fría" />
            <button onClick={() => this.toggleMenu(false)} className="btn-close-menu btn btn-sm float-right"><i className="far fa-times-circle"/></button>
          </div>
          <div className="user-account">
              <div className="user_div"><i className="fas fa-user-tie"/></div>
              <div className="dropdown">
                <small>{this.state.correo}</small>
              </div>                
          </div>  
          <nav id="left-sidebar-nav" className="sidebar-nav">
            <ul id="main-menu" className="metismenu">
              <li className={this.setActiveMenu('panel')}>
                <button onClick={() => this.handleClickMenu('panel')}><i className="fas fa-tachometer-alt"/><span>Panel</span></button>
              </li>
              <li className={this.setActiveMenu('pedidos')}>
                <button onClick={() => this.handleClickMenu('pedidos')}><i className="fa fa-database"/><span>Pedidos</span></button>
              </li>
              <li className={this.setActiveMenu('clientes')}>
                <button onClick={() => this.handleClickMenu('clientes')}><i className="fa fa-users"/><span>Clientes</span></button>
              </li>
              <li className={this.setActiveMenu('productos')}>
                <button onClick={() => this.handleClickMenu('productos')}><i className="fa fa-cubes"/><span>Productos</span></button>
              </li>
              <li className={this.setActiveMenu('categorias-productos')}>
                <button onClick={() => this.handleClickMenu('categorias-productos')}><i className="fas fa-code-branch"/><span>Categorías de Productos</span></button>
              </li>
              <li className={this.setActiveMenu('articulos')}>
                <button onClick={() => this.handleClickMenu('articulos')}><i className="far fa-newspaper"/><span>Artículos</span></button>
              </li>
              <li className={this.setActiveMenu('categorias-articulos')}>
                <button onClick={() => this.handleClickMenu('categorias-articulos')}><i className="far fa-list-alt"/><span>Categorías de Artículos</span></button>
              </li>
              <li className={this.setActiveMenu('lista-recetas')}>
                <button onClick={() => this.handleClickMenu('lista-recetas')}><i className="fas fa-utensils"/><span>Recetas</span></button>
              </li>
              <li className={this.setActiveMenu('lista-tiendas')}>
                <button onClick={() => this.handleClickMenu('lista-tiendas')}><i className="fas fa-store"/><span>Tiendas</span></button>
              </li>
              <li className={this.setActiveMenu('lista-boletin')}>
                <button onClick={() => this.handleClickMenu('lista-boletin')}><i className="fas fa-user-tag"/><span>Suscriptores al boletín</span></button>
              </li>
              <li className={this.setActiveMenu('visitantes')}>
                <button onClick={() => this.handleClickMenu('visitantes')}><i className="fas fa-user-check"/><span>Visitantes</span></button>
              </li>
              <li className={this.setActiveMenu('mensajes-contacto')}>
                <button onClick={() => this.handleClickMenu('mensajes-contacto')}><i className="fa fa-envelope"/><span>Contacto</span></button>
              </li>
            </ul>
          </nav>     
        </div>
      </div> 
    );
  }
}

export default withRouter(AppHeader);
