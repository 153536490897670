import React, {Component} from 'react';
import AppFrame from "../../components/AppFrame";
import firebase from './../../../Firebase';
import Swal from 'sweetalert2';
import {textFilter} from 'react-bootstrap-table2-filter';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
    dataField: 'fecha',
    text: 'Fecha',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'titulo',
    text: 'Título',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'categoria',
    text: 'Categoría',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'acciones',
    text: 'Acciones',
    classes: 'row-flex-wrap'
}];

class ArticlesContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.state = {
            articlesList: []
        };
        this.tableArticles = this.db.collection('articulos');
    }

    componentDidMount() {
        this.unsubscribe = this.tableArticles.onSnapshot(this.getArticlesList);
    }

    componentWillUnmount(){
        this.unsubscribe && this.unsubscribe();
    }

    getArticlesList = (querySnapshot) => {
        const articlesList = [];
        const options = {dateStyle: "long",timeStyle: "short",timeZone: "America/Mexico_City"};
        querySnapshot.forEach((doc) => {
            const { fecha, titulo, categoria, portada, contenido, comentarios } = doc.data();
            const timestamp = fecha.seconds * 1000;
            const date = new Intl.DateTimeFormat('es-MX', options).format(timestamp);
            let comments = [];
            if(comentarios !== undefined){
                comentarios.forEach( comment => {
                    const ts = comment.fecha.seconds * 1000;
                    comment.fecha = new Intl.DateTimeFormat('es-MX', options).format(ts);
                    comments.push(comment);
                });
            }
            const article = { key: doc.id, fecha: date, titulo, categoria, portada, contenido, comentarios: comments };
            const keyEdit = 'edit' + doc.id;
            const keyDelete = 'delete' + doc.id;
            let acciones;
            if(article.comentarios !== undefined && article.comentarios.length > 0){
                const keyComments = 'comments' + doc.id;
                acciones = [
                    <button key={keyEdit} className="btn btn-warning" onClick={() => this.editArticle(article)}><i className="fa fa-edit"/> Editar</button>,
                    <button key={keyDelete} className="btn btn-danger" onClick={() => this.deleteArticle(article.key)}><i className="far fa-trash-alt"/> Eliminar</button>,
                    <button key={keyComments} className="btn btn-primary" onClick={() => this.viewComments(article)}><i className="far fa-comments"/> Comentarios</button>
                ];
            }else{
                acciones = [
                    <button key={keyEdit} className="btn btn-warning" onClick={() => this.editArticle(article)}><i className="fa fa-edit"/> Editar</button>,
                    <button key={keyDelete} className="btn btn-danger" onClick={() => this.deleteArticle(article.key)}><i className="far fa-trash-alt"/> Eliminar</button>
                ];
            }
            articlesList.push({ key: doc.id, fecha: date, titulo, categoria, portada, contenido, comentarios: comments, acciones });
            this.setState({ articlesList });
        });
    }

    addArticle = () => {
        this.props.history.push('nuevo-articulo');
    }

    viewComments = (article) => {
        this.props.history.push({
            pathname: '/ver-comentarios',
            article
        });
    }

    editArticle = (article) => {
        this.props.history.push({
            pathname: '/editar-articulo',
            article
        });
    }

    deleteArticle = (key) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás recuperar este elemento.",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then( (result) => {
            if(result.value){
                this.tableArticles.doc(key).delete().then( () => {
                    Swal.fire({
                        title: '¡Operación exitosa!',
                        text: 'El elemento ha sido eliminado correctamente.',
                        icon: 'success',
                        confirmButtonText: 'Continuar'
                    }).then(()=>{});
                }).catch( () => {
                    Swal.fire({
                        title: '¡Lo sentimos!',
                        text: 'Hubo un problema con la base de datos.',
                        icon: 'warning',
                        confirmButtonText: 'Continuar'
                    }).then(()=>{});
                });
            }
        })
    }

    renderBody(){
        return(
            <div className="category-article-container">
                <div className="row clearfix m-b-25">
                    <div className="col-md-12 col-sm-12">
                        <button className="btn btn-sm btn-success" onClick={() => this.addArticle()}>Agregar Artículo</button>
                    </div>
                </div>
                <div className="card">
                    <div className="body">
                        { this.state.articlesList.length > 0 ? <Table className="table-admin">
                            <Thead>
                                <Tr>
                                    <Th>Fecha</Th>
                                    <Th>Título</Th>
                                    <Th>Categoría</Th>
                                    <Th>Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                { this.state.articlesList.map(article => {
                                    return(
                                        <Tr key={article.key}>
                                            <Td>{article.fecha}</Td>
                                            <Td>{article.titulo}</Td>
                                            <Td>{article.categoria}</Td>
                                            <Td>{article.acciones}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
                        <TableDesktop data={this.state.articlesList} columns={columns} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame title="Artículos" body={this.renderBody()} />
        );
    }
}

export default ArticlesContainer;
