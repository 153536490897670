import React from 'react';
import firebase from '../../Firebase';
import {Redirect, Route} from "react-router-dom";

const ProtectedRoute = ({component: Component, ...rest}) => {
    let isLoggedIn = false;
    firebase.auth().onAuthStateChanged(function (user) {
        isLoggedIn = user;
    });
    return (
        <Route
            {...rest}
            render = { props =>
                isLoggedIn ? ( <Component {...props} /> ) : ( <Redirect to={{ pathname: '/', state: { from: props.location } }} /> )
            }
        />
    );
};

export default ProtectedRoute;