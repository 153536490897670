import React, {Component} from 'react';
import AppFrame from './../../components/AppFrame';
import firebase from './../../../Firebase';
import {storage} from '../../../Firebase';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {toggleLoader, updateProgress} from '../../../actions/loaderActions';
import EditProduct from '../../components/products/EditProduct';

class EditProductContainer extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.state = {
            categoriesList: []
        };
        this.tableProducts = this.db.collection('productos');
        this.tableCategories = this.db.collection('categorias-productos');
    }

    componentDidMount() {
        this.unsubscribe = this.tableCategories.onSnapshot(this.getCategoriesList);
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    getCategoriesList = (querySnapshot) => {
        const categoriesList = [];
        querySnapshot.forEach((doc) => {
            const {nombre} = doc.data();
            this.db.collection('productos').where('categoria', '==', nombre).get().then(() => {
                categoriesList.push({key: doc.id, nombre});
                this.setState({categoriesList});
            });
        });
    };

    handleSubmit = (values) => {
        const imagen = this.props.photo;
        if (imagen != null) {
          window.scrollTo(0, 0);
          this.props.toggleLoader(true);
          storage.ref(imagen.name).put(imagen).on('state_changed',
              snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.props.updateProgress(progress + '%');
              }, () => {
              }, () => {
                this.props.toggleLoader(false);
                storage.ref(imagen.name).getDownloadURL().then(url_imagen => {
                  const {id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor} = values;
                  let alcohol = Number(alcVol);
                  let amargor = Number(amargorIBUS);
                  let contenidoML = Number(contenido);
                  let inventario = Number(disponibilidad);
                  let precioNumber = Number(precio);
                  this.tableProducts.doc(id).update({
                    alcVol: alcohol,
                    amargorIBUS: amargor,
                    aromas,
                    aspecto,
                    categoria,
                    contenido: contenidoML,
                    disponibilidad: inventario,
                    maridaje,
                    nombre,
                    precio: precioNumber,
                    sabor,
                    imagen: url_imagen
                  }).then(() => {
                    Swal.fire({
                      title: '¡Operación exitosa!',
                      text: 'El elemento ha sido guardado correctamente.',
                      icon: 'success',
                      confirmButtonText: 'Continuar'
                    }).then(() => {
                      //Close alert
                    });
                    this.props.history.push('productos');
                  }).catch(() => {
                    Swal.fire({
                      title: '¡Lo sentimos!',
                      text: 'Hubo un problema con la base de datos.',
                      icon: 'warning',
                      confirmButtonText: 'Continuar'
                    }).then(() => {
                      //Close alert
                    });
                  });
                });
              }
          );
        } else{
            const {id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor} = values;
            let alcohol = Number(alcVol);
            let amargor = Number(amargorIBUS);
            let contenidoML = Number(contenido);
            let inventario = Number(disponibilidad);
            let precioNumber = Number(precio);
            this.tableProducts.doc(id).update({
                alcVol: alcohol,
                amargorIBUS: amargor,
                aromas,
                aspecto,
                categoria,
                contenido: contenidoML,
                disponibilidad: inventario,
                maridaje,
                nombre,
                precio: precioNumber,
                sabor
            }).then(() => {
                Swal.fire({
                    title: '¡Operación exitosa!',
                    text: 'El elemento ha sido guardado correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Continuar'
                }).then(() => {
                    //Close alert
                });
                this.props.history.push('productos');
            }).catch(() => {
                Swal.fire({
                    title: '¡Lo sentimos!',
                    text: 'Hubo un problema con la base de datos.',
                    icon: 'warning',
                    confirmButtonText: 'Continuar'
                }).then(() => {
                    //Close alert
                });
            });
        }
    };

    renderBody() {
        return (
            <EditProduct onSubmit={this.handleSubmit} data={this.props.location.product}
                         categorias={this.state.categoriesList}/>
        );
    }

    render() {
        return (
            <AppFrame title="Editar Producto" body={this.renderBody()}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        photo: state.photo.activeImage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLoader: (loading) => {
            dispatch(toggleLoader(loading))
        },
        updateProgress: (progress) => {
            dispatch(updateProgress(progress))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProductContainer);
