import React, {Component} from 'react';
import Swal from "sweetalert2";
import AppFrame from "../../components/AppFrame";
import firebase from './../../../Firebase';
import NewCategory from "../../components/category/NewCategory";

class NewCategoryArticle extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.tableCategories = this.db.collection('categorias-articulos');
    }

    handleSubmit = (values) => {
        const {nombre} = values;
        this.tableCategories.add({nombre}).then(() => {
            Swal.fire({
                title: '¡Operación exitosa!',
                text: 'El elemento ha sido agregado correctamente.',
                icon: 'success',
                confirmButtonText: 'Continuar'
            }).then(() => {
            });
            this.props.history.push('categorias-articulos');
        }).catch(() => {
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Hubo un problema con la base de datos.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then(() => {
            });
        });
    }

    renderBody() {
        return (
            <NewCategory onSubmit={this.handleSubmit}/>
        );
    }

    render() {
        return (
            <AppFrame title="Nueva Categoría" body={this.renderBody()}/>
        );
    }
}


export default NewCategoryArticle;
