import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

class PromotionWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowSubmit: true
    };
  }

  renderField = ({input, type, placeholder }) => (
    <div className="form-group">
      <input className="form-control" {...input}  type={!type ? 'text' : type} placeholder={placeholder} required />
    </div>
  );

  onChange = (value) => {
    if(value !== ''){
      this.setState({allowSubmit: false});
    }
  }

  render() {
    const {handleSubmit} = this.props;
    return (
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <Field name="correo" component={this.renderField} type="email" placeholder={this.context.t("promotionCorreo")} />
          <div className="captcha">
            <ReCAPTCHA
                sitekey="6LdUc-0UAAAAAHpY0hK9gvUt8lXFzCfSt2j654sc"
                onChange={this.onChange}
                theme="light"
            />
          </div>
          <button type="submit" disabled={this.state.allowSubmit} >{this.context.t("promotionSuscribirse")}</button>
        </form>
      </div>
    );
  }
}

const PromotionWebForm = reduxForm({ 
  form: 'PromotionWebForm'
})(PromotionWeb);

PromotionWeb.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PromotionWebForm;
