import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

class AppFrame extends Component {
  render() {
    const { body } = this.props;
    return (
      <div className="body-container">
        <AppHeader/>
        {body}
        <AppFooter/>
      </div>
    );
  }
}

AppFrame.propTypes = {
  body: PropTypes.element.isRequired,
};

export default AppFrame;