import React, {Component} from 'react';
import firebase, {storage} from "../../../Firebase";
import Swal from "sweetalert2";
import AppFrame from "../../components/AppFrame";
import {toggleLoader, updateProgress} from "../../../actions/loaderActions";
import {connect} from "react-redux";
import EditArticleForm from "../../components/articles/EditArticle";
import {setActiveContent} from "../../../actions/textEditorActions";

class EditArticle extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.state = {
            categoriesList: []
        };
        this.tableArticles = this.db.collection('articulos');
        this.tableCategories = this.db.collection('categorias-articulos');
        this.props.setActiveContent(this.props.location.article.contenido);
    }

    componentDidMount() {
        this.unsubscribe = this.tableCategories.onSnapshot(this.getCategoriesList);
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    getCategoriesList = (querySnapshot) => {
        const categoriesList = [];
        querySnapshot.forEach((doc) => {
            const {nombre} = doc.data();
            categoriesList.push({key: doc.id, nombre});
            this.setState({categoriesList});
        });
    };

    handleSubmit = (values) => {
        const imagen = this.props.photo;
        if(imagen != null) {
            window.scrollTo(0, 0);
            this.props.toggleLoader(true);
            storage.ref(imagen.name).put(imagen).on('state_changed',
                snapshot => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.props.updateProgress(progress + '%');
                }, () => {
                }, () => {
                    this.props.toggleLoader(false);
                    storage.ref(imagen.name).getDownloadURL().then(url => {
                        const {id, titulo, categoria} = values;
                        const contenido = this.props.content;
                        this.tableArticles.doc(id).update({portada:url, titulo, categoria, contenido}).then( () => {
                            Swal.fire({
                                title: '¡Operación exitosa!',
                                text: 'El elemento ha sido guardado correctamente.',
                                icon: 'success',
                                confirmButtonText: 'Continuar'
                            }).then(()=>{});
                            this.props.history.push('articulos');
                        }).catch( () => {
                            Swal.fire({
                                title: '¡Lo sentimos!',
                                text: 'Hubo un problema con la base de datos.',
                                icon: 'warning',
                                confirmButtonText: 'Continuar'
                            }).then(()=>{});
                        });
                    });
                }
            );
        }else{
            const {id, titulo, categoria} = values;
            const contenido = this.props.content;
            this.tableArticles.doc(id).update({titulo, categoria, contenido}).then( () => {
                Swal.fire({
                    title: '¡Operación exitosa!',
                    text: 'El elemento ha sido guardado correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Continuar'
                }).then(()=>{});
                this.props.history.push('articulos');
            }).catch( () => {
                Swal.fire({
                    title: '¡Lo sentimos!',
                    text: 'Hubo un problema con la base de datos.',
                    icon: 'warning',
                    confirmButtonText: 'Continuar'
                }).then(()=>{});
            });
        }
    }

    renderBody(){
        return (
            <EditArticleForm onSubmit={this.handleSubmit} data={this.props.location.article} categorias={this.state.categoriesList} />
        );
    }

    render() {
        return (
            <AppFrame title="Editar Artículo" body={this.renderBody()} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        photo: state.photo.activeImage,
        content: state.editor.activeContent
    }
};

const mapDispatchToProps= (dispatch)=>{
    return{
        toggleLoader: (loading) => {
            dispatch(toggleLoader(loading))
        },
        updateProgress: (progress) => {
            dispatch(updateProgress(progress))
        },
        setActiveContent: (content) => {
            dispatch(setActiveContent(content))
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(EditArticle);
