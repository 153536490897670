import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

const validate = values => {
  const error = {};
  if(!values.nombre){
  error.nombre = 'Escribe el nombre por favor.';
  }
  return error;
};

class NewCategory extends Component {
  renderField = ({input, meta, type, label, name, placeholder }) => (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );

  render() {
    const {handleSubmit, submitting, pristine } = this.props;
    return (
      <div className="card">
        <div className="body">
          <div className="form-container m-b-30">
            <form onSubmit={handleSubmit}>
              <Field name="nombre" label="Nombre" component={this.renderField} type="text" placeholder="Nombre" />
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Crear</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const NewCategoryForm = reduxForm({ 
  form: 'NewCategoryForm',
  validate
})(NewCategory);

export default NewCategoryForm;