import React, {Component} from 'react';
import {change, Field, reduxForm} from "redux-form";
import {setActiveImage} from "../../../actions/photoActions";
import {connect} from "react-redux";
import renderTextEditor from "./renderTextEditor";

export const validate = values => {
    const error = {};
    if(!values.titulo){
        error.titulo = 'Escribe el título por favor.';
    }
    if(!values.categoria){
        error.categoria = 'Selecciona la categoría por favor.';
    }
    return error;
};

class EditArticle extends Component {
    constructor(props){
        super(props);
        this.state = {
            preview: null
        };
    }

    componentDidMount() {
        const { data } = this.props;
        this.props.initialize({ id: data.key });
        this.props.dispatch(change('EditArticleForm', 'titulo', data.titulo));
        this.props.dispatch(change('EditArticleForm', 'categoria', data.categoria));
        this.setState({ preview: data.portada });
    }

    renderSelect = ({input, meta, label, name, empty, options }) => (
        <div className="form-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <select className="form-control" {...input} disabled={options.length === 0}>
                <option value="">{empty}</option>
                {options.length > 0 && options.map(element => {
                    return (<option key={element.key} value={element.nombre}>{element.nombre}</option>);
                })}
            </select>
            { meta.touched && meta.error && <span>{meta.error}</span> }
        </div>
    );

    renderField = ({input, meta, type, label, name, placeholder, autocomplete }) => (
        type === 'hidden' ?
            <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={autocomplete} />
            :
            <div className="form-group">
                <label className="control-label" htmlFor={name}>{label}</label>
                <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={autocomplete} />
                { meta.touched && meta.error && <span>{meta.error}</span> }
            </div>
    );

    handleChange = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const preview = URL.createObjectURL(e.target.files[0]);
            this.props.setActiveImage(image);
            this.setState({ preview });
        }
    };

    render() {
        const {handleSubmit, submitting, pristine, categorias } = this.props;
        return (
            <div className="card">
                <div className="body">
                    <div className="form-container m-b-30">
                        <form onSubmit={handleSubmit}>
                            <Field name="titulo" label="Título" component={this.renderField} type="text" placeholder="Título" />
                            <Field name="categoria" label="Categoría" component={this.renderSelect} options={categorias} empty="Selecciona una categoría..." />
                            <div className="form-group">
                                <label className="control-label d-block">Portada</label>
                                { this.state.preview ? <img className="preview-photo" src={this.state.preview} alt="Archipedia" /> : '' }
                                <label className="label-file-upload">Seleccionar una foto...
                                    <input type="file" id="file-upload" onChange={this.handleChange} />
                                </label>
                            </div>
                            <Field name="contenido" component={renderTextEditor} />
                            <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Guardar</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const EditArticleForm = reduxForm({
    form: 'EditArticleForm',
    validate
})(EditArticle);

const mapStateToProps = (state) => {
    return {
        initialValues: {
            contenido: state.editor.activeContent
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return{
        setActiveImage: (image) => {
            dispatch(setActiveImage(image))
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(EditArticleForm);
