import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';
import firebase from './../../../Firebase';
import { storage } from '../../../Firebase';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { toggleLoader,updateProgress } from '../../../actions/loaderActions';
import NewRecipe from '../../components/recipes/NewRecipe';

class NewRecipeContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.tableRecipes = this.db.collection('recetas');
  }

  handleSubmit = (values) => {
    const imagen = this.props.photo;
    if(imagen != null){
      window.scrollTo(0, 0);
      this.props.toggleLoader(true);
      storage.ref(imagen.name).put(imagen).on('state_changed',
        snapshot => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.props.updateProgress(progress+'%');
        },() => {},() => {
          this.props.toggleLoader(false);
          storage.ref(imagen.name).getDownloadURL().then(url => {
            const { nombre, categoria, ingredientes, pasos } = values;
            this.tableRecipes.add({ nombre, categoria, ingredientes, pasos, imagen: url }).then( () => {
              Swal.fire({
                title: '¡Operación exitosa!',
                text: 'El elemento ha sido agregado correctamente.',
                icon: 'success',
                confirmButtonText: 'Continuar'
              }).then(()=>{});
              this.props.history.push('lista-recetas');
            }).catch( () => {
              Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Hubo un problema con la base de datos.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
              }).then(()=>{});
            }); 
          });
        }
      );
    }else{
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'Debes seleccionar una foto para la receta.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      }).then(()=>{});
    }
  };

  renderBody(){
    return (
      <NewRecipe onSubmit={this.handleSubmit} />
    );
  }

  render() {
    return (
      <AppFrame title="Nueva Receta" body={this.renderBody()} />
    );
  }
}

const mapStateToProps = (state)=>{
  return {
    photo: state.photo.activeImage
  }
};

const mapDispatchToProps= (dispatch)=>{
  return{
    toggleLoader: (loading) => {
      dispatch(toggleLoader(loading))
    },
    updateProgress: (progress) => {
      dispatch(updateProgress(progress))
    }
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(NewRecipeContainer);
