import React, { Component } from 'react';
import Login from './../../components/login/Login';
import firebase from './../../../Firebase';
import Swal from 'sweetalert2';
import logo from './../../img/logo.png';

class LoginContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
  }

  handleSubmit = (values) => {
    const email = values.correo;
    const password = values.contrasenia;
    firebase.auth().signInWithEmailAndPassword(email, password).then( res => {
      localStorage.setItem('admin_correo', res.user.email);
      localStorage.setItem('admin_id', res.user.uid);
      this.props.history.push('/panel');
    }).catch( () => {
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'La contraseña es incorrecta o el correo no existe.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      }).then( () => {});
    });
  };

  render() {
    return (
      <div className="auth-main">
        <div className="auth_div">
          <div className="auth_brand">
            <span className="navbar-brand">
              <img src={logo} alt="Cerveza Sierra Fría" />
            </span>
          </div>
          <div className="card">
            <div className="body">
              <Login onSubmit={this.handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginContainer;