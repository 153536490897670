import React from 'react';

const PanelTile = ({ icon, title, number, price }) => {
  return (
    <div className="col-lg-3 col-md-6">
      <div className="card">
        <div className="body">
          <div className="d-flex align-items-center">
            <div className="icon-in-bg bg-orange text-white rounded-circle"><i className={icon}></i></div>
            <div className="ml-4">
              <span>{title}</span>
              <h4 className="mb-0 font-weight-medium">{price}{new Intl.NumberFormat().format(number)}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelTile;