import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppHeader from './AppHeader';
import { connect } from 'react-redux';

class AppFrame extends Component {
  render() {
    const { title, body, classLoader,progress } = this.props;
    return (
      <div id="wrapper">
        <div className={classLoader}>
          <div className="progress-container">
            <h5>Espera un momento por favor, estamos guardando tu información.</h5>
            <div className="progress-bar" style={{width: progress}}/>
          </div>
        </div>
        <AppHeader/>
        <div id="main-content">
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-md-12 col-sm-12">
                  <h1>{title}</h1>
                </div>   
              </div>
            </div>
            {body}
          </div>
        </div>
      </div>
    );
  }
}

AppFrame.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
};

const mapStateToProps = (state)=>{
  return {
    classLoader: state.loader.classLoader,
    progress: state.loader.progress
  }
};

export default connect(mapStateToProps)(AppFrame);