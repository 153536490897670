import React from 'react';

const PanelTable = ({ title, orders }) => {
  const options = {dateStyle: "long",timeStyle: "short",timeZone: "America/Mexico_City"};
  return (
    <div className="card user_statistics">
      <div className="header">
        <h2>{title}</h2>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-custom spacing5 text-center">
          <thead>
            <tr> 
              <th>Fecha</th>
              <th>Cliente</th>
              <th>Total</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {
              orders.map((pedido) => {
                const timestamp = pedido.fecha.seconds * 1000;
                const fecha = new Intl.DateTimeFormat('es-MX', options).format(timestamp);
                return (<tr key={pedido.key}>
                  <td>{fecha}</td>
                  <td>{pedido.cliente}</td>
                  <td>${pedido.total.toFixed(2)}</td>
                  <td><span className="badge">{pedido.estado}</span></td>
                </tr>);
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PanelTable;