import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { setActiveImage } from '../../../actions/photoActions';
import { validate } from "./validate";

class NewProduct extends Component {
  constructor(props){
    super(props);
    this.state = { 
      preview: null
    };
  }

  renderField = ({input, meta, type, label, name, placeholder }) => (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );

  renderSelect = ({input, meta, label, name, empty, options }) => (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <select className="form-control" {...input} disabled={options.length === 0}>
        <option value="">{empty}</option>
        {options.length > 0 && options.map(element => {
          return (<option key={element.key} value={element.nombre}>{element.nombre}</option>);
        })}
      </select>
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );
  
  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      const preview = URL.createObjectURL(e.target.files[0]);
      this.props.setActiveImage(image);
      this.setState({ preview });
    }
  };

  render() {
    const {handleSubmit, submitting, pristine, categorias } = this.props;
    return (
      <div className="card">
        <div className="body">
          <div className="form-container m-b-30">
            <form onSubmit={handleSubmit}>
              <Field name="nombre" label="Nombre" component={this.renderField} type="text" placeholder="Nombre" />
              <Field name="precio" label="Precio" component={this.renderField} type="number" placeholder="Precio" />
              <div className="form-group">
                <label className="control-label d-block">Foto del producto</label>
                { this.state.preview ? <img className="preview-photo" src={this.state.preview} alt="Cerveza Sierra Fría" /> : '' }
                <label className="label-file-upload">Seleccionar una foto...
                  <input type="file" id="file-upload" onChange={this.handleChange} />
                </label>
              </div>
              <Field name="contenido" label="Contenido" component={this.renderField} type="number" placeholder="Contenido" />
              <Field name="alcVol" label="Alc./Vol." component={this.renderField} type="number" placeholder="Alc./Vol." />
              <Field name="amargorIBUS" label="Amargor IBUS" component={this.renderField} type="number" placeholder="Amargor IBUS" />
              <Field name="disponibilidad" label="Disponibilidad" component={this.renderField} type="number" placeholder="Disponibilidad" />
              <Field name="categoria" label="Categoría" component={this.renderSelect} options={categorias} empty="Selecciona una categoría..." />
              <Field name="aromas" label="Aromas" component={this.renderField} type="text" placeholder="Aromas" />
              <Field name="aspecto" label="Aspecto" component={this.renderField} type="text" placeholder="Aspecto" />
              <Field name="maridaje" label="Maridaje" component={this.renderField} type="text" placeholder="Maridaje" />
              <Field name="sabor" label="Sabor" component={this.renderField} type="text" placeholder="Sabor" />
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Crear</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const NewProductForm = reduxForm({ 
  form: 'NewProductForm',
  validate
})(NewProduct);

const mapDispatchToProps = (dispatch) => {
  return{
    setActiveImage: (image) => {
      dispatch(setActiveImage(image))
    }
  }
};

export default connect(null,mapDispatchToProps)(NewProductForm);
