import React from 'react';
import { Chart } from 'react-charts';

const series = ({ type: 'bar' });
const axes = [ { primary: true, type: 'ordinal', position: 'bottom' }, { type: 'linear', position: 'left', stacked: false } ];

const PanelGraphic = ({ title, info }) => {
  const data = [{ label: 'Pedidos del Mes', data: info }];
  return (
    <div className="card user_statistics">
      <div className="header">
        <h2>{title}</h2>
      </div>
      <div className="body">
        <div className="chart-container">                            
          { info.length > 0 ? <Chart data={data} series={series} axes={axes} tooltip /> : '' }
        </div>
      </div>
    </div>
  );
};

export default PanelGraphic;