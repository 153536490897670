import React, { Component } from 'react';
import AppFrame from '../components/AppFrame';
import firebase from '../../Firebase';
import PropTypes from "prop-types";

class ProductsWebContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      productsList: []
    };
    this.tableProducts = this.db.collection('productos');
  }

  componentDidMount() {
    this.unsubscribe = this.tableProducts.onSnapshot(this.getProductsList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getProductsList = (querySnapshot) => {
    const productsList = [];
    querySnapshot.forEach((doc) => {
      const { alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen } = doc.data();
      productsList.push({ key: doc.id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen });
    });
    this.setState({ productsList });
  };

  viewProduct = (product) => {
    this.props.history.push({
      pathname: '/ver-producto',
      search: '?productoId='+product.key
    })
  };

  renderBody(){
    return(
      <div className="products-web-container">
        <div className="qodef-title-holder products-web-image">
          <div className="qodef-title-wrapper">
            <div className="qodef-title-inner">
              <div className="qodef-grid">
                <h1 className="qodef-page-title">{this.context.t("ProductosTitulo")}</h1>
                <span className="qodef-page-subtitle">{this.context.t("ProductosSubtitulo")}</span>
              </div>
            </div>
          </div>
        </div>
        <section id="products-web-list">
          <div className="list-products">
            {
                this.state.productsList.length > 0 && this.state.productsList.map(product => {
                  return(
                      <div className="product" key={product.key} onClick={() => this.viewProduct(product)}>
                        <div className="qodef-pl-image">
                          <img src={product.imagen} alt={product.nombre} />
                        </div>
                        <div className="qodef-pl-text-wrapper">
                          <h6 className="qodef-product-list-title">{product.nombre}</h6>
                          <span className="price">${product.precio.toFixed(2)} MXN</span>
                        </div>
                        <p className="mb-0"><strong>{this.context.t("ProductosContenido")}:</strong> {product.contenido} ml</p>
                        <p className="mb-0"><strong>{this.context.t("ProductosAlcohol")}:</strong> {product.alcVol}%</p>
                        <p className="qodef-pl-category">{product.categoria}</p>
                      </div>
                  )
                })
            }
          </div>
        </section>
      </div>
    );
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

ProductsWebContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ProductsWebContainer;
