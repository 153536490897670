import React, {Component} from 'react';
import {Field, reduxForm} from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowSubmit: true,
            recaptchaRef: React.createRef()
        };
    }

    renderField = ({input, type, placeholder}) => (
        <div className="form-group">
            <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} required/>
        </div>
    );

    renderTextArea = ({input, placeholder}) => (
        <div className="form-group">
            <textarea className="form-control" {...input} placeholder={placeholder} rows="5" required/>
        </div>
    );

    onChange = (value) => {
        if (value !== '' && value !== undefined) {
            this.setState({allowSubmit: false});
        }
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className="site-comment-form-container">
                <form onSubmit={handleSubmit}>
                    <Field name="nombre" component={this.renderField} type="text" placeholder={this.context.t("comentarioNombre")}/>
                    <Field name="correo" component={this.renderField} type="email" placeholder={this.context.t("comentarioCorreo")}/>
                    <Field name="comentario" component={this.renderTextArea} placeholder={this.context.t("comentarioMensaje")}/>
                    <div className="captcha">
                        <ReCAPTCHA
                            sitekey="6LdUc-0UAAAAAHpY0hK9gvUt8lXFzCfSt2j654sc"
                            ref={this.state.recaptchaRef}
                            onChange={this.onChange}
                            theme="light"
                        />
                    </div>
                    <button type="submit" disabled={this.state.allowSubmit}>{this.context.t("comentarioEnviar")}</button>
                </form>
            </div>
        );
    }
}

const CommentForm = reduxForm({
    form: 'CommentForm'
})(Comment);

Comment.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = (state)=>{
    return {
        lang: state.i18nState.lang
    }
};

export default connect(mapStateToProps,null)(CommentForm);
