import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {i18nState} from 'redux-i18n';
import Music from './Music';
import Photo from './Photo';
import Loader from './Loader';
import MobileMenu from './MobileMenu';
import TextEditor from "./TextEditor";

export default combineReducers({
  form: formReducer,
  i18nState,
  music: Music,
  photo: Photo,
  loader: Loader,
  menu: MobileMenu,
  editor: TextEditor
});
