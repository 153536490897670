import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

const validate = values => {
  const error = {};
  if(!values.nombre){
    error.nombre = 'Escribe el nombre por favor.';
  }
  if(!values.direccion){
    error.direccion = 'Escribe la dirección por favor.';
  }
  if(!values.latitud){
    error.latitud = 'Escribe la latitud por favor.';
  }
  if(!values.longitud){
    error.longitud = 'Escribe la longitud por favor.';
  }
  return error;
};

class NewStore extends Component {
  renderField = ({input, meta, type, label, name, placeholder }) => (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );

  render() {
    const {handleSubmit, submitting, pristine } = this.props;
    return (
      <div className="card">
        <div className="body">
          <div className="form-container m-b-30">
            <form onSubmit={handleSubmit}>
              <Field name="nombre" label="Nombre" component={this.renderField} type="text" placeholder="Nombre" />
              <Field name="direccion" label="Dirección" component={this.renderField} type="text" placeholder="Dirección" />
              <Field name="latitud" label="Latitud" component={this.renderField} type="number" placeholder="Latitud" />
              <Field name="longitud" label="Longitud" component={this.renderField} type="number" placeholder="Longitud" />
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Crear</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const NewStoreForm = reduxForm({ 
  form: 'NewStoreForm',
  validate
})(NewStore);

export default NewStoreForm;