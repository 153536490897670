import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import firebase from '../../Firebase';
import PropTypes from "prop-types";

class LoginWebContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.tableClients = this.db.collection('clientes');
    }

    signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
            firebase.auth().signInWithPopup(provider).then(result => {
                let name = result.user.displayName;
                let email = result.user.email;
                this.logIn(name,email);
            }).catch(e => {})
        })
    };

    signInWithFacebook = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
            firebase.auth().signInWithPopup(provider).then(result => {
                let name = result.user.displayName;
                let email = result.additionalUserInfo.profile.email;
                this.logIn(name,email);
            }).catch(e => {})
        })
    };

    logIn(name,email){
        this.db.collection('clientes').where("correo", "==", email).get().then( (querySnapshot) => {
            if(querySnapshot.docs[0]){
                localStorage.setItem('clientName', name);
                localStorage.setItem('clientEmail', email);
                localStorage.setItem('clientId', querySnapshot.docs[0].id);
                this.props.history.push('mis-pedidos');
            }else{
                const fechaRegistro = new Date();
                this.tableClients.add({
                    correo: email,
                    nombre: name,
                    fechaRegistro
                }).then( (doc) => {
                    localStorage.setItem('clientName', name);
                    localStorage.setItem('clientEmail', email);
                    localStorage.setItem('clientId', doc.id);
                    this.props.history.push('mis-pedidos');
                }).catch(() => {
                    //Error with database
                });
            }
        });
    }

    renderBody() {
        return (
            <div className="login_container">
                <div className="image"/>
                <div className="card">
                    <div className="body">
                        <h1 className="titulo">{this.context.t("LoginTitulo")}</h1>
                        <p className="subtitulo">{this.context.t("LoginSubtitulo")}</p>
                        <div className="botones">
                            <div className="google login-btn" onClick={() => this.signInWithGoogle()}><i
                                className="fab fa-google"/> {this.context.t("LoginGoogle")}
                            </div>
                            <div className="facebook login-btn" onClick={() => this.signInWithFacebook()}><i
                                className="fab fa-facebook-f"/> {this.context.t("LoginFacebook")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

LoginWebContainer.contextTypes = {
    t: PropTypes.func.isRequired
};

export default LoginWebContainer;
