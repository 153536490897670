import React, { Component } from 'react';
import AppFrame from '../components/AppFrame';
import Swal from 'sweetalert2';
import PropTypes from "prop-types";
import firebase from "../../Firebase";

class ViewProductWebContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = {
      quantity: 6,
      product: null
    }
    this.tableProducts = this.db.collection('productos');
    const params = new URLSearchParams(this.props.location.search);
    this.getProduct(params.get('productoId'));
  }

  lessQuantity = () => {
    let quantity = this.state.quantity - 6;
    if(quantity < 6){
      quantity = 6;
    }
    this.setState({ quantity });
  };

  moreQuantity = () => {
    const quantity = this.state.quantity + 6;
    this.setState({ quantity });
  };

  addToCart = (id,name, price, inventory) => {
    const product = {
      id,
      nombre: name,
      precio: price,
      disponibilidad: inventory,
      cantidad: this.state.quantity,
      total: price * this.state.quantity
    };
    // Lista de Productos
    let arrProducts = localStorage.getItem('arrProducts') ? JSON.parse(localStorage.getItem('arrProducts')) : [];
    let productIsInCart = false;
    arrProducts.map(element => {
      if(element.id === product.id){
        productIsInCart = true;
        element.cantidad += product.cantidad;
        element.total += product.total;
      }
      return true;
    });
    if(!productIsInCart){
      arrProducts.push(product);
    }
    localStorage.setItem('arrProducts',JSON.stringify(Array.from(arrProducts)));
    // Total de Productos
    let totalProductos = localStorage.getItem('totalProductos') ? Number(localStorage.getItem('totalProductos')) : 0;
    totalProductos += product.cantidad;
    localStorage.setItem('totalProductos',totalProductos);
    // Total
    let total = localStorage.getItem('total') ? Number(localStorage.getItem('total')) : 0;
    total += product.total;
    // Descuento si compran en múltiplos de 24
    if(totalProductos % 24 === 0){
      total = total * 0.9;
    }
    localStorage.setItem('total',total);
    Swal.fire({
      title: this.context.t("ModalGracias"),
      text:  this.context.t("ViewProductModalExito"),
      icon: 'success',
      confirmButtonText: this.context.t("ModalBotonContinuar")
    }).then( () => {
      window.location.reload();
    });
  };

  getProduct = (id) => {
    this.tableProducts.doc(id).get().then( doc => {
      if(doc.exists){
        const { alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen } = doc.data();
        const product = { key: doc.id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen };
        this.setState({ product });
      }
    });
  }

  renderBody(){
    if(this.state.product != null) {
      return (
          <div className="view-product-container">
            <div className="qodef-single-product-content">
              <div className="product-image">
                <img src={this.state.product.imagen} alt="Cerveza Sierra Fría"/>
              </div>
              <div className="qodef-single-product-summary">
                <div className="summary">
                  <h3 className="qodef-single-product-title">{this.state.product.nombre}</h3>
                  <p className="price">${this.state.product.precio.toFixed(2)} MXN</p>
                  <div className="cart">
                    <div className="qodef-quantity-buttons">
                      <span className="quantity">{this.state.quantity}</span>
                      <span className="qodef-quantity-minus" onClick={() => this.lessQuantity()}><i
                          className="fas fa-angle-down"/></span>
                      <span className="qodef-quantity-plus" onClick={() => this.moreQuantity()}><i
                          className="fas fa-angle-up"/></span>
                    </div>
                    <button className="single_add_to_cart_button"
                            onClick={() => this.addToCart(this.state.product.key, this.state.product.nombre, this.state.product.precio, this.state.product.disponibilidad)}>{this.context.t("ViewProductAddCart")}</button>
                  </div>
                  <span className="qodef-single-product-meta-title">{this.context.t("ViewProductInformacion")}</span>
                  <div className="product_meta">
                    <p><strong>{this.context.t("ViewProductSabor")}:</strong> {this.context.t(this.state.product.sabor)}
                    </p>
                    <p>
                      <strong>{this.context.t("ViewProductMaridaje")}:</strong> {this.context.t(this.state.product.maridaje)}
                    </p>
                    <p>
                      <strong>{this.context.t("ViewProductAspecto")}:</strong> {this.context.t(this.state.product.aspecto)}
                    </p>
                    <p>
                      <strong>{this.context.t("ViewProductAromas")}:</strong> {this.context.t(this.state.product.aromas)}
                    </p>
                    <p><strong>{this.context.t("ViewProductCategoria")}:</strong> {this.state.product.categoria}</p>
                    <p><strong>{this.context.t("ViewProductContenido")}:</strong> {this.state.product.contenido} ml</p>
                    <p><strong>{this.context.t("ViewProductAlcohol")}:</strong> {this.state.product.alcVol}%</p>
                    <p><strong>{this.context.t("ViewProductAmargor")}:</strong> {this.state.product.amargorIBUS}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    }else{
      return (
          <div className="view-product-container"/>
      );
    }
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

ViewProductWebContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ViewProductWebContainer;
