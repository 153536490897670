import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';
import { setActiveImage } from '../../../actions/photoActions';
import { validate } from './validate';

class EditRecipe extends Component {
  constructor(props){
    super(props);
    this.state = { 
      preview: null
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.props.initialize({ id: data.key });
    this.props.dispatch(change('EditRecipeForm', 'nombre', data.nombre));
    this.props.dispatch(change('EditRecipeForm', 'categoria', data.categoria));
    this.props.dispatch(change('EditRecipeForm', 'ingredientes', data.ingredientes));
    this.props.dispatch(change('EditRecipeForm', 'pasos', data.pasos));
    this.setState({ preview: data.imagen });
  }

  renderField = ({input, meta, type, label, name, placeholder }) => (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );
  
  renderFieldList = ({input, meta, type, placeholder }) => (
    <div className="form-group">
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );

  renderList = ({ fields, meta: { error, submitFailed } }) => (
    <div className="contenedor-lista">
      {fields.map((element, index) => (
        <div key={index} className="contenedor-elemento-lista">
          <Field name={`${element}.nombre`} type="text" component={this.renderFieldList} />
          <button type="button" className="btn btn-danger" onClick={() => fields.remove(index)}>Eliminar</button>
        </div>
      ))}
      <button type="button" className="btn btn-secondary" onClick={() => fields.push({})}>Agregar</button>
      {submitFailed && error && <p>{error}</p>}
    </div>
  );

  renderSelect = ({input, meta, label, name, empty, options }) => (
      <div className="form-group">
        <label className="control-label" htmlFor={name}>{label}</label>
        <select className="form-control" {...input} disabled={options.length === 0}>
          <option value="">{empty}</option>
          {options.length > 0 && options.map(element => {
            return (<option key={element.key} value={element.nombre}>{element.nombre}</option>);
          })}
        </select>
        { meta.touched && meta.error && <span>{meta.error}</span> }
      </div>
  );

  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      const preview = URL.createObjectURL(e.target.files[0]);
      this.props.setActiveImage(image);
      this.setState({ preview });
    }
  };

  render() {
    const {handleSubmit, submitting, pristine } = this.props;
    const categorias = [{key:'Entrada', nombre:'Entrada'},{key:'Platillo Fuerte', nombre: 'Platillo Fuerte'},{key:'Postre', nombre:'Postre'}];
    return (
      <div className="card">
        <div className="body">
          <div className="form-container m-b-30">
            <form onSubmit={handleSubmit}>
              <Field name="nombre" label="Nombre" component={this.renderField} type="text" placeholder="Nombre" />
              <Field name="categoria" label="Categoría" component={this.renderSelect} options={categorias} empty="Selecciona una categoría..." />
              <div className="form-group">
                <label className="control-label d-block">Foto de la receta</label>
                { this.state.preview ? <img className="preview-photo" src={this.state.preview} alt="Cerveza Sierra Fría" /> : '' }
                <label className="label-file-upload">Seleccionar una foto...
                  <input type="file" id="file-upload" onChange={this.handleChange} />
                </label>
              </div>
              <div className="form-group">
                <label className="control-label d-block">Lista de Ingredientes</label>
                <FieldArray name="ingredientes" component={this.renderList} />
              </div>
              <div className="form-group">
                <label className="control-label d-block">Lista de Pasos</label>
                <FieldArray name="pasos" component={this.renderList} />
              </div>
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Guardar</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const EditRecipeForm = reduxForm({ 
  form: 'EditRecipeForm',
  validate
})(EditRecipe);

const mapDispatchToProps = (dispatch) => {
  return{
    setActiveImage: (image) => {
      dispatch(setActiveImage(image))
    }
  }
};

export default connect(null,mapDispatchToProps)(EditRecipeForm);
