import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';
import EditStore from './../../components/stores/EditStore';
import firebase from './../../../Firebase';
import Swal from 'sweetalert2';

class EditStoreContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.tableStores = this.db.collection('tiendas');
  }

  handleSubmit = (values) => {
    const { id, nombre, direccion, latitud, longitud } = values;
    const lat = Number(latitud);
    const lng = Number(longitud);
    this.tableStores.doc(id).update({ nombre, direccion, latitud: lat, longitud: lng }).then( () => {
      Swal.fire({
        title: '¡Operación exitosa!',
        text: 'El elemento ha sido guardado correctamente.',
        icon: 'success',
        confirmButtonText: 'Continuar'
      });
      this.props.history.push('lista-tiendas');
    }).catch( () => {
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'Hubo un problema con la base de datos.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      });
    });
  }

  renderBody(){
    return (
      <EditStore data={this.props.location.store} onSubmit={this.handleSubmit}  />
    );
  }

  render() {
    return (
      <AppFrame title="Editar Tienda" body={this.renderBody()} />
    );
  }
}

export default EditStoreContainer;
