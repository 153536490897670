import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import PanelTile from './../../components/panel/PanelTile';
import PanelGraphic from './../../components/panel/PanelGraphic';
import PanelTable from './../../components/panel/PanelTable';

class PanelContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      ordersList: [],
      clientsTile: 0,
      salesYearTile: 0,
      salesMonthTile: 0,
      productsInventory: 0,
      salesData: []
    };
    this.tableOrders = this.db.collection('clientes');
    this.tableProducts = this.db.collection('productos');
  }

  componentDidMount() {
    this.unsubscribe = this.tableOrders.onSnapshot(this.getOrdersList);
    this.unsubscribeTwo = this.tableProducts.onSnapshot(this.getProductsInventory);
  }
  
  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
    this.unsubscribeTwo && this.unsubscribeTwo();
  }

  getProductsInventory = (querySnapshot) => {
    let productsInventory = 0;
    querySnapshot.forEach((doc) => {
      const { disponibilidad } = doc.data();
      productsInventory += disponibilidad;
    });
    this.setState({ productsInventory });
  };

  getOrdersList = (querySnapshot) => {
    const clientsTile = querySnapshot.size;
    const ordersList = [];
    let salesMonthTile = 0;
    let salesYearTile = 0;
    let salesData = [['Enero', 0], ['Febrero', 0], ['Marzo', 0], ['Abril', 0], ['Mayo', 0],['Junio', 0],['Julio', 0],['Agosto', 0],['Septiembre', 0],['Octubre', 0],['Noviembre', 0],['Diciembre', 0]];
    querySnapshot.forEach((doc) => {
      const { pedidos, nombre } = doc.data();
      if(pedidos !== undefined){
        pedidos.map( (pedido,i) => {
          const fechaPedido = new Date(pedido.fecha.seconds * 1000);
          if(new Date().getMonth() === fechaPedido.getMonth()){
            salesMonthTile += pedido.total;
          }
          if(new Date().getFullYear() === fechaPedido.getFullYear()){
            salesYearTile += pedido.total;
          }
          salesData[fechaPedido.getMonth()][1] += 1;
          ordersList.push({ key: i, cliente: nombre, estado: pedido.estado, fecha:pedido.fecha, total:pedido.total });
          return true;
        });
      }
    });
    ordersList.sort( (a,b) => b.fecha - a.fecha);
    this.setState({ ordersList, clientsTile, salesYearTile, salesMonthTile, salesData });
  };

  renderBody(){
    return(
      <div className="panel-container">
        <div className="row clearfix">
          <PanelTile icon="fas fa-chart-line" title="Ventas Anuales" number={this.state.salesYearTile} price="$" />
          <PanelTile icon="fas fa-chart-area" title="Ventas Mensuales" number={this.state.salesMonthTile} price="$" />
          <PanelTile icon="fa fa-cubes" title="Inventario" number={this.state.productsInventory} price="" />
          <PanelTile icon="fa fa-users" title="Clientes" number={this.state.clientsTile} price="" />
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <PanelGraphic title={"Ventas del " + new Date().getFullYear()} info={this.state.salesData} />
          </div>
          <div className="col-lg-6 col-md-12">
            <PanelTable title="Pedidos Recientes" orders={this.state.ordersList} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <AppFrame title="Panel" body={this.renderBody()} />
    );
  }
}

export default PanelContainer;
