import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'fecha',
  text: 'Fecha',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'nombre',
  text: 'Nombre',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'correo',
  text: 'Correo',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'asunto',
  text: 'Asunto',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'acciones',
  text: 'Acciones',
  classes: 'row-flex'
}];

class ContactContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      contactList: []
    };
    this.tableContact = this.db.collection('contacto');
  }

  componentDidMount() {
    this.unsubscribe = this.tableContact.onSnapshot(this.getContactList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getContactList = (querySnapshot) => {
    const contactList = [];
    const options = {dateStyle: "long",timeStyle: "short",timeZone: "America/Mexico_City"};
    querySnapshot.forEach((doc) => {
      const { fecha, nombre, correo, asunto, mensaje } = doc.data();
      const timestamp = fecha.seconds * 1000;
      const date = new Intl.DateTimeFormat('es-MX', options).format(timestamp);
      const message = { key: doc.id, fecha: date, nombre, correo, asunto, mensaje };
      const keyView = 'view' + doc.id;
      const acciones = [<button key={keyView} className="btn btn-primary" onClick={() => this.viewMessage(message)}><i className="fa fa-eye"></i> Ver Mensaje</button>];
      contactList.push({ key: doc.id, fecha: date, nombre, correo, asunto, mensaje, acciones });
    });
    this.setState({ contactList });
  }

  viewMessage = (message) => {
    this.props.history.push({
      pathname: '/ver-mensaje',
      message
    });
  }

  renderBody(){
    return(
      <div className="contact-container">
        <div className="card">
          <div className="body">
            { this.state.contactList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Fecha</Th>
                  <Th>Nombre</Th>
                  <Th>Correo</Th>
                  <Th>Asunto</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.contactList.map(contact => {
                  return(
                    <Tr key={contact.key}>
                      <Td>{contact.fecha}</Td>
                      <Td>{contact.nombre}</Td>
                      <Td>{contact.correo}</Td>
                      <Td>{contact.asunto}</Td>
                      <Td>{contact.acciones}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.contactList} columns={columns} />
          </div>
        </div>
      </div>            
    );
  }

  render() {
    return (
      <AppFrame title="Mensajes de Contacto" body={this.renderBody()} />
    );
  }
}

export default ContactContainer;
