import React, {Component} from 'react';
import AppFrame from '../components/AppFrame';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {connect} from 'react-redux';
import {setActiveMenu} from '../../actions/menuActions';
import AddressWeb from "../components/AddressWeb";
import Swal from "sweetalert2";
import firebase from "../../Firebase";
import StripeCheckout from 'react-stripe-checkout';
import {NavLink} from "react-router-dom";
import dhlLogo from '../img/dhl.jpg';
import estafetaLogo from '../img/estafeta.jpg';
import tresguerrasLogo from '../img/tresguerras.jpg';
import castoresLogo from '../img/castores.jpg';
import emailjs from 'emailjs-com';
import PropTypes from "prop-types";

class CartContainer extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.state = {
            total: localStorage.getItem('total') ? Number(localStorage.getItem('total')) : 0,
            arrProducts: localStorage.getItem('arrProducts') ? JSON.parse(localStorage.getItem('arrProducts')) : [],
            allowCheckout: true
        };
        this.tableProducts = this.db.collection('productos');
    }

    componentDidMount() {
        this.props.setActiveMenu('');
    }

    lessQuantity = (product) => {
        let newTotal = 0;
        let newTotalProductos = 0;
        this.state.arrProducts.map(element => {
            if (element.id === product.id) {
                if (element.cantidad === 6) {
                    this.removeProduct(product);
                } else {
                    element.cantidad -= 6;
                    element.total = element.cantidad * element.precio;
                }
            }
            newTotal += element.total;
            newTotalProductos += element.cantidad;
            this.setCartData(this.state.arrProducts, newTotal, newTotalProductos);
            return true;
        });
    };

    moreQuantity = (product) => {
        let newTotal = 0;
        let newTotalProductos = 0;
        this.state.arrProducts.map(element => {
            if (element.id === product.id) {
                element.cantidad += 6;
                element.total = element.cantidad * element.precio;
            }
            newTotal += element.total;
            newTotalProductos += element.cantidad;
            return true;
        });
        this.setCartData(this.state.arrProducts, newTotal, newTotalProductos);
    };

    removeProduct = (product) => {
        const newArrProductos = this.state.arrProducts.filter(element => {
            return element !== product
        });
        let newTotal = 0;
        let newTotalProductos = 0;
        newArrProductos.map(element => {
            newTotal += element.total;
            newTotalProductos += element.cantidad;
            return true;
        });
        this.setCartData(newArrProductos, newTotal, newTotalProductos);
        this.updateCart();
    };

    setCartData = (arr, total, products) => {
        // Descuento si compran en múltiplos de 24
        if(products % 24 === 0){
            total = total * 0.9;
        }
        this.setState({arrProducts: arr, total: total});
        localStorage.setItem('total', total);
        localStorage.setItem('totalProductos', products);
        localStorage.setItem('arrProducts', JSON.stringify(Array.from(arr)));
    };

    updateCart = () => {
        window.location.reload();
    };

    handleAddress = (values) => {
        const { calle, numero, colonia, postal, ciudad, estado } = values;
        if(calle !== undefined && numero !== undefined && colonia !== undefined && postal !== undefined && ciudad !== undefined && estado !== undefined){
            const direccion = calle + ' ' + numero + ' ' + colonia + ' C.P. ' + postal + ' ' + ciudad + ', ' + estado;
            localStorage.setItem('calle',calle);
            localStorage.setItem('numero',numero);
            localStorage.setItem('colonia',colonia);
            localStorage.setItem('postal',postal);
            localStorage.setItem('ciudad',ciudad);
            localStorage.setItem('estado',estado);
            localStorage.setItem('direccion',direccion);
            this.setState({ allowCheckout: false });
        }
    };

    checkout = (idStripe) => {
        const fecha = new Date();
        const total = this.state.total;
        let productos = [];
        this.state.arrProducts.map( element => {
            const newInventory = element.disponibilidad - element.cantidad;
            this.tableProducts.doc(element.id).update({disponibilidad: newInventory}).then( () => {
                productos.push({ nombre: element.nombre, cantidad: element.cantidad, precio: element.precio });
            });
            return true;
        });
        let clientRef = this.db.collection('clientes').doc(localStorage.getItem('clientId'));
        const direccion = localStorage.getItem('direccion');
        const stripe_id = idStripe;
        const estado = this.context.t("PedidoCapturado");
        clientRef.update({ pedidos: firebase.firestore.FieldValue.arrayUnion({ stripe_id, direccion, fecha, estado, total, productos })})
        .then( () => {
            Swal.fire({
                title: this.context.t("ModalGracias"),
                text: this.context.t("ModalPedidoExito"),
                icon: 'success',
                confirmButtonText: this.context.t("ModalBotonContinuar")
            }).then( () => {
                const template_params = { user_email: localStorage.getItem('clientEmail') };
                emailjs.send('default_service','nuevo_pedido',template_params,'user_dXo0PylJYVui7dvBhsvGX').then( () => {
                    localStorage.setItem('calle','');
                    localStorage.setItem('numero','');
                    localStorage.setItem('colonia','');
                    localStorage.setItem('postal','');
                    localStorage.setItem('ciudad','');
                    localStorage.setItem('estado','');
                    localStorage.setItem('direccion','');
                    localStorage.setItem('envio', '0');
                    localStorage.setItem('total', '0');
                    localStorage.setItem('totalProductos', '0');
                    localStorage.setItem('arrProducts', JSON.stringify(Array.from([])));
                    window.location.reload();
                }).catch( () => {
                    Swal.fire({
                        title: this.context.t("ModalLoSentimos"),
                        text: this.context.t("ModalPedidoCorreoError"),
                        icon: 'warning',
                        confirmButtonText: this.context.t("ModalBotonContinuar")
                    }).then( () => {});
                });
            });
        });
    };

    onToken = (token) => {
        const createCharge = firebase.functions().httpsCallable('chargeCardStripe');
        createCharge({amount: this.state.total * 100, token: token.id}).then(result => {
            if(result.data.success === true && result.data.response.paid === true){
                const idStripe = result.data.response.id;
                this.checkout(idStripe);
            }else{
                Swal.fire({
                    title: this.context.t("ModalLoSentimos"),
                    text: this.context.t("ModalPedidoPagoError"),
                    icon: 'warning',
                    confirmButtonText: this.context.t("ModalBotonContinuar")
                }).then( () => {});
            }
        }).catch(() => {
            //Error with function
        });
    };

    renderBody() {
        return (
            <div className="cart-container">
                <div className="qodef-title-holder cart-web-image">
                    <div className="qodef-title-wrapper">
                        <div className="qodef-title-inner">
                            <div className="qodef-grid">
                                <h1 className="qodef-page-title">{this.context.t("CarritoTitulo")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="body">
                        { this.state.arrProducts && this.state.arrProducts.length > 0 ? <div className="cart-products-container">
                                <Table className="table_cart">
                                    <Thead>
                                        <Tr>
                                            <Th className="product-remove">{this.context.t("CarritoEliminar")}</Th>
                                            <Th className="product-name">{this.context.t("CarritoProducto")}</Th>
                                            <Th className="product-price">{this.context.t("CarritoPrecio")}</Th>
                                            <Th className="product-quantity">{this.context.t("CarritoCantidad")}</Th>
                                            <Th className="product-subtotal">{this.context.t("CarritoTotal")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.state.arrProducts.map(product => {
                                            return (
                                                <Tr className="cart_item" key={product.id}>
                                                    <Td className="product-remove"><i
                                                        onClick={() => this.removeProduct(product)}
                                                        className="fas fa-times"/></Td>
                                                    <Td className="product-name">{product.nombre}</Td>
                                                    <Td className="product-price">${product.precio.toFixed(2)} MXN</Td>
                                                    <Td className="product-quantity">
                                                        <div className="qodef-quantity-buttons">
                                                            <span className="quantity">{product.cantidad}</span>
                                                            <span className="qodef-quantity-minus"
                                                                  onClick={() => this.lessQuantity(product)}><i
                                                                className="fas fa-angle-down"/></span>
                                                            <span className="qodef-quantity-plus"
                                                                  onClick={() => this.moreQuantity(product)}><i
                                                                className="fas fa-angle-up"/></span>
                                                        </div>
                                                    </Td>
                                                    <Td className="product-subtotal">${product.total.toFixed(2)} MXN</Td>
                                                </Tr>
                                            )
                                        })}
                                        <Tr>
                                            <Td colSpan="5" className="actions">
                                                <button className="button_yellow" onClick={() => this.updateCart()}>{this.context.t("CarritoActualizar")}</button>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                                { localStorage.getItem('clientId') === null ?
                                    <div className="no_logged">
                                        <p>{this.context.t("CarritoNoLogged")}</p>
                                        <NavLink className="button_yellow" exact to="/iniciar-sesion">{this.context.t("CarritoLogIn")}</NavLink>
                                    </div>
                                    :
                                    <div className="cart_totals">
                                    <table className="table_totals mb-5">
                                        <tbody>
                                        <tr className="order-total">
                                            <td><strong>{this.context.t("CarritoTotal")}: ${(this.state.total).toFixed(2)} MXN</strong></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="shipping">
                                        <AddressWeb onChange={this.handleAddress} />
                                        <div className="logos-container mt-3">
                                            <div className="logo">
                                                <img src={dhlLogo} alt="Cerveza Sierra Fría" />
                                            </div>
                                            <div className="logo">
                                                <img src={estafetaLogo} alt="Cerveza Sierra Fría" />
                                            </div>
                                            <div className="logo">
                                                <img src={tresguerrasLogo} alt="Cerveza Sierra Fría" />
                                            </div>
                                            <div className="logo">
                                                <img src={castoresLogo} alt="Cerveza Sierra Fría" />
                                            </div>
                                        </div>
                                        <p className="mt-5">{this.context.t("CarritoEnvio")}</p>
                                    </div>
                                    <div className="checkout">
                                        <StripeCheckout
                                            name="Cerveza Sierra Fría"
                                            ComponentClass="div"
                                            panelLabel={this.context.t("CarritoPagar")}
                                            amount={this.state.total * 100}//cents
                                            currency='MXN'
                                            stripeKey="pk_live_B2BYRlv9Ax7fvkVBKrStX10J00HCOeUmjU"
                                            locale={this.props.lang}
                                            allowRememberMe={false}
                                            token={this.onToken}
                                        >
                                            <button type="button" className="button_yellow" disabled={this.state.allowCheckout}>{this.context.t("CarritoFinalizar")}</button>
                                        </StripeCheckout>
                                    </div>
                                </div>
                                }
                            </div>
                            : <div className="empty-cart-container">
                              <h5 className="empty-cart">{this.context.t("CarritoVacio")}</h5>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        lang: state.i18nState.lang
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveMenu: (menu) => {
            dispatch(setActiveMenu(menu))
        }
    }
};

CartContainer.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
