import React, { Component } from 'react';
import AppFrame from '../components/AppFrame';
import firebase from '../../Firebase';
import { Map, Marker, TileLayer } from "react-leaflet";
import PropTypes from "prop-types";

class StoresWebContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      storesList: []
    };
    this.tableStores = this.db.collection('tiendas');
  }

  componentDidMount() {
    this.unsubscribe = this.tableStores.onSnapshot(this.getStoresList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getStoresList = (querySnapshot) => {
    const storesList = [];
    querySnapshot.forEach((doc) => {
      const { nombre, direccion, latitud, longitud } = doc.data();
      storesList.push({ key: doc.id, nombre, direccion, latitud, longitud });
    });
    this.setState({ storesList });
  };

  renderBody(){
    return(
      <div className="stores-container">
        <div className="qodef-title-holder stores-web-image">
          <div className="qodef-title-wrapper">
            <div className="qodef-title-inner">
              <div className="qodef-grid">
                <h1 className="qodef-page-title">{this.context.t("TiendasTitulo")}</h1>
                <span className="qodef-page-subtitle">{this.context.t("TiendasSubtitulo")}</span>
              </div>
            </div>
          </div>
        </div>
        <section id="list-stores">
          <div className="d-flex justify-content-start flex-wrap">
            { this.state.storesList.length > 0 && this.state.storesList.map(store => {
              const position = [store.latitud,store.longitud];
              return (
                <div className="col-md-3 mb-5" key={store.key}>
                  <Map center={position} zoom={16}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker key={store.key} position={position} />
                  </Map>
                  <h4>{store.nombre}</h4>
                  <span className="subtitle_line"/>
                  <p>{store.direccion}</p>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    );
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

StoresWebContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default StoresWebContainer;
