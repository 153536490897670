import React, { Component } from 'react';
import AppFrame from '../components/AppFrame';
import PropTypes from "prop-types";
import firebase from "../../Firebase";

class ViewRecipeContainer extends Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.state = {
      recipe: null
    };
    this.tableRecipes = this.db.collection('recetas');
    const params = new URLSearchParams(this.props.location.search);
    this.getRecipe(params.get('recetaId'));
  }

  getRecipe = (id) => {
    this.tableRecipes.doc(id).get().then( doc => {
      if(doc.exists){
        const { nombre, categoria, ingredientes, pasos, imagen } = doc.data();
        const recipe = { key: doc.id, nombre, categoria, ingredientes, pasos, imagen };
        this.setState({ recipe });
      }
    });
  }

  renderBody(){
    if(this.state.recipe != null){
      return(
          <div className="view-recipe-container">
            <div className="qodef-single-recipe-content">
              <div className="recipe-image">
                <img src={this.state.recipe.imagen} alt="Cerveza Sierra Fría" />
              </div>
              <div className="qodef-single-recipe-summary">
                <div className="summary">
                  <h3 className="qodef-single-recipe-title">{this.context.t(this.state.recipe.nombre)}</h3>
                  <span className="qodef-single-recipe-meta-title">{this.context.t("ViewRecipeIngredientes")}</span>
                  <div className="recipe_meta">
                    <ul>
                      { this.state.recipe.ingredientes.length > 0 && this.state.recipe.ingredientes.map( (ingredient,index) => {
                        return(<li key={index}>{this.context.t(ingredient.nombre)}</li>)
                      })}
                    </ul>
                  </div>
                  <span className="qodef-single-recipe-meta-title">{this.context.t("ViewRecipeInstrucciones")}</span>
                  <div className="recipe_meta">
                    <ol>
                      { this.state.recipe.pasos.length > 0 && this.state.recipe.pasos.map( (step,index) => {
                        return(<li key={index}>{this.context.t(step.nombre)}</li>)
                      })}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    }else{
      return(
          <div className="view-recipe-container"/>
      );
    }
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

ViewRecipeContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ViewRecipeContainer;
