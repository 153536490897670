import React, {Component} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {setActiveContent} from "../../../actions/textEditorActions";
import {connect} from "react-redux";

class renderTextEditor extends Component {
    constructor(props) {
        super(props);
        const editorState = this.initEditorState();
        this.state = {
            editorState
        };
        this.changeValue(editorState);
    }

    initEditorState() {
        const html = this.props.input.value;
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    }

    handleChange(editorState) {
        this.setState({editorState});
        this.changeValue(editorState);
    }

    changeValue(editorState) {
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.props.setActiveContent(value);
        this.props.input.onChange(value);
    }

    render() {
        return (
            <Editor
                editorState={this.state.editorState}
                onEditorStateChange={(editorState) => this.handleChange(editorState)}/>
        );
    }
}

const mapDispatchToProps= (dispatch)=>{
    return{
        setActiveContent: (content) => {
            dispatch(setActiveContent(content))
        }
    }
};

export default connect(null,mapDispatchToProps)(renderTextEditor);
