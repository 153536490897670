import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

const validate = values => {
  const error = {};
  if(!values.correo){
  error.correo = 'Escribe el correo por favor.';
  }
  if(!values.contrasenia){
  error.contrasenia = 'Escribe la contraseña por favor.';
  }
  return error;
};

class Login extends Component {
  renderField = ({input, meta, type, label, name, placeholder,autocomplete }) => (
    <div className="form-group">
      <label className="control-label sr-only" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={!autocomplete ? '' : autocomplete} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div>
  );

  render() {
    const {handleSubmit, submitting, pristine } = this.props;
    return (
      <form className="form-auth-small m-t-20" onSubmit={handleSubmit}>
        <Field name="correo" label="Correo" component={this.renderField} type="text" placeholder="Correo" />
        <Field name="contrasenia" label="Contraseña" component={this.renderField} type="password" placeholder="Contraseña" autocomplete="current-password" />
        <button type="submit" className="btn btn-primary btn-block" disabled={pristine || submitting}>Entrar</button>
      </form>
    );
  }
}

const LoginForm = reduxForm({ 
  form: 'LoginForm',
  validate
})(Login);

export default LoginForm;