import React from 'react';
import {textFilter} from 'react-bootstrap-table2-filter';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
    dataField: 'nombre',
    text: 'Nombre',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'cantidad',
    text: 'Cantidad',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'precio',
    text: 'Precio',
    sort: true,
    filter: textFilter(),
    formatter: (cell) => {
        return <span>${cell.toFixed(2)}</span>;
    }
}];

const OrderClientContainer = ({order, handler}) => {
    const products = order ? order.productos : []
    return (
        <div className="order-client-container">
            <button className="btn btn-warning m-b-15" onClick={handler}>Volver</button>
            <div className="card">
                <div className="body">
                    <h2 className="title-table">Detalles del Pedido #{order.key} del Cliente: {order.cliente}</h2>
                    <small className="text-muted">Stripe ID: </small>
                    <p>{order.stripe_id}</p>
                    <small className="text-muted">Fecha: </small>
                    <p>{order.fecha}</p>
                    <small className="text-muted">Dirección: </small>
                    <p>{order.direccion}</p>
                    <small className="text-muted">Total: </small>
                    <p>${order.total.toFixed(2)}</p>
                    <small className="text-muted">Estado: </small>
                    <p>{order.estado}</p>
                    {products.length > 0 ? <Table className="table-admin">
                        <Thead>
                            <Tr>
                                <Th>Nombre</Th>
                                <Th>Cantidad</Th>
                                <Th>Precio</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {products.map(product => {
                                return (
                                    <Tr key={product.key}>
                                        <Td>{product.nombre}</Td>
                                        <Td>{product.cantidad}</Td>
                                        <Td>${product.precio.toFixed(2)}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p>}
                    <TableDesktop data={products} columns={columns} />
                </div>
            </div>
        </div>
    );
}

export default OrderClientContainer;
