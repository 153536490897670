export const validate = values => {
    const error = {};
    if(!values.nombre){
        error.nombre = 'Escribe el nombre por favor.';
    }
    if(!values.precio){
        error.precio = 'Escribe el precio por favor.';
    }
    if(!values.contenido){
        error.contenido = 'Escribe el contenido por favor.';
    }
    if(!values.alcVol){
        error.alcVol = 'Escribe el volumen de alcohol por favor.';
    }
    if(!values.amargorIBUS){
        error.amargorIBUS = 'Escribe el amargor IBUS por favor.';
    }
    if(!values.disponibilidad){
        error.disponibilidad = 'Escribe la disponibilidad por favor.';
    }
    if(!values.categoria){
        error.categoria = 'Selecciona una categoría por favor.';
    }
    if(!values.aromas){
        error.aromas = 'Escribe el aromas por favor.';
    }
    if(!values.aspecto){
        error.aspecto = 'Escribe el aspecto por favor.';
    }
    if(!values.maridaje){
        error.maridaje = 'Escribe el maridaje por favor.';
    }
    if(!values.sabor){
        error.sabor = 'Escribe el sabor por favor.';
    }
    return error;
};