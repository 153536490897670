import React, {Component} from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import {textFilter} from 'react-bootstrap-table2-filter';
import OrderContainer from './OrderContainer';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {Button, Modal} from "react-bootstrap";
import Swal from "sweetalert2";
import emailjs from 'emailjs-com';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
    dataField: 'fecha',
    text: 'Fecha',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'cliente',
    text: 'Cliente',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'stripe_id',
    text: 'Stripe ID',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'total',
    text: 'Total',
    sort: true,
    filter: textFilter(),
    formatter: (cell) => {
        return <span>${cell.toFixed(2)}</span>;
    }
}, {
    dataField: 'estado',
    text: 'Estado',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'acciones',
    text: 'Acciones',
    classes: 'row-flex-wrap'
}];

class OrdersContainer extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.state = {
            ordersList: [],
            section: 'orders',
            order: null,
            showModalState: false,
            orderState: null,
            stateChanged: false,
            ordersDBState: [],
            showModalShipping: false,
            orderShipping: null,
            shippingValid: false,
            shippingCost: 0,
            shippingTotal: 0,
            ordersDBShipping: []
        };
        this.handlerOrder = this.handlerOrder.bind(this);
        this.tableOrders = this.db.collection('clientes');
    }

    handlerOrder() {
        this.setState({section: 'orders'});
    }

    componentDidMount() {
        this.unsubscribe = this.tableOrders.onSnapshot(this.getordersList);
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    getordersList = (querySnapshot) => {
        const ordersList = [];
        const options = {dateStyle: "long", timeStyle: "short", timeZone: "America/Mexico_City"};
        querySnapshot.forEach((doc) => {
            const {correo, pedidos} = doc.data();
            if (pedidos !== undefined) {
                pedidos.map((pedido, keyOrder) => {
                    const {fecha, direccion, total, productos, estado, stripe_id } = pedido;
                    const timestampOrder = fecha.seconds * 1000;
                    const dateOrder = new Intl.DateTimeFormat('es-MX', options).format(timestampOrder);
                    const productosArr = [];
                    productos.map((producto, keyProduct) => {
                        const {cantidad, nombre, precio} = producto;
                        productosArr.push({key: keyProduct, nombre, cantidad, precio});
                        return true;
                    });
                    const order = {
                        key: keyOrder,
                        cliente: correo,
                        clienteId: doc.id,
                        fecha: dateOrder,
                        timestamp: fecha,
                        direccion,
                        total,
                        productos: productosArr,
                        estado,
                        stripe_id
                    };
                    const keyView = 'view' + doc.id;
                    const keyState = 'state' + doc.id;
                    let accionesOrder = [];
                    if(estado === 'Capturado'){
                        const keyShipping = 'shipping' + doc.id;
                        accionesOrder = [
                            <button key={keyView} className="btn btn-primary" onClick={() => this.viewOrder(order)}><i className="fa fa-eye"/> Ver Detalles</button>,
                            <button key={keyState} className="btn btn-warning" onClick={() => this.openModalState(order)}><i className="far fa-file-alt"/> Cambiar Estado</button>,
                            <button key={keyShipping} className="btn btn-success" onClick={() => this.openModalShipping(order)}><i className="fas fa-shipping-fast"/> Cobrar Envío</button>
                        ];
                    }else if(estado === 'Pagado'){
                        accionesOrder = [
                            <button key={keyView} className="btn btn-primary" onClick={() => this.viewOrder(order)}><i className="fa fa-eye"/> Ver Detalles</button>,
                            <button key={keyState} className="btn btn-warning" onClick={() => this.openModalState(order)}><i className="far fa-file-alt"/> Cambiar Estado</button>
                        ];
                    }
                    ordersList.push({
                        key: keyOrder,
                        cliente: correo,
                        clienteId: doc.id,
                        fecha: dateOrder,
                        timestamp: fecha,
                        direccion,
                        total,
                        productos: productosArr,
                        estado,
                        stripe_id,
                        acciones: accionesOrder
                    });
                    return true;
                });
            }
        });
        this.setState({ordersList});
    };

    viewOrder = (order) => {
        this.setState({section: 'order', order});
    };

    openModalState = (order) => {
        this.setState({orderState: order, showModalState: true});
    };

    handleCloseState = () => {
        this.setState({showModalState: false});
    };

    changeNewState = (event) => {
        const state = event.target.value;
        if(state !== 'DEFAULT'){
            let arr = [];
            this.state.ordersList.map(element => {
                const productos = [];
                element.productos.map(product => {
                    productos.push({
                        cantidad: product.cantidad,
                        nombre: product.nombre,
                        precio: product.precio
                    });
                    return true;
                });
                let order = {
                    direccion: element.direccion,
                    estado: element.estado,
                    fecha: element.timestamp,
                    productos,
                    total: element.total,
                    stripe_id: element.stripe_id
                };
                if (element.key === this.state.orderState.key) {
                    order.estado = state;
                }
                arr.push(order);
                return true;
            });
            this.setState({ordersDBState: arr, stateChanged: true });
        }
    };

    updateState = () => {
        const order = this.state.orderState;
        const stateChanged = this.state.stateChanged;
        if(stateChanged){
            this.db.collection('clientes').doc(order.clienteId).update({pedidos: Array.from(this.state.ordersDBState)}).then(() => {
                Swal.fire({
                    title: '¡Operación exitosa!',
                    text: 'El estado ha sido actualizado correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Continuar'
                }).then(() => {
                    this.handleCloseState();
                });
            }).catch(() => {
                Swal.fire({
                    title: '¡Lo sentimos!',
                    text: 'Hubo un problema con la base de datos.',
                    icon: 'warning',
                    confirmButtonText: 'Continuar'
                }).then(() => {
                    this.handleCloseState();
                });
            });
        }else{
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Debes seleccionar un estado.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then(() => {
                this.handleCloseState();
            });
        }
    };

    openModalShipping = (order) => {
        this.setState({orderShipping: order, showModalShipping: true});
    };

    handleCloseShipping = () => {
        this.setState({showModalShipping: false});
    };

    changeNewShipping = (event) => {
        const cost = event.target.value;
        if(cost > 0){
            let arr = [];
            this.state.ordersList.map(element => {
                const productos = [];
                element.productos.map(product => {
                    productos.push({
                        cantidad: product.cantidad,
                        nombre: product.nombre,
                        precio: product.precio
                    });
                    return true;
                });
                let order = {
                    direccion: element.direccion,
                    estado: element.estado,
                    fecha: element.timestamp,
                    productos,
                    total: element.total,
                    stripe_id: element.stripe_id
                };
                if (element.key === this.state.orderShipping.key) {
                    order.total = Number(order.total) + Number(cost);
                    order.estado = 'Pagado';
                    this.setState({shippingTotal:order.total});
                }
                arr.push(order);
                return true;
            });
            this.setState({ordersDBShipping: arr, shippingValid: true, shippingCost: cost});
        }
    };

    updateShipping = () => {
        const order = this.state.orderShipping;
        const shippingValid = this.state.shippingValid;
        if(shippingValid){
            const createShipping = firebase.functions().httpsCallable('chargeShippingStripe');
            createShipping({amount: this.state.shippingTotal * 100, stripe_id: order.stripe_id}).then(result => {
                if(result.data.success === true && result.data.response.paid === true){
                    const costo_envio = Number(this.state.shippingCost);
                    this.db.collection('clientes').doc(order.clienteId).update({pedidos: Array.from(this.state.ordersDBShipping)}).then(() => {
                        const template_params = { user_email: order.cliente, shipping: '$' + costo_envio.toFixed(2) };
                        emailjs.send('default_service','costo_envio',template_params,'user_dXo0PylJYVui7dvBhsvGX').then( () => {
                            Swal.fire({
                                title: '¡Operación exitosa!',
                                text: 'El costo del envío ha sido enviado correctamente.',
                                icon: 'success',
                                confirmButtonText: 'Continuar'
                            }).then(() => {
                                this.handleCloseShipping();
                            });
                        }).catch( () => {
                            Swal.fire({
                                title: '¡Lo sentimos!',
                                text: 'Hubo un problema al enviar el correo sobre el costo del envío.',
                                icon: 'warning',
                                confirmButtonText: 'Continuar'
                            }).then( () => {
                                this.handleCloseShipping();
                            });
                        });
                    }).catch((error) => {
                        console.log(error);
                        Swal.fire({
                            title: '¡Lo sentimos!',
                            text: 'Hubo un problema con la base de datos.',
                            icon: 'warning',
                            confirmButtonText: 'Continuar'
                        }).then(() => {
                            this.handleCloseShipping();
                        });
                    });
                }else{
                    Swal.fire({
                        title: '¡Lo sentimos!',
                        text: 'Hubo un problema al crear tu pedido. Revisa tu método de pago.',
                        icon: 'warning',
                        confirmButtonText: 'Continuar'
                    }).then( () => {
                        this.handleCloseShipping();
                    });
                }
            }).catch(() => {
                //Error with function
            });
        }else{
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Debes ingresar un costo de envío mayor a cero.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then(() => {
                this.handleCloseShipping();
            });
        }
    };

    renderBody() {
        if (this.state.section === 'orders') {
            return (
                <div id="orders-container">
                    <div className="card">
                        <div className="body">
                            {this.state.ordersList.length > 0 ? <Table className="table-admin">
                                <Thead>
                                    <Tr>
                                        <Th>Fecha</Th>
                                        <Th>Cliente</Th>
                                        <Th>Stripe ID</Th>
                                        <Th>Total</Th>
                                        <Th>Estado</Th>
                                        <Th>Acciones</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.state.ordersList.map(order => {
                                        return (
                                            <Tr key={order.key}>
                                                <Td>{order.fecha}</Td>
                                                <Td>{order.cliente}</Td>
                                                <Td>{order.stripe_id}</Td>
                                                <Td>${order.total.toFixed(2)}</Td>
                                                <Td>{order.estado}</Td>
                                                <Td>{order.acciones}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p>}
                            <TableDesktop data={this.state.ordersList} columns={columns} />
                        </div>
                    </div>
                    <Modal show={this.state.showModalState} onHide={this.handleCloseState}>
                        <Modal.Header>
                            <Modal.Title>Actualizar estado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <select className="form-control" onChange={this.changeNewState} defaultValue={'DEFAULT'}>
                                <option value="DEFAULT">Selecciona un estado...</option>
                                <option value="Enviado">Enviado</option>
                                <option value="Entregado">Entregado</option>
                            </select>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleCloseState}>Cerrar</Button>
                            <Button variant="success" onClick={this.updateState}>Guardar</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showModalShipping} onHide={this.handleCloseShipping}>
                        <Modal.Header>
                            <Modal.Title>Enviar costo del envío</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input type="number" min="0" step="0.01" className="form-control" onChange={this.changeNewShipping} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleCloseShipping}>Cerrar</Button>
                            <Button variant="success" onClick={this.updateShipping}>Guardar</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        } else if (this.state.section === 'order') {
            return <OrderContainer handler={this.handlerOrder} order={this.state.order}/>
        }
    }

    render() {
        return (
            <AppFrame title="Pedidos" body={this.renderBody()}/>
        );
    }
}

export default OrdersContainer;
