import React, {Component} from 'react';
import AppFrame from "../components/AppFrame";
import PropTypes from "prop-types";
import firebase from '../../Firebase';
import ReactHtmlParser from "react-html-parser";

class BlogWebContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.state = {
            articlesList: []
        };
        this.tableArticles = this.db.collection('articulos');
    }

    componentDidMount() {
        this.unsubscribe = this.tableArticles.onSnapshot(this.getArticlesList);
    }

    componentWillUnmount(){
        this.unsubscribe && this.unsubscribe();
    }

    getArticlesList = (querySnapshot) => {
        const articlesList = [];
        const options = {dateStyle: "long",timeZone: "America/Mexico_City"};
        querySnapshot.forEach((doc) => {
            const { titulo, portada, fecha, categoria, contenido } = doc.data();
            const timestamp = fecha.seconds * 1000;
            const date = new Intl.DateTimeFormat('es-MX', options).format(timestamp);
            const resumen = contenido.slice(0,130) + '...';
            articlesList.push({ key: doc.id, titulo, portada, fecha: date, categoria, contenido, resumen });
        });
        this.setState({ articlesList });
    };

    viewArticle = (id) => {
        this.props.history.push({
            pathname: '/articulo',
            search: '?articuloId='+id
        })
    }

    renderBody(){
        return(
            <div className="blog-web-container">
                <div className="qodef-title-holder blog-web-image">
                    <div className="qodef-title-wrapper">
                        <div className="qodef-title-inner">
                            <div className="qodef-grid">
                                <h1 className="qodef-page-title">{this.context.t("BlogTitulo")}</h1>
                                <span className="qodef-page-subtitle">{this.context.t("BlogSubtitulo")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="blog-web-list">
                    <div className="list-articles">
                        {
                            this.state.articlesList.length > 0 ? this.state.articlesList.map(article => {
                                return (
                                    <div className="article" key={article.key}>
                                        <div className="portada">
                                            <img src={article.portada} alt="Cerveza Sierra Fría"/>
                                        </div>
                                        <p className="categoria">{article.categoria}</p>
                                        <h4 onClick={() => this.viewArticle(article.key)}>{article.titulo}</h4>
                                        <p className="fecha">{article.fecha}</p>
                                        <div className="contenido">
                                            {ReactHtmlParser(article.resumen)}
                                        </div>
                                        <button onClick={() => this.viewArticle(article.key)}>{this.context.t("BlogLeer")}</button>
                                    </div>
                                );
                            }) : <div className="categoria-vacia">{this.context.t("BlogVacio")}</div>
                        }
                    </div>
                </section>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()} />
        );
    }
}

BlogWebContainer.contextTypes = {
    t: PropTypes.func.isRequired
};

export default BlogWebContainer;
