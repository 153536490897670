import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PrivateRoute from './administrar/components/PrivateRoute';
import ProtectedRoute from './sitio-web/components/ProtectedRoute';
import LoginContainer from './administrar/containers/login/LoginContainer';
import PanelContainer from './administrar/containers/panel/PanelContainer';
import EditCategoryContainer from './administrar/containers/categories/EditCategoryContainer';
import NewCategoryContainer from './administrar/containers/categories/NewCategoryContainer';
import CategoryProductsContainer from './administrar/containers/categories/CategoryProductsContainer';
import ContactContainer from './administrar/containers/contact/ContactContainer';
import ViewMessageContainer from './administrar/containers/contact/ViewMessageContainer';
import ClientsContainer from './administrar/containers/clients/ClientsContainer';
import OrdersContainer from './administrar/containers/orders/OrdersContainer';
import HomeWebContainer from './sitio-web/containers/HomeWebContainer';
import NewsletterContainer from './administrar/containers/newsletter/NewsletterContainer';
import ProductsContainer from './administrar/containers/products/ProductsContainer';
import NewProductContainer from './administrar/containers/products/NewProductContainer';
import EditProductContainer from './administrar/containers/products/EditProductContainer';
import ListStoresContainer from './administrar/containers/stores/ListStoresContainer';
import NewStoreContainer from './administrar/containers/stores/NewStoreContainer';
import EditStoreContainer from './administrar/containers/stores/EditStoreContainer';
import ContactWebContainer from './sitio-web/containers/ContactWebContainer';
import StoresWebContainer from './sitio-web/containers/StoresWebContainer';
import ProductsWebContainer from './sitio-web/containers/ProductsWebContainer';
import CartContainer from './sitio-web/containers/CartContainer';
import ViewProductWebContainer from './sitio-web/containers/ViewProductWebContainer';
import RecipesWebContainer from './sitio-web/containers/RecipesWebContainer';
import VisitorsContainer from './administrar/containers/visitors/VisitorsContainer';
import RecipesContainer from './administrar/containers/recipes/RecipesContainer';
import NewRecipeContainer from './administrar/containers/recipes/NewRecipeContainer';
import EditRecipeContainer from './administrar/containers/recipes/EditRecipeContainer';
import ViewRecipeContainer from './sitio-web/containers/ViewRecipeContainer';
import LoginWebContainer from "./sitio-web/containers/LoginWebContainer";
import OrdersWebContainer from "./sitio-web/containers/OrdersWebContainer";
import ViewOrderWebContainer from "./sitio-web/containers/ViewOrderWebContainer";
import CategoryArticle from "./administrar/containers/articles/CategoryArticle";
import NewCategoryArticle from "./administrar/containers/articles/NewCategoryArticle";
import EditCategoryArticle from "./administrar/containers/articles/EditCategoryArticle";
import ArticlesContainer from "./administrar/containers/articles/ArticlesContainer";
import NewArticle from "./administrar/containers/articles/NewArticle";
import EditArticle from "./administrar/containers/articles/EditArticle";
import CommentsContainer from "./administrar/containers/articles/CommentsContainer";
import BlogWebContainer from "./sitio-web/containers/BlogWebContainer";
import ArticleWebContainer from "./sitio-web/containers/ArticleWebContainer";

function App() {
    return (
        <Router>
            <Route exact path="/" component={HomeWebContainer}/>
            <Route exact path="/tiendas" component={StoresWebContainer}/>
            <Route exact path="/contacto" component={ContactWebContainer}/>
            <Route exact path="/nuestros-productos" component={ProductsWebContainer}/>
            <Route exact path="/ver-producto" component={ViewProductWebContainer}/>
            <Route exact path="/carrito" component={CartContainer}/>
            <Route exact path="/recetas" component={RecipesWebContainer}/>
            <Route exact path="/ver-receta" component={ViewRecipeContainer}/>
            <Route exact path="/iniciar-sesion" component={LoginWebContainer}/>
            <Route exact path="/blog" component={BlogWebContainer} />
            <Route exact path="/articulo" component={ArticleWebContainer} />
            <ProtectedRoute exact path="/mis-pedidos" component={OrdersWebContainer}/>
            <ProtectedRoute exact path="/mi-pedido" component={ViewOrderWebContainer}/>
            <Route exact path="/administrar" component={LoginContainer}/>
            <PrivateRoute exact path="/panel" component={PanelContainer}/>
            <PrivateRoute exact path="/categorias-productos" component={CategoryProductsContainer}/>
            <PrivateRoute exact path="/nueva-categoria" component={NewCategoryContainer}/>
            <PrivateRoute exact path="/editar-categoria" component={EditCategoryContainer}/>
            <PrivateRoute exact path="/lista-boletin" component={NewsletterContainer}/>
            <PrivateRoute exact path="/mensajes-contacto" component={ContactContainer}/>
            <PrivateRoute exact path="/ver-mensaje" component={ViewMessageContainer}/>
            <PrivateRoute exact path="/clientes" component={ClientsContainer}/>
            <PrivateRoute exact path="/pedidos" component={OrdersContainer}/>
            <PrivateRoute exact path="/productos" component={ProductsContainer}/>
            <PrivateRoute exact path="/nuevo-producto" component={NewProductContainer}/>
            <PrivateRoute exact path="/editar-producto" component={EditProductContainer}/>
            <PrivateRoute exact path="/lista-tiendas" component={ListStoresContainer}/>
            <PrivateRoute exact path="/nueva-tienda" component={NewStoreContainer}/>
            <PrivateRoute exact path="/editar-tienda" component={EditStoreContainer}/>
            <PrivateRoute exact path="/visitantes" component={VisitorsContainer}/>
            <PrivateRoute exact path="/lista-recetas" component={RecipesContainer}/>
            <PrivateRoute exact path="/nueva-receta" component={NewRecipeContainer}/>
            <PrivateRoute exact path="/editar-receta" component={EditRecipeContainer}/>
            <PrivateRoute exact path="/categorias-articulos" component={CategoryArticle}/>
            <PrivateRoute exact path="/nueva-categoria-articulo" component={NewCategoryArticle}/>
            <PrivateRoute exact path="/editar-categoria-articulo" component={EditCategoryArticle}/>
            <PrivateRoute exact path="/articulos" component={ArticlesContainer}/>
            <PrivateRoute exact path="/nuevo-articulo" component={NewArticle}/>
            <PrivateRoute exact path="/editar-articulo" component={EditArticle}/>
            <PrivateRoute exact path="/ver-comentarios" component={CommentsContainer}/>
        </Router>
    );
}

export default App;
