import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import { textFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'nombre',
  text: 'Nombre',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'categoria',
  text: 'Categoría',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'precio',
  text: 'Precio',
  sort: true,
  filter: textFilter(),
  formatter: (cell) => {
    return <span>${cell.toFixed(2)}</span>;
  }
}, {
  dataField: 'contenido',
  text: 'Contenido',
  sort: true,
  filter: textFilter(),
  formatter: (cell) => {
    return <span>{cell} ml</span>;
  }
}, {
  dataField: 'disponibilidad',
  text: 'Disponibilidad',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'acciones',
  text: 'Acciones',
  classes: 'row-flex-wrap'
}];

class ProductsContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      productsList: []
    };
    this.tableProducts = this.db.collection('productos');
  }

  componentDidMount() {
    this.unsubscribe = this.tableProducts.onSnapshot(this.getProductsList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getProductsList = (querySnapshot) => {
    const productsList = [];
    querySnapshot.forEach((doc) => {
      const { alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen } = doc.data();
      const producto = { key: doc.id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen };
      const keyEdit = 'edit' + doc.id;
      const keyDelete = 'delete' + doc.id;
      let acciones = [<button key={keyEdit} className="btn btn-warning" onClick={() => this.editProduct(producto)}><i className="fa fa-edit"/> Editar</button>,<button key={keyDelete} className="btn btn-danger" onClick={() => this.deleteProduct(producto.key)}><i className="far fa-trash-alt"/> Eliminar</button>];
      productsList.push({ key: doc.id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen, acciones });
    });
    this.setState({ productsList });
  }

  addProduct = () => {
    this.props.history.push('nuevo-producto');
  }

  editProduct = (product) => {
    this.props.history.push({
      pathname: '/editar-producto',
      product
    });
  }

  deleteProduct = (key) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás recuperar este elemento.",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then( (result) => {
      if(result.value){
        this.tableProducts.doc(key).delete().then( () => {
          Swal.fire({
            title: '¡Operación exitosa!',
            text: 'El elemento ha sido eliminado correctamente.',
            icon: 'success',
            confirmButtonText: 'Continuar'
          });
        }).catch( () => {
          Swal.fire({
            title: '¡Lo sentimos!',
            text: 'Hubo un problema con la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Continuar'
          });
        });
      }
    })
  }

  renderBody(){
    return(
      <div className="products-container">
        <div className="row clearfix m-b-25">
          <div className="col-md-12 col-sm-12">
            <button className="btn btn-sm btn-success" onClick={() => this.addProduct()}>Agregar Producto</button>
          </div>
        </div>
        <div className="card">
          <div className="body">
            { this.state.productsList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Nombre</Th>
                  <Th>Categoría</Th>
                  <Th>Precio</Th>
                  <Th>Contenido</Th>
                  <Th>Disponibilidad</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.productsList.map(product => {
                  return(
                    <Tr key={product.key}>
                      <Td>{product.nombre}</Td>
                      <Td>{product.categoria}</Td>
                      <Td>${product.precio.toFixed(2)}</Td>
                      <Td>{product.contenido}</Td>
                      <Td>{product.disponibilidad}</Td>
                      <Td>{product.acciones}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.productsList} columns={columns} />
          </div>
        </div>
      </div>            
    );
  }

  render() {
    return (
      <AppFrame title="Productos" body={this.renderBody()} />
    );
  }
}

export default ProductsContainer;
