import React, { Component } from 'react';
import AppFrame from '../components/AppFrame';
import firebase from '../../Firebase';
import PropTypes from "prop-types";

class RecipesWebContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      recipesList: []
    };
    this.tableRecipes = this.db.collection('recetas');
  }

  componentDidMount() {
    this.unsubscribe = this.tableRecipes.onSnapshot(this.getRecipesList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getRecipesList = (querySnapshot) => {
    const recipesList = [];
    querySnapshot.forEach((doc) => {
      const { nombre, categoria, ingredientes, pasos, imagen } = doc.data();
      recipesList.push({ key: doc.id, nombre, categoria, ingredientes, pasos, imagen });
    });
    this.setState({ recipesList });
  };

  viewRecipe = (recipe) => {
    this.props.history.push({
      pathname: '/ver-receta',
      search: '?recetaId='+recipe.key
    })
  };

  printRecipe = (recipe) => {
    return (
        <div className="recipe" key={recipe.key} onClick={() => this.viewRecipe(recipe)}>
          <div className="qodef-pl-image">
            <img src={recipe.imagen} alt="Cerveza Sierra Fría"/>
          </div>
          <p className="title">{this.context.t(recipe.nombre)}</p>
        </div>
    )
  };

  renderBody(){
    return(
      <div className="recipes-web-container">
        <div className="qodef-title-holder recipes-web-image">
          <div className="qodef-title-wrapper">
            <div className="qodef-title-inner">
              <div className="qodef-grid">
                <h1 className="qodef-page-title">{this.context.t("RecetasTitulo")}</h1>
                <span className="qodef-page-subtitle">{this.context.t("RecetasSubtitulo")}</span>
              </div>
            </div>
          </div>
        </div>
        <section id="recipes-web-list">
          <div className="qodef-pl-text-wrapper">
            <h2 className="qodef-recipe-list-title">{this.context.t("Entrada")}</h2>
          </div>
          <div className="list-recipes">
            {
              this.state.recipesList.length > 0 && this.state.recipesList.map(recipe => {
                if(recipe.categoria === 'Entrada') {
                  return this.printRecipe(recipe);
                }else{
                  return('');
                }
              })
            }
          </div>
          <div className="qodef-pl-text-wrapper">
            <h2 className="qodef-recipe-list-title">{this.context.t("PlatilloFuerte")}</h2>
          </div>
          <div className="list-recipes">
            {
              this.state.recipesList.length > 0 && this.state.recipesList.map(recipe => {
                if(recipe.categoria === 'Platillo Fuerte') {
                  return this.printRecipe(recipe);
                }else{
                  return('');
                }
              })
            }
          </div>
          <div className="qodef-pl-text-wrapper">
            <h2 className="qodef-recipe-list-title">{this.context.t("Postre")}</h2>
          </div>
          <div className="list-recipes">
            {
              this.state.recipesList.length > 0 && this.state.recipesList.map(recipe => {
                if(recipe.categoria === 'Postre') {
                  return this.printRecipe(recipe);
                }else{
                  return('');
                }
              })
            }
          </div>
        </section>
      </div>
    );
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

RecipesWebContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default RecipesWebContainer;
