import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './store';
import * as serviceWorker from './serviceWorker';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import I18n from 'redux-i18n';
import {translations} from './languages/translations';

const rootComponent = (
    <Provider store={store}>
        <I18n translations={translations}>
            <App/>
        </I18n>
    </Provider>
);

ReactDOM.render(rootComponent, document.getElementById('root'));
serviceWorker.unregister();
