export const toggleLoader = (loading) => {
  return{
    type: 'TOGGLE_LOADER',
    loading
  }
};
export const updateProgress = (progress) => {
  return{
    type: 'UPDATE_PROGRESS',
    progress
  }
};
