export const validate = values => {
    const error = {};
    if(!values.nombre){
        error.nombre = 'Escribe el nombre por favor.';
    }
    if (!values.ingredientes || !values.ingredientes.length) {
        error.ingredientes = { _error: 'Debes ingresar al menos un ingrediente' };
    }
    if (!values.pasos || !values.pasos.length) {
        error.pasos = { _error: 'Debes ingresar al menos un paso' };
    }
    return error;
};