import React, {Component} from 'react';
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PropTypes from "prop-types";

class ViewOrderWebContainer extends Component {
    render(){
        const { order, handler } = this.props;
        const products = order ? order.productos : [];
        return(
            <div className="order-container">
                <div className="qodef-title-holder order-web-image">
                    <div className="qodef-title-wrapper">
                        <div className="qodef-title-inner">
                            <div className="qodef-grid">
                                <h1 className="qodef-page-title">{this.context.t("VerPedidoTitulo")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="body">
                        <button className="btn btn-warning m-b-15" onClick={handler}>{this.context.t("VerPedidoVolver")}</button>
                        <h2 className="title-table">{this.context.t("VerPedidoInformacion")}</h2>
                        <small className="text-muted">{this.context.t("VerPedidoFecha")}: </small>
                        <p>{order.fecha}</p>
                        <small className="text-muted">{this.context.t("VerPedidoDireccion")}: </small>
                        <p>{order.direccion}</p>
                        <small className="text-muted">{this.context.t("VerPedidoTotal")}: </small>
                        <p>${order.total.toFixed(2)} MXN</p>
                        <small className="text-muted">{this.context.t("VerPedidoEstado")}: </small>
                        <p>{order.estado}</p>
                        { products.length > 0 ? <Table className="table_order">
                            <Thead>
                                <Tr>
                                    <Th>{this.context.t("VerPedidoNombre")}</Th>
                                    <Th>{this.context.t("VerPedidoCantidad")}</Th>
                                    <Th>{this.context.t("VerPedidoPrecio")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                { products.map( (product,key) => {
                                    return(
                                        <Tr key={key}>
                                            <Td>{product.nombre}</Td>
                                            <Td>{product.cantidad}</Td>
                                            <Td>${product.precio.toFixed(2)} MXN</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table> : <p className="table_order">{this.context.t("VerPedidoVacio")}</p> }
                    </div>
                </div>
            </div>
        );
    }
}

ViewOrderWebContainer.contextTypes = {
    t: PropTypes.func.isRequired
};

export default ViewOrderWebContainer;
