import React, { Component } from 'react';
import AppFrame from './../components/AppFrame';
import OwlCarousel from 'react-owl-carousel3';
import historiaProducto from '../img/historia.jpg';
import beneficio1Producto from '../img/beneficio_1.jpg';
import beneficio2Producto from '../img/beneficio_2.jpg';
import beneficio3Producto from '../img/beneficio_3.jpg';
import beneficio4Producto from '../img/beneficio_4.jpg';
import PromotionWeb from '../components/PromotionWeb';
import Swal from 'sweetalert2';
import firebase from '../../Firebase';
import { FacebookProvider, Page } from 'react-facebook';
import InstagramEmbed from 'react-instagram-embed';
import PropTypes from "prop-types";

class HomeWebContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      productsList: []
    };
    this.tableProducts = this.db.collection('productos');
    this.tablePromotions = this.db.collection('promociones');
  }

  componentDidMount() {
    this.unsubscribe = this.tableProducts.onSnapshot(this.getProductsList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getProductsList = (querySnapshot) => {
    const productsList = [];
    querySnapshot.forEach((doc) => {
      const { alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen } = doc.data();
      productsList.push({ key: doc.id, alcVol, amargorIBUS, aromas, aspecto, categoria, contenido, disponibilidad, maridaje, nombre, precio, sabor, imagen });
    });
    this.setState({ productsList });
  };

  handleSubmit = (values) => {
    const { correo } = values;
    this.tablePromotions.add({ correo }).then( () => {
      Swal.fire({
        title: this.context.t("ModalGracias"),
        text: this.context.t("ModalPromotionExito"),
        icon: 'success',
        confirmButtonText: this.context.t("ModalBotonContinuar")
      }).then( () => {
        window.location.reload();
      });
    }).catch( () => {
      Swal.fire({
        title: this.context.t("ModalLoSentimos"),
        text: this.context.t("ModalPromotionError"),
        icon: 'warning',
        confirmButtonText: this.context.t("ModalBotonContinuar")
      }).then( () => {
        //Closing alert
      });
    });
  };

  viewProduct = (product) => {
    this.props.history.push({
      pathname: '/ver-producto',
      search: '?productoId='+product.key
    })
  };

  renderBody(){
    return(
      <div className="home-container">
        <section id="slider">
          <h1>{this.context.t("HomeExplora")}</h1>
          <h2>{this.context.t("HomeExploraSubtitulo")}</h2>
        </section>

        <section id="slider_footer">
          {
              this.state.productsList.length > 0 && this.state.productsList.map(product => {
                return(
                    <div className="columna" key={product.key} style={{ background: `url(${product.imagen}) no-repeat center center`,backgroundSize: 'cover'}} onClick={() => this.viewProduct(product)}>
                      <h3>{product.nombre}</h3>
                      <p>{this.context.t(product.sabor)}</p>
                    </div>
                )
              })
          }
        </section>

        <section id="home_history">
          <div className="photos">
            <img src={historiaProducto} alt="Cerveza Sierra Fría"/>
          </div>
          <div className="text">
            <h3>{this.context.t("HomeHistoriaTitulo")}</h3>
            <span className="subtitle_line"/>
            <p>{this.context.t("HomeHistoriaP1")}</p>
            <p>{this.context.t("HomeHistoriaP2")}</p>
            <p>{this.context.t("HomeHistoriaP3")}</p>
            <p>{this.context.t("HomeHistoriaP4")}</p>
          </div>
        </section>

        <section id="phrase">
          <h3>{this.context.t("HomeFrase")}</h3>
        </section>

        <section id="testimonials">
          <div className="quote_mark"/>
          <OwlCarousel className="testimonial-slider" items={1} autoplay loop nav navText={[this.context.t("Anterior"),this.context.t("Siguiente")]} >
            <div className="testimonial-slider-item">
              <p>{this.context.t("HomeTestimonio1")}</p>
              <h3 className="name">JORGE M.</h3>
            </div>
            <div className="testimonial-slider-item">
              <p>{this.context.t("HomeTestimonio2")}</p>
              <h3 className="name">ROBERTO E.</h3>
            </div>
            <div className="testimonial-slider-item">   
              <p>{this.context.t("HomeTestimonio3")}</p>
              <h3 className="name">JOSÉ M.</h3>
            </div>
            <div className="testimonial-slider-item">
              <p>{this.context.t("HomeTestimonio4")}</p>
              <h3 className="name">MARÍA C.</h3>
            </div>
            <div className="testimonial-slider-item">
              <p>{this.context.t("HomeTestimonio5")}</p>
              <h3 className="name">JULIETA T.</h3>
            </div>
          </OwlCarousel>
        </section>

        <section id="benefits">
          <div className="title">
            <h3>{this.context.t("HomeBeneficiosTitulo")}</h3>
          </div>
          <div className="circles">
            <div className="column">
              <img className="image" src={beneficio1Producto} alt="Cerveza Sierra Fría"/>
              <h4>{this.context.t("HomeBeneficiosNombre1")}</h4>
              <p>{this.context.t("HomeBeneficiosSubtitulo1")}</p>
            </div>
            <div className="column">
              <img className="image" src={beneficio2Producto} alt="Cerveza Sierra Fría"/>
              <h4>{this.context.t("HomeBeneficiosNombre2")}</h4>
              <p>{this.context.t("HomeBeneficiosSubtitulo2")}</p>
            </div>
            <div className="column">
              <img className="image" src={beneficio3Producto} alt="Cerveza Sierra Fría"/>
              <h4>{this.context.t("HomeBeneficiosNombre3")}</h4>
              <p>{this.context.t("HomeBeneficiosSubtitulo3")}</p>
            </div>
            <div className="column">
              <img className="image" src={beneficio4Producto} alt="Cerveza Sierra Fría"/>
              <h4>{this.context.t("HomeBeneficiosNombre4")}</h4>
              <p>{this.context.t("HomeBeneficiosSubtitulo4")}</p>
            </div>
          </div>
        </section>

        <section id="social_feed">
          <div className="title">
            <h3>{this.context.t("HomeSocialTitulo")}</h3>
            <span className="line"/>
          </div>
          <div className="columnas">
            <div className="columna">
              <FacebookProvider appId="565149567456776">
                <Page
                    href="https://www.facebook.com/Cerveza-Sierra-Friamx-108088070663065"
                    tabs="timeline"
                    showFacepile="false"
                    height="780"
                    adaptContainerWidth="true"
                />
              </FacebookProvider>
            </div>
            <div className="columna instagram">
              <InstagramEmbed
                  url='https://www.instagram.com/p/B-K3hoanjch/'
                  maxWidth={500}
              />
            </div>
          </div>
        </section>

        <section id="newsletter">
          <div className="form_pattern">
            <div className="form">
              <div className="title">
                <h3>{this.context.t("HomePromocionesTitulo")}</h3>
                <span className="line"/>
              </div>
              <PromotionWeb onSubmit={this.handleSubmit} />
            </div>
          </div>
        </section>

        <section id="activities">
          <div className="title">
            <h3>{this.context.t("HomeActividadesTitulo")}</h3>
            <span className="line"/>
          </div>
          <div className="columnas">
            <div className="columna deportes">
              <h3>{this.context.t("HomeActividades1")}</h3>
            </div>
            <div className="columna viajes">
              <h3>{this.context.t("HomeActividades2")}</h3>
            </div>
            <div className="columna amigos">
              <h3>{this.context.t("HomeActividades3")}</h3>
            </div>
            <div className="columna gastronomia">
              <h3>{this.context.t("HomeActividades4")}</h3>
            </div>
          </div>
        </section>
      </div>
    );
  }
  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

HomeWebContainer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HomeWebContainer;
