import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';

const validate = values => {
  const error = {};
  if(!values.nombre){
    error.nombre = 'Escribe el nombre por favor.';
  }
  if(!values.direccion){
    error.direccion = 'Escribe la dirección por favor.';
  }
  if(!values.latitud){
    error.latitud = 'Escribe la latitud por favor.';
  }
  if(!values.longitud){
    error.longitud = 'Escribe la longitud por favor.';
  }
  return error;
};

class EditStore extends Component {
  componentDidMount() {
    const { data } = this.props;
    this.props.initialize({ id: data.key });
    this.props.dispatch(change('EditStoreForm', 'nombre', data.nombre));
    this.props.dispatch(change('EditStoreForm', 'direccion', data.direccion));
    this.props.dispatch(change('EditStoreForm', 'latitud', data.latitud));
    this.props.dispatch(change('EditStoreForm', 'longitud', data.longitud));
  }

  renderField = ({input, meta, type, label, name, placeholder, autocomplete }) => (
    type === 'hidden' ?
    <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={autocomplete} />
    :
    <div className="form-group">
      <label className="control-label" htmlFor={name}>{label}</label>
      <input className="form-control" {...input} type={!type ? 'text' : type} placeholder={placeholder} autoComplete={autocomplete} />
      { meta.touched && meta.error && <span>{meta.error}</span> }
    </div> 
  );

  render() {
    const {handleSubmit, submitting, pristine } = this.props;
    return (
      <div className="card">
        <div className="body">
          <div className="form-container m-b-30">
            <form onSubmit={handleSubmit}>
              <Field name="id" label="ID" component={this.renderField} type="hidden" placeholder="ID" />
              <Field name="nombre" label="Nombre" component={this.renderField} type="text" placeholder="Nombre" />
              <Field name="direccion" label="Dirección" component={this.renderField} type="text" placeholder="Dirección" />
              <Field name="latitud" label="Latitud" component={this.renderField} type="number" placeholder="Latitud" />
              <Field name="longitud" label="Longitud" component={this.renderField} type="number" placeholder="Longitud" />
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting} >Guardar</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const EditStoreForm = reduxForm({ 
  form: 'EditStoreForm',
  validate
})(EditStore);

export default EditStoreForm;