import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'correo',
  text: 'Correo',
  sort: true,
  filter: textFilter()
},{
  dataField: 'edad',
  text: 'Edad',
  sort: true,
  filter: textFilter()
},{
  dataField: 'estadoResidencia',
  text: 'Estado de Residencia',
  sort: true,
  filter: textFilter()
},{
  dataField: 'genero',
  text: 'Género',
  sort: true,
  filter: textFilter()
}];

class VisitorsContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      visitorsList: []
    };
    this.tableVisitors = this.db.collection('visitantes');
  }

  componentDidMount() {
    this.unsubscribe = this.tableVisitors.onSnapshot(this.getVisitorsList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getVisitorsList = (querySnapshot) => {
    const visitorsList = [];
    querySnapshot.forEach((doc) => {
      const { correo, edad, estadoResidencia, genero } = doc.data();
      visitorsList.push({ key: doc.id, correo, edad, estadoResidencia, genero });
    });
    this.setState({ visitorsList });
  }

  renderBody(){
    return(
      <div className="boletin-container">
        <div className="card">
          <div className="body">
            { this.state.visitorsList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Correo</Th>
                  <Th>Edad</Th>
                  <Th>Estado de Residencia</Th>
                  <Th>Género</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.visitorsList.map(visitor => {
                  return(
                    <Tr key={visitor.key}>
                      <Td>{visitor.correo}</Td>
                      <Td>{visitor.edad}</Td>
                      <Td>{visitor.estadoResidencia}</Td>
                      <Td>{visitor.genero}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.visitorsList} columns={columns} />
          </div>
        </div>
      </div>            
    );
  }

  render() {
    return (
      <AppFrame title="Visitantes del Sitio Web" body={this.renderBody()} />
    );
  }
}

export default VisitorsContainer;
