import React, { Component } from 'react';
import firebase from '../../../Firebase';
import AppFrame from '../../components/AppFrame';
import Swal from 'sweetalert2';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'nombre',
  text: 'Nombre',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'direccion',
  text: 'Dirección',
  sort: true,
  filter: textFilter()
}, {
  dataField: 'acciones',
  text: 'Acciones',
  classes: 'row-flex'
}];

class ListStoresContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      storesList: []
    };
    this.tableStores = this.db.collection('tiendas');
  }

  componentDidMount() {
    this.unsubscribe = this.tableStores.onSnapshot(this.getStoresList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getStoresList = (querySnapshot) => {
    const storesList = [];
    querySnapshot.forEach((doc) => {
      const { nombre, direccion, latitud, longitud } = doc.data();
      const store = { key: doc.id, nombre, direccion, latitud, longitud };
      const keyEdit = 'edit' + doc.id;
      const keyDelete = 'delete' + doc.id;
      let acciones = [<button key={keyEdit} className="btn btn-warning" onClick={() => this.editStore(store)}><i className="fa fa-edit"></i> Editar</button>,<button key={keyDelete} className="btn btn-danger" onClick={() => this.deleteStore(store.key)}><i className="far fa-trash-alt"></i> Eliminar</button>];
      storesList.push({ key: doc.id, nombre, direccion, latitud, longitud, acciones });
    });
    this.setState({ storesList });
  }

  addStore = () => {
    this.props.history.push('nueva-tienda');
  }

  editStore = (store) => {
    this.props.history.push({
      pathname: '/editar-tienda',
      store
    });
  }

  deleteStore = (key) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás recuperar este elemento.",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then( (result) => {
      if(result.value){
        this.tableStores.doc(key).delete().then( () => {
          Swal.fire({
            title: '¡Operación exitosa!',
            text: 'El elemento ha sido eliminado correctamente.',
            icon: 'success',
            confirmButtonText: 'Continuar'
          });
        }).catch( () => {
          Swal.fire({
            title: '¡Lo sentimos!',
            text: 'Hubo un problema con la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Continuar'
          });
        });
      }
    })
  }

  renderBody(){
    return(
      <div className="list-stores-container">
        <div className="row clearfix m-b-25">
          <div className="col-md-12 col-sm-12">
            <button className="btn btn-sm btn-success" onClick={() => this.addStore()}>Agregar Tienda</button>
          </div>
        </div>
        <div className="card">
          <div className="body">
            { this.state.storesList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Nombre</Th>
                  <Th>Dirección</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.storesList.map(store => {
                  return(
                    <Tr key={store.key}>
                      <Td>{store.nombre}</Td>
                      <Td>{store.direccion}</Td>
                      <Td>{store.acciones}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.storesList} columns={columns} />
          </div>
        </div>
      </div>            
    );
  }

  render() {
    return (
      <AppFrame title="Tiendas" body={this.renderBody()} />
    );
  }
}

export default ListStoresContainer;
