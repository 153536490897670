import React, { Component } from 'react';
import firebase from './../../../Firebase';
import AppFrame from './../../components/AppFrame';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
  dataField: 'correo',
  text: 'Correo',
  sort: true,
  filter: textFilter()
}];

class NewsletterContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = { 
      newsletterList: []
    };
    this.tableNewsletter = this.db.collection('promociones');
  }

  componentDidMount() {
    this.unsubscribe = this.tableNewsletter.onSnapshot(this.getNewsletterList);
  }

  componentWillUnmount(){
    this.unsubscribe && this.unsubscribe();
  }

  getNewsletterList = (querySnapshot) => {
    const newsletterList = [];
    querySnapshot.forEach((doc) => {
      const { correo } = doc.data();
      newsletterList.push({ key: doc.id, correo });
    });
    this.setState({ newsletterList });
  }

  renderBody(){
    return(
      <div className="boletin-container">
        <div className="card">
          <div className="body">
            { this.state.newsletterList.length > 0 ? <Table className="table-admin">
              <Thead>
                <Tr>
                  <Th>Correo</Th>
                </Tr>
              </Thead>
              <Tbody>
                { this.state.newsletterList.map(subscriber => {
                  return(
                    <Tr key={subscriber.key}>
                      <Td>{subscriber.correo}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p> }
            <TableDesktop data={this.state.newsletterList} columns={columns} />
          </div>
        </div>
      </div>            
    );
  }

  render() {
    return (
      <AppFrame title="Suscriptores al boletín" body={this.renderBody()} />
    );
  }
}

export default NewsletterContainer;
