import React, {Component} from 'react';
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class TableDesktop extends Component {
    render() {
        const { data, columns } = this.props;
        return (
            <BootstrapTable
                keyField='key'
                data={data}
                columns={columns}
                striped
                bordered={false}
                wrapperClasses='visible-desktop'
                filter={ filterFactory() }
                pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    firstPageText: 'Primero',
                    prePageText: 'Anterior',
                    nextPageText: 'Siguiente',
                    lastPageText: 'Último',
                    nextPageTitle: 'Primera Página',
                    prePageTitle: 'Anterior Página',
                    firstPageTitle: 'Siguiente Página',
                    lastPageTitle: 'Última Página'
                })}
                noDataIndication={() => (<p>Lo sentimos, no hay registros.</p>)}
            />
        );
    }
}

export default TableDesktop;
