import React from 'react';
import {textFilter} from 'react-bootstrap-table2-filter';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableDesktop from "../../components/TableDesktop";

const columns = [{
    dataField: 'fecha',
    text: 'Fecha',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'stripe_id',
    text: 'Stripe ID',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'total',
    text: 'Total',
    sort: true,
    filter: textFilter(),
    formatter: (cell) => {
        return <span>${cell.toFixed(2)}</span>;
    }
}, {
    dataField: 'estado',
    text: 'Estado',
    sort: true,
    filter: textFilter()
}, {
    dataField: 'acciones',
    text: 'Acciones',
    classes: 'row-flex'
}];

const OrdersClientContainer = ({client, handler}) => {
    const orders = client ? client.pedidos : [];
    return (
        <div className="orders-client-container">
            <button className="btn btn-warning m-b-15" onClick={handler}>Volver</button>
            <div className="card">
                <div className="body">
                    <h2 className="title-table">Pedidos del Cliente: {client.correo}</h2>
                    {orders.length > 0 ? <Table className="table-admin">
                        <Thead>
                            <Tr>
                                <Th>Fecha</Th>
                                <Th>Stripe ID</Th>
                                <Th>Total</Th>
                                <Th>Estado</Th>
                                <Th>Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {orders.map(order => {
                                return (
                                    <Tr key={order.key}>
                                        <Td>{order.fecha}</Td>
                                        <Td>{order.stripe_id}</Td>
                                        <Td>${order.total.toFixed(2)}</Td>
                                        <Td>{order.estado}</Td>
                                        <Td>{order.acciones}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table> : <p className="table-admin">Lo sentimos, no hay registros.</p>}
                    <TableDesktop data={orders} columns={columns} />
                </div>
            </div>
        </div>
    );
}

export default OrdersClientContainer;
