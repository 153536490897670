import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyAEDPB2LiGBeCzxvFcHYVX9UCFRA2afno0",
  authDomain: "cerveza-sierra-fria.firebaseapp.com",
  databaseURL: "https://cerveza-sierra-fria.firebaseio.com",
  projectId: "cerveza-sierra-fria",
  storageBucket: "cerveza-sierra-fria.appspot.com",
  messagingSenderId: "245854282995",
  appId: "1:245854282995:web:c6372ff1fe9f6ada1d4386",
  measurementId: "G-2CKZKRPPXD"
};

firebase.initializeApp(config);
firebase.auth().signInWithEmailAndPassword('admin@cervezasierrafria.mx','sierra2020fria').then( () => {});
export const storage = firebase.storage();
export default firebase;
