import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';
import NewCategory from './../../components/categories/NewCategory';
import firebase from './../../../Firebase';
import Swal from 'sweetalert2';

class NewCategoryContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.tableCategories = this.db.collection('categorias-productos');
  }

  handleSubmit = (values) => {
    const { nombre } = values;
    this.tableCategories.add({ nombre }).then( () => {
      Swal.fire({
        title: '¡Operación exitosa!',
        text: 'El elemento ha sido agregado correctamente.',
        icon: 'success',
        confirmButtonText: 'Continuar'
      });
      this.props.history.push('categorias-productos');
    }).catch( () => {
      Swal.fire({
        title: '¡Lo sentimos!',
        text: 'Hubo un problema con la base de datos.',
        icon: 'warning',
        confirmButtonText: 'Continuar'
      });
    });
  }

  renderBody(){
    return (
      <NewCategory onSubmit={this.handleSubmit} />
    );
  }

  render() {
    return (
      <AppFrame title="Nueva Categoría" body={this.renderBody()} />
    );
  }
}

export default NewCategoryContainer;
