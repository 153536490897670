import music from '../sitio-web/music/africa.mp3';

const initState = {
  audio: new Audio(music),
  isPlaying: false,
  musicIcon: 'far fa-play-circle'
}

const Music = (state = initState, action)=>{
  if(action.type === 'SET_MUSIC_PLAYING'){
    let icono = '';
    if(action.estado){
      icono = 'far fa-pause-circle';
    }else{
      icono = 'far fa-play-circle';
    }
    return{ ...state, isPlaying: action.estado, musicIcon: icono }
  }else{
    return state;
  }
}

export default Music;