import React, {Component} from 'react';
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";

class AddressWeb extends Component {
    renderField = ({input, type, label, name, placeholder }) => (
        <div className="form-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <input className="form-control" {...input}  type={!type ? 'text' : type} placeholder={placeholder} required />
        </div>
    );

    render() {
        const {handleAddress} = this.props;
        return (
            <div className="form-container">
                <form onChange={handleAddress}>
                    <h3 className="mb-3">{this.context.t("direccionTitulo")}</h3>
                    <div className="row">
                        <div className="col-6">
                            <Field name="calle" component={this.renderField} type="text" label={this.context.t("direccionCalle")} value={localStorage.getItem('calle')} />
                        </div>
                        <div className="col-6">
                            <Field name="numero" component={this.renderField} type="number" label={this.context.t("direccionNumero")} value={localStorage.getItem('numero')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="colonia" component={this.renderField} type="text" label={this.context.t("direccionColonia")} value={localStorage.getItem('colonia')} />
                        </div>
                        <div className="col-6">
                            <Field name="postal" component={this.renderField} type="number" label={this.context.t("direccionPostal")} value={localStorage.getItem('postal')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="ciudad" component={this.renderField} type="text" label={this.context.t("direccionCiudad")} value={localStorage.getItem('ciudad')} />
                        </div>
                        <div className="col-6">
                            <Field name="estado" component={this.renderField} type="text" label={this.context.t("direccionEstado")} value={localStorage.getItem('estado')} />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const AddressWebForm = reduxForm({
    form: 'AddressWebForm'
})(AddressWeb);

AddressWeb.contextTypes = {
    t: PropTypes.func.isRequired
};

export default AddressWebForm;
